import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getStoreById } from '../../queries/get-store-by-id.query';
import {
    supplierStoreItemLoadAction,
    supplierStoreItemLoadFailedAction,
    supplierStoreItemLoadSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadStoreByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(supplierStoreItemLoadAction),
        toPayload(),
        switchMap(storeId =>
            getStoreById({ storeId }).pipe(
                map(store => supplierStoreItemLoadSuccessAction(serializeDateFields(store))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load store by provided id" });

                    return [supplierStoreItemLoadFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
