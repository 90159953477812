import { initialLoadingState } from '@rnw-community/redux-loadable';

import { emptyCategoryDto, emptyStoreDto, emptySupplierDto } from '@dotgoclub/client-contracts';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import type { CategoryDtoInterface, StoreDtoInterface, SupplierDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { LoadingStateInterface } from '@rnw-community/redux-loadable';

export interface SuppliersState extends LoadingStateInterface {
    categories: LoadingStateInterface & {
        item: WithSerializedDates<CategoryDtoInterface>;
        list: WithSerializedDates<CategoryDtoInterface[]>;
    };
    item: WithSerializedDates<SupplierDtoInterface>;
    list: WithSerializedDates<SupplierDtoInterface[]>;
    stores: LoadingStateInterface & {
        item: WithSerializedDates<StoreDtoInterface>;
        list: WithSerializedDates<StoreDtoInterface[]>;
    };
}

export const initialSuppliersState: SuppliersState = {
    ...initialLoadingState,
    list: [],
    item: serializeDateFields(emptySupplierDto),
    categories: {
        ...initialLoadingState,
        list: [],
        item: serializeDateFields(emptyCategoryDto)
    },
    stores: {
        ...initialLoadingState,
        list: [],
        item: serializeDateFields(emptyStoreDto)
    }
};
