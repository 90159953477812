import { gql } from '@apollo/client';

export const metaFragment = gql`
    fragment MetaFragment on PaginationMeta {
        totalItems
        totalPages
        itemCount
        itemsPerPage
        currentPage
    }
`;
