import type { AddressComponent } from '@googlemaps/google-maps-services-js';

export interface GeocodeResultDtoInterface {
    address: AddressComponent[];
    placeId: string;
}

export const emptyGeocodeResultDto: GeocodeResultDtoInterface = {
    address: [],
    placeId: ''
};

export const isEmptyGeocodeResult = (place: GeocodeResultDtoInterface): boolean =>
    place.placeId === emptyGeocodeResultDto.placeId && place.address.length === 0;
