import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, mergeMap } from 'rxjs';

import { getApolloErrorCode } from '@dotgoclub/apollo-components';
import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { loginMutation$ } from '../../queries/login.mutation';
import { authLoginAction, authLoginFailedAction, authLoginSuccessAction } from '../auth.actions';

import type { AuthEventOptionsInterface } from '../../interface/auth-event-options.interface';
import type { ApolloClient } from '@apollo/client';
import type { Action } from '@reduxjs/toolkit';
import type { Observable } from 'rxjs';

export const createAuthLoginEpic =
    <T>(
        apolloClient: ApolloClient<T>,
        onSuccess?: AuthEventOptionsInterface['onLoginSuccess'],
        onError?: AuthEventOptionsInterface['onLoginFailed']
    ) =>
    (actions$: Observable<Action>): Observable<Action> =>
        actions$.pipe(
            ofType(authLoginAction),
            toPayload(),
            exhaustMap(loginData =>
                loginMutation$(apolloClient, loginData).pipe(
                    mergeMap(credentials => [authLoginSuccessAction(credentials), ...(onSuccess?.(credentials) ?? [])]),
                    catchError((err: unknown) => {
                        const errText = getApolloErrorCode(err) ?? getErrorMessage(err, 'Failed login request');

                        return [authLoginFailedAction(errText), ...(onError?.(errText) ?? [])];
                    })
                )
            )
        );
