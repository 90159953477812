import { useAdminNavigationRoute } from './use-admin-navigation-route.hook';

import type { AdminRoutesEnum } from '../enum/admin-routes.enum';
import type { RootStackParamList } from '../navigators/linking.config';

export const useAdminNavigationRouteParams = <T extends AdminRoutesEnum>(): Partial<RootStackParamList[T]> => {
    const { params = {} } = useAdminNavigationRoute<T>();

    return params;
};
