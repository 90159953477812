import { createSelector } from '@reduxjs/toolkit';

import type { SuppliersRootState } from './suppliers.slice';
import type { SuppliersState } from './suppliers.state';
import type { SupplierDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { OutputSelector } from '@reduxjs/toolkit';

export const suppliersSelector = (state: SuppliersRootState): SuppliersState => state.suppliers;

export const suppliersListSelector = createSelector(suppliersSelector, state => ({
    list: state.list,
    isLoading: state.isLoading
}));
export const supplierCategoriesSelector = createSelector(suppliersSelector, state => ({
    list: state.categories.list,
    isLoading: state.categories.isLoading
}));
export const supplierCategoryItemSelector = createSelector(suppliersSelector, state => ({
    item: state.categories.item,
    isLoading: state.categories.isLoading
}));
export const supplierStoreListSelector = createSelector(suppliersSelector, state => ({
    list: state.stores.list,
    isLoading: state.stores.isLoading
}));
export const supplierStoreItemSelector = createSelector(suppliersSelector, state => ({
    item: state.stores.item,
    isLoading: state.stores.isLoading
}));

export const supplierItemSelector = createSelector(suppliersSelector, state => ({
    item: state.item,
    isLoading: state.isLoading
}));

export const supplierByIdSelector = (
    supplierId: string
): OutputSelector<
    SuppliersRootState,
    WithSerializedDates<SupplierDtoInterface> | undefined,
    (state: SuppliersState) => WithSerializedDates<SupplierDtoInterface> | undefined
> => createSelector(suppliersSelector, state => state.list.find((supplier: { id: string }) => supplier.id === supplierId));
