// TODO: These enums should not be in client contracts
export enum PricingActionEnum {
    Create = 'create',
    Delete = 'delete',
    Read = 'read',
    ReadCountries = 'read-countries',
    ReadCurrencies = 'read-currencies',
    ReadList = 'read-list',
    Update = 'update',
    UpdatePricing = 'update-pricing'
}
