import { createSlice } from '@reduxjs/toolkit';
import { loadingFailed, loadingFinished, loadingStarted } from '@rnw-community/redux-loadable';

import { initialClientState } from './client.state';

import type {
    FindUsersArgsInterface,
    PaginatedInterface,
    UpdateUserArgsInterface,
    UserDtoInterface
} from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { PayloadAction } from '@reduxjs/toolkit';

export const clientSlice = createSlice({
    initialState: initialClientState,
    name: 'client',
    reducers: {
        listLoad(state, _action: PayloadAction<FindUsersArgsInterface>) {
            loadingStarted(state);
        },
        listLoadSuccess(state, action: PayloadAction<WithSerializedDates<PaginatedInterface<UserDtoInterface>>>) {
            loadingFinished(state);
            state.list = action.payload.items;
            state.pagination = action.payload.meta;
        },
        listLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state);
        },
        itemLoadSuccess(state, action: PayloadAction<WithSerializedDates<UserDtoInterface>>) {
            loadingFinished(state);
            state.item = action.payload;
        },
        itemLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemSave(state, _action: PayloadAction<UpdateUserArgsInterface>) {
            loadingStarted(state);
        },
        itemSaveSuccess(state, action: PayloadAction<WithSerializedDates<UserDtoInterface>>) {
            loadingFinished(state);
            state.item = action.payload;
        },
        itemSaveFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        }
    }
});

export interface ClientRootState {
    [clientSlice.name]: ReturnType<typeof clientSlice.reducer>;
}

export const clientReducer = clientSlice.reducer;
