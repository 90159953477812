import { createSlice } from '@reduxjs/toolkit';
import { loadingFailed, loadingFinished, loadingStarted } from '@rnw-community/redux-loadable';

import { initialPricingState } from './pricing.state';

import type {
    CountryDtoInterface,
    CurrencyDtoInterface,
    FindPricingsArgsInterface,
    PaginatedInterface,
    PricingDtoInterface,
    PricingInputInterface,
    UpdatePricingInputInterface
} from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { PayloadAction } from '@reduxjs/toolkit';

export const pricingSlice = createSlice({
    initialState: initialPricingState,
    name: 'pricing',
    reducers: {
        listLoad(state, _action: PayloadAction<FindPricingsArgsInterface>) {
            loadingStarted(state);
        },
        listLoadSuccess(state, action: PayloadAction<WithSerializedDates<PaginatedInterface<PricingDtoInterface>>>) {
            loadingFinished(state);
            state.list = action.payload.items;
            state.pagination = action.payload.meta;
        },
        listLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state);
        },
        itemLoadSuccess(state, action: PayloadAction<WithSerializedDates<PricingDtoInterface>>) {
            loadingFinished(state);
            state.item = action.payload;
        },
        itemLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        currenciesLoad(state) {
            loadingStarted(state);
        },
        currenciesLoadSuccess(state, action: PayloadAction<Array<WithSerializedDates<CurrencyDtoInterface>>>) {
            loadingFinished(state);
            state.currencies = action.payload;
        },
        currenciesLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        countriesLoad(state) {
            loadingStarted(state);
        },
        countriesLoadSuccess(state, action: PayloadAction<Array<WithSerializedDates<CountryDtoInterface>>>) {
            loadingFinished(state);
            state.countries = action.payload;
        },
        countriesLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemSave(state, _action: PayloadAction<UpdatePricingInputInterface>) {
            loadingStarted(state);
        },
        itemSaveSuccess(state, action: PayloadAction<WithSerializedDates<PricingDtoInterface>>) {
            loadingFinished(state);
            state.item = action.payload;
        },
        itemSaveFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemCreate(state, _action: PayloadAction<PricingInputInterface>) {
            loadingStarted(state);
        },
        itemCreateSuccess(state, action: PayloadAction<WithSerializedDates<PricingDtoInterface>>) {
            loadingFinished(state);
            state.item = action.payload;
        },
        itemCreateFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        itemDelete(state, _action: PayloadAction<string>) {
            loadingStarted(state);
        },
        itemDeleteSuccess(state, _action: PayloadAction<boolean>) {
            loadingFinished(state);
        },
        itemDeleteFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        }
    }
});

export interface PricingRootState {
    [pricingSlice.name]: ReturnType<typeof pricingSlice.reducer>;
}

export const pricingReducer = pricingSlice.reducer;
