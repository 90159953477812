import { emptyBaseEntity } from '../../../common';

import { emptyCurrencyDto } from './currency-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CityDtoInterface } from './city-dto.interface';
import type { CurrencyDtoInterface } from './currency-dto.interface';

export interface CountryDtoInterface extends BaseEntityInterface {
    cca2: string;
    cca3: string;
    cities: CityDtoInterface[];
    currency?: CurrencyDtoInterface;
    currencyId: string;
    name: string;
}

export const emptyCountryDto: CountryDtoInterface = {
    ...emptyBaseEntity,
    cca2: '',
    cca3: '',
    cities: [],
    currency: emptyCurrencyDto,
    currencyId: '',
    name: ''
};
