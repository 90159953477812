export enum DeliveryTimeEnum {
    EarlyMorning = 'EarlyMorning',
    Evening = 'Evening',
    LateEvening = 'LateEvening',
    Lunch = 'Lunch',
    MidDay = 'MidDay',
    Morning = 'Morning',
    Night = 'Night'
}

export const deliveryTimeMatrix: Record<DeliveryTimeEnum, number> = {
    [DeliveryTimeEnum.Night]: 0,
    [DeliveryTimeEnum.EarlyMorning]: 6,
    [DeliveryTimeEnum.Morning]: 9,
    [DeliveryTimeEnum.MidDay]: 12,
    [DeliveryTimeEnum.Lunch]: 13,
    [DeliveryTimeEnum.Evening]: 16,
    [DeliveryTimeEnum.LateEvening]: 20
};
