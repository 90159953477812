import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getCategoryByIdQuery$ } from '../../queries/get-category-by-id.query';
import {
    supplierCategoryItemLoadAction,
    supplierCategoryItemLoadFailedAction,
    supplierCategoryItemLoadSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadCategoryByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(supplierCategoryItemLoadAction),
        toPayload(),
        switchMap(categoryId =>
            getCategoryByIdQuery$({ categoryId }).pipe(
                map(category => supplierCategoryItemLoadSuccessAction(serializeDateFields(category))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load selected category" });

                    return [supplierCategoryItemLoadFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
