import { createDrawerNavigator } from '@react-navigation/drawer';
import React from 'react';

import {
    AddStoreFormPage,
    AddSupplierFormPage,
    AdminSupplierPage,
    AdminSuppliersPage,
    EditStoreFormPage,
    EditSupplierCategoryFormPage,
    EditSupplierFormPage
} from '../../../catalog/suppliers';
import { AdminClientFormPage, AdminClientsPage } from '../../../clients';
import { AdminOrdersList } from '../../../orders';
import { AdminCreatePricingFormPage, AdminPricingFormPage, AdminPricingListPage } from '../../../pricing';
import { AdminHomePage } from '../../components/admin-home-page/admin-home-page';
import { AdminMenu } from '../../components/admin-menu/admin-menu';
import { AdminNotFoundPage } from '../../components/admin-not-found-page/admin-not-found-page';
import { AdminRoutesEnum } from '../../enum/admin-routes.enum';

import { AdminAuthorizedNavigatorStyles } from './admin-authorized-navigator.styles';

import type { RootStackParamList } from '../linking.config';
import type { DrawerContentComponentProps } from '@react-navigation/drawer';
import type { FC } from 'react';

const Drawer = createDrawerNavigator<RootStackParamList>();

export const AdminAuthorizedNavigator: FC = () => {
    const drawerContent = (props: DrawerContentComponentProps): JSX.Element => <AdminMenu {...props} />;

    return (
        <Drawer.Navigator
            backBehavior="history"
            drawerContent={drawerContent}
            screenOptions={{
                drawerStyle: AdminAuthorizedNavigatorStyles.drawerMenu,
                drawerType: 'permanent',
                headerShown: false,
                sceneContainerStyle: AdminAuthorizedNavigatorStyles.drawerContent
            }}
        >
            <Drawer.Screen component={AdminHomePage} name={AdminRoutesEnum.Home} options={{ title: 'Home' }} />
            <Drawer.Screen component={AdminOrdersList} name={AdminRoutesEnum.Orders} options={{ title: 'Orders' }} />
            <Drawer.Screen component={AdminClientsPage} name={AdminRoutesEnum.Clients} options={{ title: 'Clients' }} />
            <Drawer.Screen component={AdminClientFormPage} name={AdminRoutesEnum.ClientForm} options={{ title: 'Client form' }} />
            <Drawer.Screen component={AdminPricingListPage} name={AdminRoutesEnum.Pricing} options={{ title: 'Pricing' }} />
            <Drawer.Screen component={AdminPricingFormPage} name={AdminRoutesEnum.PricingForm} options={{ title: 'Pricing form' }} />
            <Drawer.Screen
                component={AdminCreatePricingFormPage}
                name={AdminRoutesEnum.CreatePricingForm}
                options={{ title: 'Create pricing form' }}
            />
            <Drawer.Screen component={AdminSuppliersPage} name={AdminRoutesEnum.Suppliers} options={{ title: 'Suppliers' }} />
            <Drawer.Screen component={AdminSupplierPage} name={AdminRoutesEnum.Supplier} options={{ title: 'Supplier' }} />
            <Drawer.Screen
                component={EditSupplierFormPage}
                name={AdminRoutesEnum.EditSupplierForm}
                options={{ title: 'Edit supplier' }}
            />
            <Drawer.Screen
                component={EditSupplierCategoryFormPage}
                name={AdminRoutesEnum.EditCategoryForm}
                options={{ title: 'Edit category' }}
            />
            <Drawer.Screen component={EditStoreFormPage} name={AdminRoutesEnum.EditStoreForm} options={{ title: 'Edit store' }} />
            <Drawer.Screen component={AddStoreFormPage} name={AdminRoutesEnum.AddStoreForm} options={{ title: 'Add store' }} />
            <Drawer.Screen
                component={AddSupplierFormPage}
                name={AdminRoutesEnum.AddSupplierForm}
                options={{ title: 'Add supplier' }}
            />
            <Drawer.Screen component={AdminNotFoundPage} name={AdminRoutesEnum.NotFound} options={{ title: 'Page not found' }} />
        </Drawer.Navigator>
    );
};
