import { loadingStateSelector } from '@rnw-community/redux-loadable';
import React, { useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Form, Loader } from 'rsuite';

import { authCancelOtpAction, authLoginAction, authOtpAction, authSelector } from '@dotgoclub/auth-components';

import { H1 } from '../../../general';

import { AuthPageStyles } from './auth-page.styles';

import type { FC } from 'react';

export const AuthPage: FC = () => {
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const [isLoading] = useSelector(loadingStateSelector('auth'));

    const [otp, setOTP] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleRequestOTP = (): void => void dispatch(authOtpAction(phoneNumber));
    const handleLogin = (): void => void dispatch(authLoginAction({ phoneNumber, otp }));
    const handleBack = (): void => void dispatch(authCancelOtpAction());

    return (
        <View style={AuthPageStyles.wrapper}>
            <Form onSubmit={handleRequestOTP}>
                <H1 isCentered title="Authorization" />
                {!auth.isOtpRequested && (
                    <>
                        <Form.Group>
                            <Form.Control
                                name="phoneNumber"
                                onChange={setPhoneNumber}
                                placeholder="Enter your phone number"
                                type="tel"
                            />
                        </Form.Group>
                        <Form.Group>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <ButtonToolbar>
                                    <Button appearance="primary" block type="submit">
                                        Send OTP
                                    </Button>
                                </ButtonToolbar>
                            )}
                        </Form.Group>
                    </>
                )}
                {auth.isOtpRequested ? (
                    <>
                        <Form.Group>
                            <Form.Control name="otp" onChange={setOTP} placeholder="Enter OTP from SMS/Email" type="number" />
                        </Form.Group>
                        <Form.Group>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <ButtonToolbar>
                                    <Button appearance="primary" block onClick={handleLogin}>
                                        Login
                                    </Button>
                                    <Button appearance="primary" block onClick={handleBack}>
                                        Back
                                    </Button>
                                </ButtonToolbar>
                            )}
                        </Form.Group>
                    </>
                ) : null}
            </Form>
        </View>
    );
};
