import React from 'react';
import { Form } from 'rsuite';

import { DeliverySizeEnum } from '@dotgoclub/client-contracts';

import { H1, InputFormGroup } from '../../../../general';
import { PricingFormStyle } from '../pricing-form.styles';

import type { FC } from 'react';

export const FormGroupSize: FC = () => (
    <Form.Group style={PricingFormStyle.FormGroup}>
        <H1>Size</H1>
        {Object.keys(DeliverySizeEnum).map(item => (
            <InputFormGroup fieldLabel={`size.${item}`} key={item} label={item} />
        ))}
    </Form.Group>
);
