export enum AdminRoutesEnum {
    AddStoreForm = 'add-store-form',
    AddSupplierForm = 'add-supplier-form',
    Auth = 'auth',
    ClientForm = 'client-form',
    Clients = 'clients',
    CreatePricingForm = 'create-pricing-form',
    EditCategoryForm = 'edit-category-form',
    EditStoreForm = 'edit-store-form',
    EditSupplierForm = 'edit-supplier-form',
    Home = 'home',
    Main = 'main',
    NotFound = 'not-found',
    Orders = 'orders',
    Pricing = 'pricing',
    PricingForm = 'pricing-form',
    Supplier = 'supplier',
    Suppliers = 'suppliers'
}
