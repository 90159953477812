import { isDefined } from '@rnw-community/shared';
import React from 'react';
import { Form, Message } from 'rsuite';

import type { OnEventFn } from '@rnw-community/shared';
import type { FC } from 'react';

interface Props {
    error?: string;
    label: string;
    name: string;
    onChange: OnEventFn<string>;
    value: string;
}

const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const FormControlWithLabel: FC<Props> = ({ error, onChange, label, name, value }) => (
    <Form.Group>
        <Form.ControlLabel>{label}</Form.ControlLabel>
        <Form.Control name={name} onChange={onChange} value={value} />
        {isDefined(error) && <Message header={capitalizeFirstLetter(error)} type="error" />}
    </Form.Group>
);
