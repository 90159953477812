import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { imageFragment } from './../../../general/fragments/image.fragment';
import { categoryFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const updateCategoryByIdMutation$ = gqlMutationOperation<CatalogAdminResolverInterface, 'updateCategoryById'>(
    adminApolloClient,
    'updateCategoryById',
    gql`
        mutation updateCategoryById($categoryId: ID!, $input: UpdateCategoryInput!) {
            updateCategoryById(categoryId: $categoryId, input: $input) {
                ...CategoryFragment
                image {
                    ...ImageFragment
                }
            }
        }
        ${categoryFragment}
        ${imageFragment}
    `
);
