import { emptyBaseEntity } from '../../../common';
import { emptyCityDto, emptyCountryDto, emptyCurrencyDto } from '../../../geolocation';

import { emptyDistancePricingDto } from './distance-pricing-dto.interface';
import { emptySizePricingDto } from './size-pricing-dto.interface';
import { emptyTimePricingDto } from './time-pricing-dto.interface';
import { emptyValuePricingDto } from './value-pricing-dto.interface';

import type { PickWithBaseEntity } from '../../../common';
import type { CityDtoInterface, CountryDtoInterface, CurrencyDtoInterface } from '../../../geolocation';
import type { PricingEntityInterface } from '../../entities';
import type { DistancePricingDtoInterface } from './distance-pricing-dto.interface';
import type { SizePricingDtoInterface } from './size-pricing-dto.interface';
import type { TimePricingDtoInterface } from './time-pricing-dto.interface';
import type { ValuePricingDtoInterface } from './value-pricing-dto.interface';

type FIELDS =
    | 'cityId'
    | 'commission'
    | 'countryId'
    | 'defaultPurchaseCost'
    | 'defaultTipsValues'
    | 'deliveryFeeStepsCount'
    | 'deliveryFeeStepValue'
    | 'intervalBetweenTimeFromAndTimeTillInHours'
    | 'intervalDeliveryTimeInHours'
    | 'maximumTipsCost'
    | 'maxPurchaseCost'
    | 'minimalPrice'
    | 'stepPurchaseCost'
    | 'tipsCostStep';

export interface PricingDtoInterface extends PickWithBaseEntity<PricingEntityInterface, FIELDS> {
    city: CityDtoInterface;
    country: CountryDtoInterface;
    currency: CurrencyDtoInterface;
    // TODO: These properties should be called and have the same types as in pricing entity
    distance: DistancePricingDtoInterface;

    rawDistance: number;
    size: SizePricingDtoInterface;
    time: TimePricingDtoInterface;
    value: ValuePricingDtoInterface;
}

export const emptyPricingDto: PricingDtoInterface = {
    ...emptyBaseEntity,
    city: emptyCityDto,
    cityId: '',
    commission: 0,
    country: emptyCountryDto,
    countryId: '',
    currency: emptyCurrencyDto,
    defaultPurchaseCost: 0,
    maxPurchaseCost: 0,
    stepPurchaseCost: 0,
    distance: emptyDistancePricingDto,
    minimalPrice: 0,
    rawDistance: 0,
    size: emptySizePricingDto,
    time: emptyTimePricingDto,
    value: emptyValuePricingDto,
    deliveryFeeStepsCount: 0,
    deliveryFeeStepValue: 0,
    defaultTipsValues: [],
    maximumTipsCost: 0,
    tipsCostStep: 0,
    intervalDeliveryTimeInHours: 0,
    intervalBetweenTimeFromAndTimeTillInHours: 0
};
