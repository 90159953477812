import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { findAllOrdersQuery$ } from '../../queries/find-orders.query';
import { orderListLoadAction, orderListLoadFailedAction, orderListLoadSuccessAction } from '../order.action';

import type { Epic } from 'redux-observable';

export const orderListEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(orderListLoadAction),
        toPayload(),
        switchMap(variables =>
            findAllOrdersQuery$(variables).pipe(
                map(data => orderListLoadSuccessAction(serializeDateFields(data))),
                catchError((error: unknown) => [orderListLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
