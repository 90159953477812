import { getErrorMessage, isDefined, isNotEmptyString } from '@rnw-community/shared';
import { catchError, exhaustMap, mergeMap, withLatestFrom } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { displayToast } from '../../../general/helper/display-toast';
import { cancelMultipleOrdersMutation$ } from '../../queries/cancel-orders-mutation';
import {
    orderCancelSelectedOrdersAction,
    orderCancelSelectedOrdersFailedAction,
    orderCancelSelectedOrdersSuccessAction,
    orderListLoadAction
} from '../order.action';

import type { AdminRootState } from '../../../general/state/admin-root.state';
import type { OrderStatusEnum } from '@dotgoclub/client-contracts';
import type { Action } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';

const getOrderFilter = (
    orderStatus: OrderStatusEnum | undefined,
    searchParam: string | undefined
): { orderStatus?: OrderStatusEnum; searchParam?: string } | undefined => {
    if (isDefined(orderStatus) || isNotEmptyString(searchParam)) {
        return { ...(isDefined(orderStatus) && { orderStatus }), ...(isNotEmptyString(searchParam) && { searchParam }) };
    }

    return undefined;
};

export const orderCancelSelectedOrdersEpic: Epic<Action, Action, AdminRootState> = (actions$, state$) =>
    actions$.pipe(
        ofType(orderCancelSelectedOrdersAction),
        toPayload(),
        withLatestFrom(state$, (input, state) => ({
            orderIds: input,
            pagination: state.order.pagination,
            orderStatus: state.order.orderStatusFilter,
            searchParam: state.order.searchParamFilter,
            state
        })),
        exhaustMap(({ pagination, orderIds, orderStatus, searchParam }) =>
            cancelMultipleOrdersMutation$({ orderIds }).pipe(
                mergeMap(() => {
                    displayToast({ type: 'info', header: `${orderIds.length} orders have been canceled` });

                    return [
                        orderCancelSelectedOrdersSuccessAction(),
                        orderListLoadAction({
                            pagination: { limit: pagination.itemsPerPage, page: pagination.currentPage },
                            filter: getOrderFilter(orderStatus, searchParam)
                        })
                    ];
                }),
                catchError((error: unknown) => {
                    const errorMessage = getErrorMessage(error);
                    displayToast({ type: 'error', header: errorMessage });

                    return [orderCancelSelectedOrdersFailedAction(errorMessage)];
                })
            )
        )
    );
