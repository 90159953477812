import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFieldsArray } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getCategoriesBySupplierIdQuery$ } from '../../queries/get-categories-by-supplier-id.query';
import {
    supplierCategoriesListBySupplierIdLoadAction,
    supplierCategoriesListBySupplierIdLoadFailedAction,
    supplierCategoriesListBySupplierIdLoadSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadCategoriesBySupplierIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(supplierCategoriesListBySupplierIdLoadAction),
        toPayload(),
        switchMap(supplierId =>
            getCategoriesBySupplierIdQuery$({ supplierId }).pipe(
                map(category => supplierCategoriesListBySupplierIdLoadSuccessAction(serializeDateFieldsArray(category))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load categories for the selected supplier" });

                    return [supplierCategoriesListBySupplierIdLoadFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
