export const storeFormInitialValues = {
    name: '',
    externalId: '',
    mapPlaceId: '',
    address: '',
    coordinates: {
        latitude: 0,
        longitude: 0
    },
    cityId: '',
    type: '',
    zip: ''
};
