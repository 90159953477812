import { isDefined } from '@rnw-community/shared';
import { useState } from 'react';

export const useExpandableTable = <T extends { id: string }>(): [(rowData?: T) => void, string[]] => {
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

    const handleExpand = (rowData?: T): void => {
        if (isDefined(rowData)) {
            setExpandedRowKeys(
                expandedRowKeys.includes(rowData.id)
                    ? expandedRowKeys.filter(id => rowData.id !== id)
                    : [...expandedRowKeys, rowData.id]
            );
        }
    };

    return [handleExpand, expandedRowKeys];
};
