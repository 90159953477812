import { orderSlice } from './order.slice';

export const {
    listLoad: orderListLoadAction,
    listLoadFailed: orderListLoadFailedAction,
    listLoadSuccess: orderListLoadSuccessAction,
    cancelSelectedOrders: orderCancelSelectedOrdersAction,
    cancelSelectedOrdersSuccess: orderCancelSelectedOrdersSuccessAction,
    cancelSelectedOrdersFailed: orderCancelSelectedOrdersFailedAction,
    statusChanged: orderStatusChangedAction,
    searchParamChanged: orderSearchParamChangedAction
} = orderSlice.actions;
