import { emptyBaseEntity } from '../../../common';
import { DeliveryValueEnum } from '../../enum';

import type { PickWithBaseEntity } from '../../../common';
import type { ValuePricingEntityInterface } from '../../entities';

export interface ValuePricingDtoInterface extends PickWithBaseEntity<ValuePricingEntityInterface, DeliveryValueEnum> {}

export const emptyValuePricingDto: ValuePricingDtoInterface = {
    ...emptyBaseEntity,
    [DeliveryValueEnum.Cheap]: 0,
    [DeliveryValueEnum.Medium]: 0,
    [DeliveryValueEnum.Expensive]: 0,
    [DeliveryValueEnum.VeryExpensive]: 0,
    [DeliveryValueEnum.Luxury]: 0
};
