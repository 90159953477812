import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { metaFragment } from './../../general/fragments/meta.fragment';

import type { OrderAdminResolverInterface } from '@dotgoclub/client-contracts';

const FIND_ALL_ORDERS = gql`
    query findAllOrders($pagination: PaginationArgs!, $filter: FilterOrder) {
        findAllOrders(pagination: $pagination, filter: $filter) {
            items {
                deliveryCost
                orderStatus
                commission
                createdAt
                description
                id
                toAddress {
                    short_name
                    long_name
                    types
                }
                fromAddress {
                    short_name
                    long_name
                    types
                }
                client {
                    id
                    name
                    phoneNumber
                    email
                }
                timeFrom
                timeTill
                courier {
                    id
                    phoneNumber
                    name
                    email
                }
                images {
                    id
                    link
                }
                currency {
                    symbol
                    name
                }
                purchaseCost
                tipsCost
            }
            meta {
                ...MetaFragment
            }
        }
    }
    ${metaFragment}
`;

export const findAllOrdersQuery$ = gqlQueryOperation<OrderAdminResolverInterface, 'findAllOrders'>(
    adminApolloClient,
    'findAllOrders',
    FIND_ALL_ORDERS
);
