import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { findUsersQuery$ } from '../../queries/find-users.query';
import { clientListLoadAction, clientListLoadFailedAction, clientListLoadSuccessAction } from '../client.action';

import type { Epic } from 'redux-observable';

export const clientListEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(clientListLoadAction),
        toPayload(),
        switchMap(variables =>
            findUsersQuery$(variables).pipe(
                map(users => clientListLoadSuccessAction(serializeDateFields(users))),
                catchError((error: unknown) => [clientListLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
