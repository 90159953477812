import { createApolloClient } from '@dotgoclub/apollo-components';

import { authRefreshAction, authSessionExpiredAction } from './state/auth.actions';
import { createAuthLoginEpic } from './state/epics/auth-login.epic';
import { createAuthRequestOtpEpic } from './state/epics/auth-request-otp.epic';

import type { AuthEventOptionsInterface } from './interface/auth-event-options.interface';
import type { AuthRootState } from './state/auth.slice';
import type { ApolloClient, ApolloLink, NormalizedCacheObject } from '@apollo/client';
import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';
import type { StoreInterface } from '@dotgoclub/state-components';

export const createAuthApolloClient = <S extends AuthRootState>(
    apiUrl: string,
    reduxStore: StoreInterface<S>,
    events: AuthEventOptionsInterface,
    additionalLinks?: ApolloLink[]
): ApolloClient<NormalizedCacheObject> => {
    const getAuthorizationHeader = (): string => reduxStore.store.getState().auth.credentials.accessToken;
    const getRefreshToken = (): string => reduxStore.store.getState().auth.credentials.refreshToken;
    const onRefreshSuccess = (token: JwtTokensResponseInterface): void => void reduxStore.store.dispatch(authRefreshAction(token));
    const onRefreshError = (): void => void reduxStore.store.dispatch(authSessionExpiredAction());

    const apolloClient = createApolloClient(
        apiUrl,
        getAuthorizationHeader,
        getRefreshToken,
        onRefreshSuccess,
        onRefreshError,
        additionalLinks
    );

    reduxStore.addEpic(createAuthRequestOtpEpic(apolloClient, events.onOTPSuccess, events.onOTPFailed));
    reduxStore.addEpic(createAuthLoginEpic(apolloClient, events.onLoginSuccess, events.onLoginFailed));

    return apolloClient;
};
