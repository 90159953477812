import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Text } from 'react-native';
import { useSelector } from 'react-redux';

import { authIsAuthorizedSelector } from '@dotgoclub/auth-components';

import { AuthPage } from '../../../auth';
import { AdminRoutesEnum } from '../../enum/admin-routes.enum';
import { adminNavigationRef } from '../../navigation/admin-navigation-ref';
import { AdminAuthorizedNavigator } from '../admin-authorized-navigator/admin-authorized-navigator';
import { linkingConfig } from '../linking.config';

import type { RootStackParamList } from '../linking.config';
import type { FC } from 'react';

const Stack = createStackNavigator<RootStackParamList>();

const formatter = (options?: Record<string, string>): string => `${options?.title ?? ''} - OnTheWay!`;

export const AdminRootNavigator: FC = () => {
    const isAuthorized = useSelector(authIsAuthorizedSelector);

    return (
        <NavigationContainer
            documentTitle={{ formatter }}
            fallback={<Text>Loading...</Text>}
            linking={linkingConfig}
            ref={adminNavigationRef}
        >
            <Stack.Navigator screenOptions={{ headerShown: false }}>
                {!isAuthorized && (
                    <Stack.Screen component={AuthPage} name={AdminRoutesEnum.Auth} options={{ title: 'Authorization' }} />
                )}
                {isAuthorized ? <Stack.Screen component={AdminAuthorizedNavigator} name={AdminRoutesEnum.Main} /> : null}
            </Stack.Navigator>
        </NavigationContainer>
    );
};
