import { EMPTY, concatMap, map } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { navigate } from '../admin-navigation-ref';
import { adminNavigateParamsAction } from '../admin-navigation.action';

import type { Action } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';

export const adminNavigationParamsEpic: Epic<Action, Action, unknown> = actions$ =>
    actions$.pipe(
        ofType(adminNavigateParamsAction),
        toPayload(),
        map(({ route, params }) => void navigate(route, params)),
        concatMap(() => EMPTY)
    );
