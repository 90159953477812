import { setContext } from '@apollo/client/link/context';

import { getAuthHeaderUtil } from '../util/get-auth-header.util';

import type { ApolloLink } from '@apollo/client';

interface ApolloContextHeaders {
    headers: Record<string, string>;
}

export const createAuthLink = (getAuthToken: () => string): ApolloLink =>
    setContext(
        (_request, { headers }: ApolloContextHeaders): ApolloContextHeaders => ({
            headers: {
                ...headers,
                ...getAuthHeaderUtil(getAuthToken())
            }
        })
    );
