import { emptyBaseEntity } from '../../../common';
import { emptyCoordinates } from '../../interfaces';

import type { BaseEntityInterface } from '../../../common';
import type { CoordinatesInterface } from '../../interfaces';
import type { CountryDtoInterface } from './country-dto.interface';

export interface CityDtoInterface extends BaseEntityInterface {
    coordinates: CoordinatesInterface;
    country?: CountryDtoInterface;
    countryId: string;
    name: string;
    timezone: string;
}

export const emptyCityDto: CityDtoInterface = {
    ...emptyBaseEntity,
    ...emptyCoordinates,
    countryId: '',
    name: '',
    timezone: '',
    coordinates: emptyCoordinates
};
