import { Flex } from '@rnw-community/fast-style';

export const PricingFormStyle = {
    Form: {
        ...Flex.column.spaceBetween.center,
        width: '100%'
    },
    FormGroup: {
        width: '70%'
    },
    FormControl: {
        maxWidth: '278px'
    }
};
