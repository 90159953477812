import { getFont } from '@rnw-community/fast-style';

import { AdminColorsEnum } from '../enum/admin-colors.enum';
import { AdminFontFamilyEnum } from '../enum/admin-font-family.enum';
import { AdminFontSizeEnum } from '../enum/admin-font-size.enum';

export const AdminFont = getFont(AdminFontFamilyEnum, AdminFontSizeEnum, AdminColorsEnum, {
    includeFontPadding: false,
    textAlignVertical: 'center'
});
