import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, map } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { updateStoreByIdMutation$ } from '../../queries/update-store-item.mutation';
import {
    updateSupplierStoreItemAction,
    updateSupplierStoreItemFailedAction,
    updateSupplierStoreItemSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const updateStoreByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(updateSupplierStoreItemAction),
        toPayload(),
        exhaustMap(({ storeId, input }) =>
            updateStoreByIdMutation$({ storeId, input }).pipe(
                map(store => {
                    displayToast({ type: 'success', header: `Store has been successfully updated` });

                    return updateSupplierStoreItemSuccessAction(serializeDateFields(store));
                }),
                catchError((err: unknown) => {
                    const errorMsg = getErrorMessage(err);
                    displayToast({ type: 'error', header: `Store update failed : ${errorMsg}` });

                    return [updateSupplierStoreItemFailedAction(errorMsg)];
                })
            )
        )
    );
