import { AppRegistry } from 'react-native';

import './index.css';
import { AdminApp } from '@dotgoclub/admin-components';

import { reportWebVitals } from './reportWebVitals';

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
AppRegistry.registerComponent('dotgoclub', () => AdminApp);
AppRegistry.runApplication('dotgoclub', {
    rootTag: document.getElementById('root')
});

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
