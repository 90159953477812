import { clientSlice } from './client.slice';

export const {
    listLoad: clientListLoadAction,
    listLoadFailed: clientListLoadFailedAction,
    listLoadSuccess: clientListLoadSuccessAction,
    itemLoad: clientItemLoadAction,
    itemLoadSuccess: clientItemLoadSuccessAction,
    itemLoadFailed: clientItemLoadFailedAction,
    itemSave: clientItemSaveAction,
    itemSaveSuccess: clientItemSaveSuccessAction,
    itemSaveFailed: clientItemSaveFailedAction
} = clientSlice.actions;
