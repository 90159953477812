import React, { FC } from 'react';
import { Form } from 'rsuite';

import { H1, InputFormGroup } from '../../../../general';
import { PricingFormStyle } from '../pricing-form.styles';

export const FormGroupTipsCost: FC = () => (
    <Form.Group style={PricingFormStyle.FormGroup}>
        <H1>Tips cost</H1>
        <InputFormGroup fieldLabel="maximumTipsCost" label="Max tips cost" />
        <InputFormGroup fieldLabel="tipsCostStep" label="Step tips cost" />
    </Form.Group>
);
