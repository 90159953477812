import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, mergeMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { AdminRoutesEnum, adminNavigateParamsAction } from '../../../../general';
import { displayToast } from '../../../../general/helper/display-toast';
import { createSupplierMutation$ } from '../../queries/create-supplier.mutation';
import { createSupplierItemAction, createSupplierItemFailedAction, createSupplierItemSuccessAction } from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const createSupplierEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(createSupplierItemAction),
        toPayload(),
        exhaustMap(input =>
            createSupplierMutation$({ input }).pipe(
                mergeMap(() => {
                    displayToast({ type: 'success', header: `Supplier has been successfully created` });

                    return [adminNavigateParamsAction({ route: AdminRoutesEnum.Suppliers }), createSupplierItemSuccessAction()];
                }),
                catchError((err: unknown) => {
                    const errorMsg = getErrorMessage(err);
                    displayToast({ type: 'error', header: `Supplier creation failed : ${errorMsg}` });

                    return [createSupplierItemFailedAction(errorMsg)];
                })
            )
        )
    );
