import { combineReducers } from '@reduxjs/toolkit';
import { getEnv } from '@rnw-community/platform';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';

import { authReducer, authSlice } from '@dotgoclub/auth-components';
import { configureAppStore } from '@dotgoclub/state-components';

import { suppliersReducer, suppliersSlice } from '../../catalog/suppliers';
import { clientReducer, clientSlice } from '../../clients';
import { orderReducer, orderSlice } from '../../orders';
import { pricingReducer, pricingSlice } from '../../pricing';

import type { AdminRootState } from './admin-root.state';

const rootReducer = combineReducers({
    [authSlice.name]: authReducer,
    [clientSlice.name]: clientReducer,
    [pricingSlice.name]: pricingReducer,
    [orderSlice.name]: orderReducer,
    [suppliersSlice.name]: suppliersReducer
});

const persistConfig = {
    key: getEnv('STORAGE_PREFIX') ?? '',
    storage,
    whitelist: ['auth', 'suppliers', 'client', 'order', 'pricing'],
    stateReconciler: autoMergeLevel2
};

export const adminReduxStore = configureAppStore<AdminRootState>(rootReducer, persistConfig);
