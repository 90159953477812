import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { metaFragment } from './../../general/fragments/meta.fragment';
import { pricingFragment } from './fragments/pricing.fragment';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findAllPricingsQuery$ = gqlQueryOperation<PricingAdminResolverInterface, 'findAllPricings'>(
    adminApolloClient,
    'findAllPricings',
    gql`
        query findAllPricings($pagination: PaginationArgs!) {
            findAllPricings(pagination: $pagination) {
                items {
                    ...PricingFragment
                    currency {
                        name
                    }
                }
                meta {
                    ...MetaFragment
                }
            }
        }
        ${pricingFragment}
        ${metaFragment}
    `
);
