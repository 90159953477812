import EditIcon from '@rsuite/icons/Edit';
import React from 'react';
import { IconButton } from 'rsuite';

import type { FC } from 'react';

interface Props {
    handleClick: (id: string) => void;
    rowData: { id: string };
}

export const EditCell: FC<Props> = ({ rowData, handleClick }) => {
    const handleEdit = (id: string) => () => void handleClick(id);

    return <IconButton icon={<EditIcon />} onClick={handleEdit(rowData.id)} />;
};
