import { useFocusEffect } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Table } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, EditCell, useAdminNavigation } from '../../../../general';
import { suppliersListLoadAction } from '../../state/suppliers.action';
import { suppliersListSelector } from '../../state/suppliers.selector';

import type { SupplierDtoInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

const { Column, HeaderCell, Cell } = Table;

export const AdminSuppliersPage: FC = () => {
    const dispatch = useDispatch();

    const { list, isLoading } = useSelector(suppliersListSelector);

    const navigation = useAdminNavigation();

    useFocusEffect(useCallback(() => void dispatch(suppliersListLoadAction()), []));

    const handleShowSupplierPress = (id: string) => (): void => void navigation.navigate(AdminRoutesEnum.Supplier, { id });
    const handleEditPress = (id: string): void => void navigation.navigate(AdminRoutesEnum.EditSupplierForm, { supplierId: id });
    const handleCreateSupplierPress = (): void => void navigation.navigate(AdminRoutesEnum.AddSupplierForm);

    return (
        <AdminPageContent title="Suppliers">
            <ButtonToolbar>
                <Button appearance="primary" color="green" onClick={handleCreateSupplierPress}>
                    Create supplier
                </Button>
            </ButtonToolbar>
            <Table data={list} height={800} loading={isLoading}>
                <Column flexGrow={1}>
                    <HeaderCell>ID</HeaderCell>
                    <Cell>
                        {(row: SupplierDtoInterface) => (
                            <Button appearance="link" color="blue" onClick={handleShowSupplierPress(row.id)} size="sm">
                                {row.id.substring(0, 6)}
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Description</HeaderCell>
                    <Cell dataKey="description" />
                </Column>
                <Column>
                    <HeaderCell>Edit</HeaderCell>
                    <Cell>{(rowData: SupplierDtoInterface) => <EditCell handleClick={handleEditPress} rowData={rowData} />}</Cell>
                </Column>
            </Table>
        </AdminPageContent>
    );
};
