import { CardPaymentMethodEnum } from '../../enum';

export interface CardPaymentMethodInputInterface {
    cardCVV: string;
    cardExpiration: string;
    cardNumber: string;
    type: CardPaymentMethodEnum;
}

export const emptyCardPaymentMethodInput: CardPaymentMethodInputInterface = {
    cardCVV: '',
    cardExpiration: '',
    cardNumber: '',
    type: CardPaymentMethodEnum.UnknownCard
};
