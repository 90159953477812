import React from 'react';
import { Checkbox, Table } from 'rsuite';

import type { FC } from 'react';
import type { ValueType } from 'rsuite/esm/Checkbox';

interface Props {
    checkedKeys: string[];
    dataKey: string;
    onChange: (value: string) => void;
}

export const CheckCell: FC<Props> = ({ onChange, checkedKeys, dataKey, ...props }) => (
    <Table.Cell {...props}>
        {(rowData: Record<string, unknown>) => (
            <Checkbox
                checked={checkedKeys.some(item => item === rowData[dataKey])}
                inline
                onChange={onChange as (value: ValueType) => void}
                value={rowData[dataKey] as string}
            />
        )}
    </Table.Cell>
);
