import { CardPaymentMethodEnum } from './card-payment-method.enum';
import { WalletPaymentMethodEnum } from './wallet-payment-method.enum';

export const PaymentMethodEnum = {
    ...CardPaymentMethodEnum,
    ...WalletPaymentMethodEnum
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentMethodEnum = CardPaymentMethodEnum | WalletPaymentMethodEnum;
