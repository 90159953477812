import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, map } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { updateCategoryByIdMutation$ } from '../../queries/update-category-by-id.mutation';
import {
    updateSupplierCategoryItemAction,
    updateSupplierCategoryItemFailedAction,
    updateSupplierCategoryItemSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const updateCategoryByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(updateSupplierCategoryItemAction),
        toPayload(),
        exhaustMap(({ categoryId, input }) =>
            updateCategoryByIdMutation$({ categoryId, input }).pipe(
                map(category => {
                    displayToast({ type: 'success', header: 'Category updated successfully!' });

                    return updateSupplierCategoryItemSuccessAction(serializeDateFields(category));
                }),
                catchError((error: unknown) => {
                    const errorMsg = getErrorMessage(error);
                    displayToast({ type: 'error', header: `Category update failed: ${errorMsg}` });

                    return [updateSupplierCategoryItemFailedAction(errorMsg)];
                })
            )
        )
    );
