import { StoreEnhancer, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import { Subject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { logError } from '@dotgoclub/logger-lib';

import { getFlipperMiddleware } from './get-flipper-middleware/get-flipper-middleware.util';

import type { EpicInterface } from './interface/epic.interface';
import type { StoreInterface } from './interface/store.interface';
import type { Action, Reducer } from '@reduxjs/toolkit';
import type { Epic, StateObservable } from 'redux-observable';
import type { PersistConfig } from 'redux-persist';
import type { Observable } from 'rxjs';

// TODO: Fix eslint errors and Epic types
export const configureAppStore = <T>(
    rootReducer: Reducer<T>,
    persistConfig: PersistConfig<T>,
    enhancers: StoreEnhancer[] = []
): StoreInterface<T> => {
    const epic$ = new Subject<Epic>();
    const epicMiddleware = createEpicMiddleware<Action, Action>();
    const flipperMiddleware = getFlipperMiddleware();

    const middlewares = getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
        thunk: false
    });

    middlewares.push(epicMiddleware);

    if (flipperMiddleware !== null) {
        middlewares.push(flipperMiddleware);
    }

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = configureStore({
        reducer: persistedReducer,
        middleware: middlewares,
        devTools: __DEV__,
        enhancers
    });
    const persistor = persistStore(store);

    // https://redux-observable.js.org/docs/basics/SettingUpTheMiddleware.html
    const rootEpic: EpicInterface = (action$: Observable<Action>, state$: StateObservable<void>, dependencies: unknown) =>
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        epic$.pipe(
            mergeMap(epic =>
                epic(action$, state$, dependencies).pipe(
                    catchError((error: unknown, source) => {
                        logError(error);

                        return source;
                    })
                )
            )
        );

    epicMiddleware.run(rootEpic);

    return { store, persistor, addEpic: epic => void epic$.next(epic), addEpics: newEpics => newEpics.map(epic$.next.bind(epic$)) };
};
