import { EMPTY, concatMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { navigate } from '../admin-navigation-ref';
import { adminNavigateAction } from '../admin-navigation.action';

import type { Action } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';

export const adminNavigationEpic: Epic<Action, Action, unknown> = actions$ =>
    actions$.pipe(
        ofType(adminNavigateAction),
        toPayload(),
        concatMap(route => {
            navigate(route);

            return EMPTY;
        })
    );
