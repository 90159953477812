export const roundTimeQuarterHour = (time = new Date()): Date => {
    const timeCopy = new Date(time.getTime());
    const minutes = time.getMinutes();

    timeCopy.setMilliseconds(0);
    timeCopy.setSeconds(0);
    timeCopy.setMinutes(Math.ceil(minutes / 15) * 15);

    return timeCopy;
};
