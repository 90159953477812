import { toaster } from 'rsuite';

import { notificationBar } from '../components/notification-bar/notification-bar';

import type { MessageType } from 'rsuite/esm/Notification/Notification';

interface Props {
    header: string;
    type: MessageType;
}
export const displayToast = ({ type, header }: Props): void => {
    toaster.push(notificationBar({ type, header }), {
        placement: 'topEnd'
    });
};
