export const numericEnumFromNumberMatrix = <T extends string>(cost: number, matrix: Record<T, number>): T => {
    const enumKeys = Object.keys(matrix) as unknown as T[];
    let [prevEnumKey] = enumKeys;

    for (const enumKey of enumKeys) {
        if (cost < matrix[enumKey]) {
            return prevEnumKey;
        }

        prevEnumKey = enumKey;
    }

    return prevEnumKey;
};
