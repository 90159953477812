import EditIcon from '@rsuite/icons/Edit';
import React from 'react';
import { IconButton, Table } from 'rsuite';

import { H2 } from '../../../../general';

import type { CategoryDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { OnEventFn } from '@rnw-community/shared';
import type { FC } from 'react';

const { Column, HeaderCell, Cell } = Table;

interface Props {
    categories: Array<WithSerializedDates<CategoryDtoInterface>>;
    isLoading: boolean;
    onEditPress: OnEventFn<string>;
}

export const CategoryTable: FC<Props> = ({ onEditPress, categories, isLoading }) => {
    const handleEditPress = (id: string) => () => void onEditPress(id);

    return (
        <>
            <H2>Categories</H2>
            <Table data={categories} height={800} loading={isLoading}>
                <Column flexGrow={2}>
                    <HeaderCell>ID</HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>External ID</HeaderCell>
                    <Cell dataKey="externalId" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Parent Category ID</HeaderCell>
                    <Cell dataKey="parentCategoryId" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>&nbsp;</HeaderCell>
                    <Cell>{(row: CategoryDtoInterface) => <IconButton icon={<EditIcon />} onClick={handleEditPress(row.id)} />}</Cell>
                </Column>
            </Table>
        </>
    );
};
