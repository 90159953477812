import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { findPricingQuery$ } from '../../queries/find-pricing.query';
import { pricingItemLoadAction, pricingItemLoadFailedAction, pricingItemLoadSuccessAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const pricingItemLoadEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingItemLoadAction),
        toPayload(),
        switchMap(id =>
            findPricingQuery$({ id }).pipe(
                map(data => pricingItemLoadSuccessAction(serializeDateFields(data))),
                catchError((error: unknown) => [pricingItemLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
