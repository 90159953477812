import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { clientFragment } from './fragments/client.fragment';

import type { UserAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findUserQuery$ = gqlQueryOperation<UserAdminResolverInterface, 'findUser'>(
    adminApolloClient,
    'findUser',
    gql`
        query findUser($id: ID!) {
            findUser(id: $id) {
                ...ClientFragment
                quickbloxId
                quickbloxPassword
            }
        }
        ${clientFragment}
    `
);
