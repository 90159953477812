export enum DeliveryDistanceEnum {
    Long = 'Long',
    Medium = 'Medium',
    Short = 'Short',
    XLong = 'XLong'
}

export const deliveryDistanceMatrix: Record<DeliveryDistanceEnum, number> = {
    [DeliveryDistanceEnum.Short]: 0,
    [DeliveryDistanceEnum.Medium]: 2000,
    [DeliveryDistanceEnum.Long]: 5000,
    [DeliveryDistanceEnum.XLong]: 10000
};
