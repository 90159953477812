import React from 'react';
import { Button, Text, View } from 'react-native';

import { AdminErrorStyles } from './admin-error.styles';

import type { FC } from 'react';
import type { FallbackProps } from 'react-error-boundary';

export const AdminError: FC<FallbackProps> = ({ resetErrorBoundary }) => (
    <View style={AdminErrorStyles.wrapper}>
        <View style={AdminErrorStyles.header}>
            <Text style={AdminErrorStyles.headerText}>Ooops, something went wrong</Text>
            <Text style={AdminErrorStyles.subHeaderText}>Dont worry, our talented developer team already working on it!</Text>
        </View>
        <Button color="yellow" onPress={resetErrorBoundary} title="Please try again" />
    </View>
);
