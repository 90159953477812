import type { WithSerializedDates } from './typescript-generics';

const serializeFn = <T>(obj: T): WithSerializedDates<T> =>
    Object.entries(obj).reduce(
        (acc: WithSerializedDates<T>, [key, value]: [string, unknown]): WithSerializedDates<T> => {
            let newValue = value;

            if (value instanceof Date) {
                try {
                    newValue = value.toISOString();
                } catch (err: unknown) {
                    newValue = '';
                }
            } else if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    newValue = value.map(listObject => {
                        if (typeof listObject === 'object' && listObject !== null) {
                            return serializeFn(listObject);
                        }

                        return listObject as unknown;
                    });
                } else {
                    newValue = serializeDateFields(value);
                }
            }

            return { ...acc, [key]: newValue };
        },
        // TODO: Investigate if we can omit eslint ignores
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions
        {} as WithSerializedDates<T>
    );

/** @deprecated We have removed this check from the redux */
export const serializeDateFields = <T>(obj: T): WithSerializedDates<T> => serializeFn(obj);
/** @deprecated We have removed this check from the redux */
export const serializeDateFieldsArray = <T>(obj: T[]): Array<WithSerializedDates<T>> => obj.map(serializeFn);
