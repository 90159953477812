import { emptyBaseEntity } from '../../../common';

import { emptyProductVariantDto } from './product-variant-dto.interface';
import { emptyStoreDto } from './store-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { ProductVariantDtoInterface } from './product-variant-dto.interface';
import type { StoreDtoInterface } from './store-dto.interface';

export interface ProductInventoryDtoInterface extends BaseEntityInterface {
    discountPercentage: number;
    externalClientId: string;
    price: number;
    pricePerUnit: number;
    productVariant: ProductVariantDtoInterface;
    store: StoreDtoInterface;
}

export const emptyProductInventoryDto: ProductInventoryDtoInterface = {
    ...emptyBaseEntity,
    discountPercentage: 0,
    externalClientId: '',
    price: 0,
    pricePerUnit: 0,
    productVariant: emptyProductVariantDto,
    store: emptyStoreDto
};
