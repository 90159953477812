import { StyleSheet } from 'react-native';

import { AdminFont } from '../../styles/admin-font';
import { adminStep } from '../../styles/admin-step';

export const H2Styles = StyleSheet.create({
    text: {
        ...AdminFont.Inter.xl.black
    },
    textCentered: {
        textAlign: 'center'
    },
    wrapper: {
        marginBottom: adminStep(2)
    }
});
