import { isNotEmptyString } from '@rnw-community/shared';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import { createStoreItemAction, supplierStoreItemSelector } from '../../../suppliers';
import { StoreForm } from '../store-form/store-form';

import { storeFormInitialValues } from './store-form-initial-values';

import type { UpdateStoreInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const AddStoreFormPage: FC = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(supplierStoreItemSelector);

    const navigation = useAdminNavigation();

    const { supplierId } = useAdminNavigationRouteParams<AdminRoutesEnum.AddStoreForm>();

    const handleSuppliersBreadcrumbPress = (): void => void navigation.navigate(AdminRoutesEnum.Suppliers);
    const handleSupplierBreadcrumbPress = (): void =>
        void (isNotEmptyString(supplierId) && navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId }));
    const handleFormSubmit = (input: UpdateStoreInputInterface): void =>
        void (isNotEmptyString(supplierId) && dispatch(createStoreItemAction({ ...input, supplierId })));

    const handleCancelButtonPress = (): void =>
        void (isNotEmptyString(supplierId) && navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId }));

    const title = 'Create store';

    const breadcrumbs = [
        createBreadcrumb('Suppliers', false, handleSuppliersBreadcrumbPress),
        createBreadcrumb(`Supplier "${supplierId ?? ''}"`, false, handleSupplierBreadcrumbPress),
        createBreadcrumb(title, true)
    ];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={isLoading ? 'Loading...' : title}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <StoreForm initialValues={storeFormInitialValues} onCancel={handleCancelButtonPress} onSubmit={handleFormSubmit} />
            )}
        </AdminPageContent>
    );
};
