import { getFocusedRouteNameFromRoute } from '@react-navigation/core';
import DashboardIcon from '@rsuite/icons/Dashboard';
import ExitIcon from '@rsuite/icons/Exit';
import BlindIcon from '@rsuite/icons/legacy/Blind';
import BuildingIcon from '@rsuite/icons/legacy/Building';
import EurIcon from '@rsuite/icons/legacy/Eur';
import OrderFormIcon from '@rsuite/icons/legacy/OrderForm';
import React from 'react';
import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Nav, Sidenav } from 'rsuite';

import { authLogoutAction } from '@dotgoclub/auth-components';

import { ReactComponent as LogoImg } from '../../../assets/logo.svg';
import { AdminRoutesEnum } from '../../enum/admin-routes.enum';
import { useAdminNavigationRoute } from '../../hooks/use-admin-navigation-route.hook';

import { AdminMenuStyles } from './admin-menu.styles';

import type { DrawerContentComponentProps } from '@react-navigation/drawer';
import type { FC } from 'react';

interface Props extends DrawerContentComponentProps {}

export const AdminMenu: FC<Props> = ({ navigation }) => {
    const dispatch = useDispatch();

    const router = useAdminNavigationRoute();
    const routeName = getFocusedRouteNameFromRoute(router);

    const navigate = (route: AdminRoutesEnum) => () => void navigation.navigate(route);
    const handleLogout = (): void => void dispatch(authLogoutAction());

    return (
        <View style={AdminMenuStyles.container}>
            <LogoImg style={AdminMenuStyles.logo} />
            <Sidenav style={AdminMenuStyles.container}>
                <Sidenav.Body>
                    <Nav>
                        <Nav.Item
                            active={routeName === AdminRoutesEnum.Home}
                            icon={<DashboardIcon />}
                            onSelect={navigate(AdminRoutesEnum.Home)}
                        >
                            Home
                        </Nav.Item>
                        <Nav.Item
                            active={routeName === AdminRoutesEnum.Orders}
                            icon={<OrderFormIcon />}
                            onSelect={navigate(AdminRoutesEnum.Orders)}
                        >
                            Orders
                        </Nav.Item>
                        <Nav.Item
                            active={routeName === AdminRoutesEnum.Clients}
                            icon={<BlindIcon />}
                            onSelect={navigate(AdminRoutesEnum.Clients)}
                        >
                            Clients
                        </Nav.Item>
                        <Nav.Item
                            active={routeName === AdminRoutesEnum.Pricing}
                            icon={<EurIcon />}
                            onSelect={navigate(AdminRoutesEnum.Pricing)}
                        >
                            Pricing
                        </Nav.Item>
                        <Nav.Item
                            active={[AdminRoutesEnum.Suppliers, AdminRoutesEnum.Supplier].includes(routeName as AdminRoutesEnum)}
                            icon={<BuildingIcon />}
                            onSelect={navigate(AdminRoutesEnum.Suppliers)}
                        >
                            Suppliers
                        </Nav.Item>
                        <Nav.Item icon={<ExitIcon />} onSelect={handleLogout}>
                            Log out
                        </Nav.Item>
                    </Nav>
                </Sidenav.Body>
            </Sidenav>
        </View>
    );
};
