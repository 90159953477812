import { isApolloError } from '@apollo/client';

import { ErrorCodesEnum } from '@dotgoclub/client-contracts';

const isErrorCodeEnum = (val: string): val is ErrorCodesEnum =>
    Object.values(ErrorCodesEnum).includes(val as unknown as ErrorCodesEnum);

export const getApolloErrorCode = (err: unknown): ErrorCodesEnum | undefined => {
    if (err instanceof Error && isApolloError(err)) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const errCode = err.graphQLErrors?.[0]?.extensions?.code as unknown;
        if (typeof errCode === 'string' && isErrorCodeEnum(errCode)) {
            return errCode;
        }
    }

    return undefined;
};
