import { ApolloLink } from '@apollo/client';

const omitTypename = <T>(key: string, value: T): T | undefined => (key === '__typename' ? undefined : value);

export const cleanTypeNameLink = new ApolloLink((operation, forward) => {
    const isFileUpload = operation.getContext().hasUpload as boolean;

    if ('variables' in operation && !isFileUpload) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename) as Record<string, unknown>;
    }

    return forward(operation);
});
