import type { RecursiveOmit } from './typescript-generics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeObjectFields = <T extends O, O extends Record<string, any>>(
    obj: T,
    excludeFields: Array<keyof O>
): RecursiveOmit<T, O> =>
    Object.keys(obj).reduce((acc, key) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const value = obj[key];

        if (!excludeFields.includes(key)) {
            if (typeof value === 'object' && value !== null) {
                return { ...acc, [key]: removeObjectFields(value, excludeFields) };
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return { ...acc, [key]: value };
        }

        return acc;
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions
    }, {} as RecursiveOmit<T, O>);
