import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { pricingFragment } from './fragments/pricing.fragment';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findPricingQuery$ = gqlQueryOperation<PricingAdminResolverInterface, 'findPricing'>(
    adminApolloClient,
    'findPricing',
    gql`
        query findPricing($id: ID!) {
            findPricing(id: $id) {
                ...PricingFragment
            }
        }
        ${pricingFragment}
    `
);
