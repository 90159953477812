import { emptyDistancePricingInput } from './distance-pricing-input.interface';
import { emptySizePricingInput } from './size-pricing-input.interface';
import { emptyTimePricingInput } from './time-pricing-input.interface';
import { emptyValuePricingInput } from './value-pricing-input.interface';

import type { PricingEntityInterface } from '../../entities';
import type { DistancePricingInputInterface } from './distance-pricing-input.interface';
import type { SizePricingInputInterface } from './size-pricing-input.interface';
import type { TimePricingInputInterface } from './time-pricing-input.interface';
import type { ValuePricingInputInterface } from './value-pricing-input.interface';

export interface PricingInputInterface
    extends Pick<
        PricingEntityInterface,
        | 'cityId'
        | 'commission'
        | 'countryId'
        | 'defaultPurchaseCost'
        | 'deliveryFeeStepsCount'
        | 'deliveryFeeStepValue'
        | 'intervalBetweenTimeFromAndTimeTillInHours'
        | 'intervalDeliveryTimeInHours'
        | 'maximumTipsCost'
        | 'maxPurchaseCost'
        | 'minimalPrice'
        | 'stepPurchaseCost'
        | 'tipsCostStep'
    > {
    distance: DistancePricingInputInterface;
    size: SizePricingInputInterface;
    time: TimePricingInputInterface;
    value: ValuePricingInputInterface;
}

export const emptyPricingInput: PricingInputInterface = {
    minimalPrice: 0,
    defaultPurchaseCost: 0,
    commission: 0,
    countryId: '',
    cityId: '',
    intervalBetweenTimeFromAndTimeTillInHours: 0,
    intervalDeliveryTimeInHours: 0,
    distance: emptyDistancePricingInput,
    size: emptySizePricingInput,
    time: emptyTimePricingInput,
    value: emptyValuePricingInput,
    maxPurchaseCost: 0,
    stepPurchaseCost: 0,
    tipsCostStep: 0,
    maximumTipsCost: 0,
    deliveryFeeStepsCount: 0,
    deliveryFeeStepValue: 0
};
