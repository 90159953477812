import { pricingSlice } from './pricing.slice';

export const {
    listLoad: pricingListLoadAction,
    listLoadFailed: pricingListLoadFailedAction,
    listLoadSuccess: pricingListLoadSuccessAction,
    itemLoad: pricingItemLoadAction,
    itemLoadFailed: pricingItemLoadFailedAction,
    itemLoadSuccess: pricingItemLoadSuccessAction,
    currenciesLoad: pricingCurrenciesLoadAction,
    currenciesLoadSuccess: pricingCurrenciesLoadSuccessAction,
    currenciesLoadFailed: pricingCurrenciesLoadFailedAction,
    countriesLoad: pricingCountriesLoadAction,
    countriesLoadSuccess: pricingCountriesLoadSuccessAction,
    countriesLoadFailed: pricingCountriesLoadFailedAction,
    itemSave: pricingSaveAction,
    itemSaveSuccess: pricingSaveSuccessAction,
    itemSaveFailed: pricingSaveFailedAction,
    itemCreate: pricingItemCreateAction,
    itemCreateSuccess: pricingItemCreateSuccessAction,
    itemCreateFailed: pricingItemCreateFailedAction,
    itemDelete: pricingItemDeleteAction,
    itemDeleteSuccess: pricingItemDeleteSuccessAction,
    itemDeleteFailed: pricingItemDeleteFailedAction
} = pricingSlice.actions;
