import { emptyBaseEntity } from '../../../common';

import { emptySupplierDto } from './supplier-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CatalogImageDtoInterface } from './catalog-image-dto.interface';
import type { ProductDtoInterface } from './product-dto.interface';
import type { SupplierDtoInterface } from './supplier-dto.interface';

export interface CategoryDtoInterface extends BaseEntityInterface {
    childCategories: CategoryDtoInterface[];
    description: string;
    externalId: string;
    image?: CatalogImageDtoInterface;
    name: string;
    parentCategory?: CategoryDtoInterface;
    parentCategoryId?: string;
    productMainCategory: ProductDtoInterface[];
    productSideCategories: ProductDtoInterface[];
    supplier: SupplierDtoInterface;
}

export const emptyCategoryDto: CategoryDtoInterface = {
    ...emptyBaseEntity,
    childCategories: [],
    description: '',
    externalId: '',
    name: '',
    parentCategoryId: '',
    productMainCategory: [],
    productSideCategories: [],
    supplier: emptySupplierDto
};
