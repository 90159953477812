import { Flex } from '@rnw-community/fast-style';
import { StyleSheet } from 'react-native';

export const AuthPageStyles = StyleSheet.create({
    wrapper: {
        ...Flex.row.center.center,
        flex: 1,
        height: '100%'
    }
});
