import { GraphQLScalarType, Kind } from 'graphql';

import type { ValueNode } from 'graphql';

export const dateScalar = new GraphQLScalarType({
    name: 'Date',
    serialize(value: Date) {
        return value.toISOString();
    },
    parseValue(value: string) {
        return new Date(value);
    },
    parseLiteral(ast: ValueNode) {
        if (!('value' in ast)) {
            return null;
        }
        const { kind, value } = ast;
        if (kind === Kind.STRING && typeof value === 'string') {
            return new Date(parseInt(value, 10));
        }

        return null;
    }
});
