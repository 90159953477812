import { isDefined } from '@rnw-community/shared';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { Form, InputNumber, Message } from 'rsuite';

import type { FC } from 'react';

interface Props {
    fieldLabel: string;
    label: string;
}

export const InputFormGroup: FC<Props> = ({ label, fieldLabel }) => {
    const [field, meta] = useField<string>(fieldLabel);
    const { setFieldValue, setFieldTouched } = useFormikContext();

    const handleChange = (fieldName: string) => (value: number | string) => {
        setFieldTouched(fieldName, true);
        setFieldValue(fieldName, Number(value), true);
    };

    return (
        <Form.Group>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <InputNumber onChange={handleChange(field.name)} value={field.value} />
            {meta.touched && isDefined(meta.error) ? <Message type="error"> {meta.error}</Message> : null}
        </Form.Group>
    );
};
