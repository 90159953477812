import { DeliveryDistanceEnum } from '../../enum';

export interface DistancePricingInputInterface extends Partial<Record<DeliveryDistanceEnum, number>> {}

export const emptyDistancePricingInput: DistancePricingInputInterface = {
    [DeliveryDistanceEnum.Short]: 0,
    [DeliveryDistanceEnum.Medium]: 0,
    [DeliveryDistanceEnum.Long]: 0,
    [DeliveryDistanceEnum.XLong]: 0
};
