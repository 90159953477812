import React from 'react';
import { ScrollView, View } from 'react-native';
import { Breadcrumb } from 'rsuite';

import { AdminRoutesEnum } from '../../enum/admin-routes.enum';
import { createBreadcrumb } from '../../helper/create-breadcrumb';
import { useAdminNavigation } from '../../hooks/use-admin-navigation.hook';
import { H1 } from '../h1/h1';

import { AdminPageContentStyles as styles } from './admin-page-content.styles';

import type { BreadcrumbInterface } from '../../helper/create-breadcrumb';
import type { FC } from 'react';

interface Props {
    breadcrumbs?: BreadcrumbInterface[];
    title: string;
}

export const AdminPageContent: FC<Props> = ({
    title,
    children,
    breadcrumbs: additionalBreadcrumbs = [createBreadcrumb(title, true)]
}) => {
    const navigation = useAdminNavigation();

    const handleHomeBreadcrumbPress = (): void => void navigation.navigate(AdminRoutesEnum.Home);

    const breadcrumbs = [
        createBreadcrumb('Home', additionalBreadcrumbs.length === 0, handleHomeBreadcrumbPress),
        ...additionalBreadcrumbs
    ];

    return (
        <ScrollView style={styles.wrapper}>
            <Breadcrumb>
                {breadcrumbs.map(breadcrumb => {
                    const handleBreadcrumbPress = (): void => void breadcrumb.onClick();

                    return (
                        <Breadcrumb.Item
                            active={breadcrumb.isActive}
                            key={`breadcrumb-${breadcrumb.title}`}
                            onClick={handleBreadcrumbPress}
                        >
                            {breadcrumb.title}
                        </Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
            <H1 title={title} />
            <View style={styles.contentWrapper}>{children}</View>
        </ScrollView>
    );
};
