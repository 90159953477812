import { Formik } from 'formik';
import React from 'react';
import { Button, ButtonToolbar, Form } from 'rsuite';
import * as yup from 'yup';

import { useFormikNavUpdateHook } from '../../../../general';
import { FormControlWithLabel } from '../../../../general/components/form-control-with-label';

import type { UpdateSupplierInputInterface } from '@dotgoclub/client-contracts';
import type { OnEventFn } from '@rnw-community/shared';

interface Props {
    initialValues: UpdateSupplierInputInterface;
    onCancel: OnEventFn;
    onSubmit: OnEventFn<UpdateSupplierInputInterface>;
}

const validationUpdateSchema = yup.object().shape({
    name: yup.string().strict(true).trim().min(2, 'Too short').max(250, 'Too long').required('required'),
    description: yup.string().strict(true).trim().max(1000, 'Too long'),
    src: yup.string().label('Image source').strict(true).trim().max(500).required('required'),
    alt: yup.string().label('Image alternative text').strict(true).trim().max(200)
});

export const SupplierForm = ({ initialValues, onSubmit, onCancel }: Props): JSX.Element => {
    const formikRef = useFormikNavUpdateHook(initialValues);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            onSubmit={onSubmit}
            validationSchema={validationUpdateSchema}
        >
            {({ submitForm, handleChange, errors, values, isValid, dirty }) => (
                <Form>
                    <FormControlWithLabel
                        error={errors.name}
                        label="Name"
                        name="name"
                        onChange={handleChange('name')}
                        value={values.name}
                    />
                    <FormControlWithLabel
                        error={errors.description}
                        label="Description"
                        name="description"
                        onChange={handleChange('description')}
                        value={values.description}
                    />
                    <FormControlWithLabel
                        error={errors.src}
                        label="Image source"
                        name="src"
                        onChange={handleChange('src')}
                        value={values.src}
                    />
                    <FormControlWithLabel
                        error={errors.alt}
                        label="Image alternative text"
                        name="alt"
                        onChange={handleChange('alt')}
                        value={values.alt}
                    />
                    <ButtonToolbar>
                        <Button appearance="primary" disabled={!isValid || !dirty} onClick={() => void submitForm()}>
                            Save
                        </Button>
                        <Button appearance="subtle" onClick={onCancel}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </Form>
            )}
        </Formik>
    );
};
