import { gql } from '@apollo/client';

export const categoryFragment = gql`
    fragment CategoryFragment on Category {
        id
        description
        name
        externalId
        parentCategoryId
    }
`;
