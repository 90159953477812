import { createStoreEpic } from './epic/create-store.epic';
import { createSupplierEpic } from './epic/create-supplier.epic';
import { loadCategoriesBySupplierIdEpic } from './epic/load-categories-by-supplier-id.epic';
import { loadCategoryByIdEpic } from './epic/load-category-by-id.epic';
import { loadStoreByIdEpic } from './epic/load-store-by-id.epic';
import { loadStoresBySupplierIdEpic } from './epic/load-stores-by-supplier-id.epic';
import { loadSupplierByIdEpic } from './epic/load-supplier-by-id.epic';
import { loadSuppliersOnInitEpic } from './epic/load-suppliers-on-init.epic';
import { loadSuppliersEpic } from './epic/load-suppliers.epic';
import { updateCategoryByIdEpic } from './epic/update-category-by-id.epic';
import { updateStoreByIdEpic } from './epic/update-store-by-id.epic';
import { updateSupplierByIdEpic } from './epic/update-supplier-by-id.epic';

export const suppliersEpics = [
    loadSuppliersEpic,
    loadCategoriesBySupplierIdEpic,
    loadCategoryByIdEpic,
    loadSuppliersOnInitEpic,
    loadSupplierByIdEpic,
    loadStoresBySupplierIdEpic,
    loadStoreByIdEpic,
    createStoreEpic,
    createSupplierEpic,
    updateCategoryByIdEpic,
    updateSupplierByIdEpic,
    updateStoreByIdEpic
];
