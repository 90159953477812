import { createSelector } from '@reduxjs/toolkit';

import type { PricingRootState } from './pricing.slice';
import type { PricingState } from './pricing.state';

export const pricingSelector = (state: PricingRootState): PricingState => state.pricing;
export const pricingListSelector = createSelector(pricingSelector, state => state.list);
export const pricingItemSelector = createSelector(pricingSelector, state => state.item);
export const currenciesSelector = createSelector(pricingSelector, state => state.currencies);
export const countriesSelector = createSelector(pricingSelector, state => state.countries);
