import { authSlice } from './auth.slice';

export const {
    otpSuccess: authOtpSuccessAction,
    otpFailed: authOtpFailedAction,
    login: authLoginAction,
    loginSuccess: authLoginSuccessAction,
    loginFailed: authLoginFailedAction,
    cancelOtp: authCancelOtpAction,
    otp: authOtpAction,
    logout: authLogoutAction,
    refresh: authRefreshAction,
    sessionExpired: authSessionExpiredAction,
    completeOnBoarding: authCompleteOnBoardingAction,
    resetOtpState: authResetOtpStateAction
} = authSlice.actions;
