import { useIsFocused } from '@react-navigation/core';
import { isDefined, isNotEmptyString } from '@rnw-community/shared';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { Text } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Form, Loader, Message, Toggle } from 'rsuite';
import * as yup from 'yup';

import {
    AdminPageContent,
    AdminRoutesEnum,
    useAdminNavigation,
    useAdminNavigationRouteParams,
    useFormikNavUpdateHook
} from '../../../general';
import { clientItemLoadAction, clientItemSaveAction } from '../../state/client.action';
import { clientItemSelector } from '../../state/client.selector';

import { AdminClientFormPageStyle as styles } from './admin-client-form.page.styles';

import type { UpdateUserInputInterface, UserDtoInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

import 'yup-phone';

const userUpdateSchema = yup.object().shape({
    name: yup.string().strict(true).trim().min(2, 'Too short').max(50, 'Too long').required('required'),
    email: yup.string().strict(true).trim().email('Invalid email').required('Required'),
    phoneNumber: yup.string().phone().required()
});

// eslint-disable-next-line max-lines-per-function
export const AdminClientFormPage: FC = () => {
    const dispatch = useDispatch();

    const { item: user, isLoading } = useSelector(clientItemSelector);

    const initialValues: UpdateUserInputInterface = {
        name: user.name ?? '',
        email: user.email ?? '',
        phoneNumber: user.phoneNumber,
        isAdmin: user.isAdmin,
        quickbloxId: user.quickbloxId ?? 0,
        quickbloxPassword: user.quickbloxPassword ?? ''
    };

    const isRouteUpdated = useIsFocused();
    const { id = '' } = useAdminNavigationRouteParams<AdminRoutesEnum.ClientForm>();
    const navigation = useAdminNavigation();
    const formikRef = useFormikNavUpdateHook(initialValues);

    useEffect(() => void dispatch(clientItemLoadAction(id)), [isRouteUpdated, id]);

    const handleFormSubmit = (input: UpdateUserInputInterface): void => {
        if (isNotEmptyString(id)) {
            dispatch(clientItemSaveAction({ id, input }));
        }
    };
    const handleCancelAction = (): void => void navigation.navigate(AdminRoutesEnum.Clients);

    return (
        <AdminPageContent title={`User: ${initialValues.name ?? ''}`}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <Formik
                    initialValues={initialValues}
                    innerRef={formikRef}
                    onSubmit={handleFormSubmit}
                    validationSchema={userUpdateSchema}
                >
                    {({ submitForm, handleChange, errors, setFieldValue, values, isValid, dirty }) => {
                        const handleCheckBox = (field: keyof Pick<UserDtoInterface, 'isAdmin'>) => (): void =>
                            void setFieldValue(field, !(values[field] ?? false));
                        const handleNumberChange =
                            (field: keyof UserDtoInterface) =>
                            (value: number): void =>
                                void setFieldValue(field, Number(value));

                        const cannotSave = !isValid || !dirty;

                        return (
                            <Form>
                                <Form.Group>
                                    <Form.ControlLabel>General information</Form.ControlLabel>
                                    <Form.Control
                                        name="name"
                                        onChange={handleChange('name')}
                                        style={styles.formControl}
                                        value={values.name}
                                    />
                                    {isDefined(errors.name) && <Message header={errors.name} type="error" />}

                                    <Form.Control
                                        name="email"
                                        onChange={handleChange('email')}
                                        style={styles.formControl}
                                        value={values.email}
                                    />
                                    {isDefined(errors.email) && <Message header={errors.email} type="error" />}

                                    <Form.Control
                                        name="phoneNumber"
                                        onChange={handleChange('phoneNumber')}
                                        style={styles.formControl}
                                        value={values.phoneNumber}
                                    />
                                    {isDefined(errors.phoneNumber) && <Message header={errors.phoneNumber} type="error" />}

                                    <Toggle
                                        checked={values.isAdmin}
                                        checkedChildren={<Text style={styles.toggleText}>Is admin</Text>}
                                        id="isAdmin"
                                        onChange={handleCheckBox('isAdmin')}
                                        unCheckedChildren={<Text style={styles.toggleText}>Not admin</Text>}
                                    />
                                </Form.Group>

                                <Form.Group>
                                    <Form.ControlLabel>Quickblox</Form.ControlLabel>
                                    <Form.Control
                                        name="quickbloxId"
                                        onChange={handleNumberChange('quickbloxId')}
                                        placeholder="Id"
                                        style={styles.formControl}
                                        value={values.quickbloxId}
                                    />
                                    {isDefined(errors.quickbloxId) && <Message header={errors.quickbloxId} type="error" />}
                                    <Form.Control
                                        name="quickbloxPassword"
                                        onChange={handleChange('quickbloxPassword')}
                                        placeholder="Password"
                                        style={styles.formControl}
                                        value={values.quickbloxPassword}
                                    />
                                    {isDefined(errors.quickbloxPassword) && <Message header={errors.quickbloxPassword} type="error" />}
                                </Form.Group>

                                <ButtonToolbar>
                                    <Button appearance="primary" disabled={cannotSave} onClick={() => void submitForm()}>
                                        Save
                                    </Button>
                                    <Button color="red" onClick={handleCancelAction}>
                                        Cancel
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        );
                    }}
                </Formik>
            )}
        </AdminPageContent>
    );
};
