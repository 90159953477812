import { StyleSheet } from 'react-native';

import { AdminColorsEnum } from '../../enum/admin-colors.enum';
import { adminStep } from '../../styles/admin-step';

export const AdminPageContentStyles = StyleSheet.create({
    contentWrapper: {
        overflowY: 'auto',
        paddingVertical: adminStep(2)
    },
    wrapper: {
        backgroundColor: AdminColorsEnum.white,
        height: '100vh',
        padding: adminStep(2)
    }
});
