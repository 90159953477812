import { filter } from 'rxjs/operators';

import type { ActionCreatorWithPayload, AnyAction, PayloadAction } from '@reduxjs/toolkit';
import type { Observable } from 'rxjs';

// TODO: read https://github.com/redux-observable/redux-observable/issues/706
export const ofType =
    <P, T extends string, O extends PayloadAction<P, T>>(...filterActions: Array<ActionCreatorWithPayload<P, T>>) =>
    (action$: Observable<AnyAction>): Observable<O> => {
        const { length } = filterActions;

        // TODO: Investigate if this optimization makes sense
        let compareFn = (action: AnyAction): action is O => action.type === filterActions[0].type;
        const compareTwo = (action: AnyAction): action is O =>
            action.type === filterActions[0].type || action.type === filterActions[1].type;
        const compareThree = (action: AnyAction): action is O =>
            action.type === filterActions[0].type || action.type === filterActions[1].type || action.type === filterActions[2].type;
        const compareMore = (action: AnyAction): action is O => {
            for (let i = 0; i < length; i += 1) {
                if (action.type === filterActions[i].type) {
                    return true;
                }
            }

            return false;
        };

        if (length === 2) {
            compareFn = compareTwo;
        } else if (length === 3) {
            compareFn = compareThree;
        } else {
            compareFn = compareMore;
        }

        return action$.pipe(filter(compareFn));
    };
