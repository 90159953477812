export interface BaseEntityInterface {
    createdAt: Date;
    deletedAt: Date;
    id: string;
    updatedAt: Date;
}

export const emptyBaseEntity: BaseEntityInterface = {
    createdAt: new Date(),
    deletedAt: new Date(),
    id: '',
    updatedAt: new Date()
};

export type OmitBaseEntity<T extends BaseEntityInterface, TKey extends keyof T = keyof BaseEntityInterface> = Omit<
    T,
    TKey | keyof BaseEntityInterface
>;
