import type { ReportHandler } from 'web-vitals';

export const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
    if (onPerfEntry instanceof Function) {
        void import('web-vitals')
            // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
            .then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
                getCLS(onPerfEntry);
                getFID(onPerfEntry);
                getFCP(onPerfEntry);
                getLCP(onPerfEntry);
                getTTFB(onPerfEntry);

                return null;
            });
    }
};
