import { emptyBaseEntity } from '../../../common';
import { emptyCoordinates } from '../../../geolocation';

import { emptySupplierDto } from './supplier-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CoordinatesInterface } from '../../../geolocation';
import type { ProductInventoryDtoInterface } from './product-inventory-dto.interface';
import type { SupplierDtoInterface } from './supplier-dto.interface';

export interface StoreDtoInterface extends BaseEntityInterface {
    address: string;
    cityId: string;
    coordinates: CoordinatesInterface;
    externalId: string;
    mapPlaceId: string;
    name: string;
    productInventory: ProductInventoryDtoInterface[];
    supplier: SupplierDtoInterface;
    type: string;
    zip: string;
}

export const emptyStoreDto: StoreDtoInterface = {
    address: '',
    cityId: '',
    coordinates: emptyCoordinates,
    externalId: '',
    mapPlaceId: '',
    name: '',
    productInventory: [],
    supplier: emptySupplierDto,
    type: '',
    zip: '',
    ...emptyBaseEntity
};
