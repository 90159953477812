import { useFocusEffect } from '@react-navigation/core';
import { isNotEmptyString } from '@rnw-community/shared';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import {
    supplierItemSelector,
    supplierStoreItemLoadAction,
    supplierStoreItemSelector,
    updateSupplierStoreItemAction
} from '../../../suppliers';
import { StoreForm } from '../store-form/store-form';

import type { UpdateStoreInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const EditStoreFormPage: FC = () => {
    const dispatch = useDispatch();

    const { item: store, isLoading } = useSelector(supplierStoreItemSelector);
    const { item: supplier } = useSelector(supplierItemSelector);

    const initialValues: UpdateStoreInputInterface = {
        cityId: store.cityId,
        externalId: store.externalId,
        mapPlaceId: store.mapPlaceId,
        name: store.name,
        coordinates: store.coordinates,
        address: store.address,
        type: store.type,
        zip: store.zip
    };

    const navigation = useAdminNavigation();

    const { storeId, supplierId } = useAdminNavigationRouteParams<AdminRoutesEnum.EditStoreForm>();

    useFocusEffect(useCallback(() => void (isNotEmptyString(storeId) && dispatch(supplierStoreItemLoadAction(storeId))), [storeId]));

    const handleSupplierBreadcrumbPress = (): void =>
        void (isNotEmptyString(supplierId) && navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId }));
    const handleSuppliersBreadcrumbPress = (): void => void navigation.navigate(AdminRoutesEnum.Suppliers);
    const handleFormSubmit = (input: UpdateStoreInputInterface): void => {
        if (isNotEmptyString(supplierId) && isNotEmptyString(storeId)) {
            dispatch(updateSupplierStoreItemAction({ storeId, input }));
        }
    };
    const handleCancelButtonPress = (): void =>
        void (isNotEmptyString(supplierId) && navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId }));

    const breadcrumbs = [
        createBreadcrumb('Suppliers', false, handleSuppliersBreadcrumbPress),
        createBreadcrumb(`Supplier "${supplier.name}"`, false, handleSupplierBreadcrumbPress),
        createBreadcrumb(`Store "${store.name}"`, true)
    ];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={isLoading ? 'Loading...' : `Edit store "${store.name}"`}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <StoreForm initialValues={initialValues} onCancel={handleCancelButtonPress} onSubmit={handleFormSubmit} />
            )}
        </AdminPageContent>
    );
};
