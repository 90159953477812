import { initialLoadingState } from '@rnw-community/redux-loadable';

import { emptyJwtTokensResponse } from '@dotgoclub/client-contracts';

import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';
import type { LoadingStateInterface } from '@rnw-community/redux-loadable';

export interface AuthState {
    areOnBoardingScreensRequired: boolean;
    credentials: JwtTokensResponseInterface;
    isOtpRequested: boolean;
    isSessionExpired: boolean;
    otpEnter: LoadingStateInterface;
    otpRequestNextDate?: string | null;
    phoneEnter: LoadingStateInterface;
    phoneNumber: string;
}

export const initialAuthState: AuthState = {
    phoneEnter: { ...initialLoadingState },
    otpEnter: { ...initialLoadingState },
    isSessionExpired: false,
    isOtpRequested: false,
    credentials: emptyJwtTokensResponse,
    phoneNumber: '',
    areOnBoardingScreensRequired: true
};
