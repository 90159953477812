import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { storeFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const getStoresBySupplierIdQuery$ = gqlQueryOperation<CatalogAdminResolverInterface, 'getStoresBySupplierId'>(
    adminApolloClient,
    'getStoresBySupplierId',
    gql`
        query getStoresBySupplierId($supplierId: ID!) {
            getStoresBySupplierId(supplierId: $supplierId) {
                ...StoreFragment
            }
        }
        ${storeFragment}
    `
);
