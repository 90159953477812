import { isDefined } from '@rnw-community/shared';
import { from, map } from 'rxjs';

import type { GQLResponseRoot, Resolver, ResolverMethodArgs, ResolverMethodReturn } from './gql-operation';
import type { ApolloClient, DocumentNode, NormalizedCacheObject } from '@apollo/client';
import type { Observable } from 'rxjs';

type Mutation<R extends Resolver<M>, M extends keyof R, V = ResolverMethodArgs<R, M>, Return = ResolverMethodReturn<R, M>> = (
    variables: V
) => Observable<Return>;

export const gqlMutationOperation =
    <
        R extends Resolver<M>,
        M extends keyof R,
        V = ResolverMethodArgs<R, M>,
        Return = ResolverMethodReturn<R, M>,
        C = NormalizedCacheObject
    >(
        client: ApolloClient<C>,
        method: M,
        mutation: DocumentNode
    ): Mutation<R, M, V, Return> =>
    (variables: V) =>
        from(client.mutate<GQLResponseRoot<R, M, Return>, V>({ mutation, variables })).pipe(
            map(res => {
                if (isDefined(res.data) && isDefined(res.data[method])) {
                    return res.data[method];
                }

                throw new Error(`Mutation "${method as string}" request failed`);
            })
        );
