import { Flex } from '@rnw-community/fast-style';
import { StyleSheet } from 'react-native';

import { AdminFont, adminStep } from '../../../general';

export const AdminOrdersListStyle = StyleSheet.create({
    searchContainer: {
        ...Flex.row.flexStart.flexEnd
    },
    label: {
        ...AdminFont.Inter.xl.darkGrey,
        padding: adminStep(),
        width: '25%'
    },
    labelContainer: {
        ...Flex.row.flexStart.flexStart
    },
    searchInputContainer: {
        width: '25%'
    },
    selectPickerContainer: {
        paddingLeft: adminStep(),
        paddingRight: adminStep()
    },
    buttonToolbarContainer: {
        marginTop: adminStep(2),
        marginBottom: adminStep(2)
    },
    paginationButtons: {
        marginTop: 50
    }
});
