import { getErrorMessage } from '@rnw-community/shared';
import { catchError, concatMap, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { displayToast } from '../../../general/helper/display-toast';
import { deletePricingMutation$ } from '../../queries/delete-pricing.mutatuion';
import { pricingItemDeleteAction, pricingItemDeleteFailedAction, pricingItemDeleteSuccessAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const pricingItemDeleteEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingItemDeleteAction),
        toPayload(),
        switchMap(id =>
            deletePricingMutation$({ id }).pipe(
                concatMap(data => {
                    displayToast({ type: 'success', header: 'Pricing was successfully deleted' });

                    return [pricingItemDeleteSuccessAction(data)];
                }),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: 'Failed deleting pricing' });

                    return [pricingItemDeleteFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
