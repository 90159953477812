import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { authEpics } from './auth/state/auth.epic';
import { suppliersEpics } from './catalog/suppliers/state/suppliers.epic';
import { clientEpics } from './clients/state/client.epic';
import { AdminError, AdminRootNavigator } from './general';
import { adminApolloClient } from './general/admin-apollo-client';
import { adminNavigationEpics } from './general/navigation/admin-navigation.epic';
import { adminRootEpics } from './general/state/admin-root.epic';
import { adminReduxStore } from './general/state/admin-root.reducer';
import { orderEpics } from './orders/state/order.epic';
import { pricingEpics } from './pricing/state/pricing.epic';

import type { FC } from 'react';

import 'rsuite/dist/rsuite.css';

// HINT: All epics should be loaded asynchronously in this file to reduce cycle references
adminReduxStore.addEpics([
    ...adminRootEpics,
    ...adminNavigationEpics,
    ...authEpics,
    ...clientEpics,
    ...orderEpics,
    ...pricingEpics,
    ...suppliersEpics
]);

export const AdminApp: FC = () => {
    const handleError = (error: Error): void => void Sentry.captureException(error);
    const handleReset = (): void => {
        window.localStorage.clear();
        window.location.reload();
    };

    return (
        <ErrorBoundary FallbackComponent={AdminError} onError={handleError} onReset={handleReset}>
            <Provider store={adminReduxStore.store}>
                <PersistGate persistor={adminReduxStore.persistor}>
                    <ApolloProvider client={adminApolloClient}>
                        <AdminRootNavigator />
                    </ApolloProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    );
};
