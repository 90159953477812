import { useFocusEffect } from '@react-navigation/core';
import { isNotEmptyString } from '@rnw-community/shared';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import {
    supplierCategoryItemLoadAction,
    supplierCategoryItemSelector,
    supplierItemSelector,
    updateSupplierCategoryItemAction
} from '../../../suppliers';
import { SupplierForm } from '../supplier-form/supplier-form';

import type { UpdateCategoryInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const EditSupplierCategoryFormPage: FC = () => {
    const dispatch = useDispatch();

    const { categoryId, supplierId } = useAdminNavigationRouteParams<AdminRoutesEnum.EditCategoryForm>();

    const { item: supplier } = useSelector(supplierItemSelector);
    const { item: category, isLoading } = useSelector(supplierCategoryItemSelector);

    const initialValues = {
        name: category.name,
        description: category.description,
        src: category.image?.src ?? '',
        alt: category.image?.alt ?? ''
    };

    const navigation = useAdminNavigation();

    useFocusEffect(
        useCallback(() => void (isNotEmptyString(categoryId) && dispatch(supplierCategoryItemLoadAction(categoryId))), [categoryId])
    );

    const handleCancelAction = (): void => {
        if (isNotEmptyString(supplierId)) {
            navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId });
        }
    };
    const handleFormSubmit = (input: UpdateCategoryInputInterface): void => {
        if (isNotEmptyString(supplierId) && isNotEmptyString(categoryId)) {
            dispatch(updateSupplierCategoryItemAction({ categoryId, input }));
        }
    };

    const handleSuppliersBreadcrumbPress = (): void => void navigation.navigate(AdminRoutesEnum.Suppliers);
    const handleSupplierBreadcrumbPress = (): void => {
        if (isNotEmptyString(supplierId)) {
            void navigation.navigate(AdminRoutesEnum.Supplier, { id: supplierId });
        }
    };

    const breadcrumbs = [
        createBreadcrumb('Suppliers', false, handleSuppliersBreadcrumbPress),
        createBreadcrumb(`Supplier "${supplier.name}"`, false, handleSupplierBreadcrumbPress),
        createBreadcrumb(`Category "${category.name}"`, true)
    ];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={isLoading ? 'Loading...' : `Edit category "${category.name}"`}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <SupplierForm initialValues={initialValues} onCancel={handleCancelAction} onSubmit={handleFormSubmit} />
            )}
        </AdminPageContent>
    );
};
