export interface CoordinatesInterface {
    latitude: number;
    longitude: number;
}

export const emptyCoordinates: CoordinatesInterface = {
    latitude: 0,
    longitude: 0
};

export const isCoordinates = (coordinatesObj: unknown): coordinatesObj is CoordinatesInterface =>
    typeof coordinatesObj === 'object' && coordinatesObj !== null && 'latitude' in coordinatesObj && 'longitude' in coordinatesObj;

export const isEmptyCoordinates = (coordinatesObject: CoordinatesInterface): boolean =>
    coordinatesObject.longitude === emptyCoordinates.longitude && coordinatesObject.latitude === emptyCoordinates.latitude;

export const isCoordinatesEqual = (first: CoordinatesInterface, second: CoordinatesInterface): boolean =>
    first.latitude === second.latitude && first.longitude === second.longitude;
