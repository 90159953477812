import { addDays, isToday, subHours } from 'date-fns';

import { resetDateHourAndMin } from './reset-date-hour-and-min.util';
import { roundTimeQuarterHour } from './round-time-quarter-hour.util';

export const getRangeTimeFrom = (
    timeFrom: Date,
    intervalBetweenTimeFromAndTimeTillInHours: number,
    currentDate = new Date()
): [min: Date, max: Date] => {
    const maxTimeTill = resetDateHourAndMin(23, 45, timeFrom);
    const minDate = isToday(timeFrom) ? roundTimeQuarterHour(currentDate) : resetDateHourAndMin(0, 0, timeFrom);
    const maxDate = subHours(maxTimeTill, intervalBetweenTimeFromAndTimeTillInHours);
    const limitDate = addDays(resetDateHourAndMin(0, 0, maxDate), 1);

    return [minDate > maxDate ? limitDate : minDate, maxDate];
};
