import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { supplierFragment } from './fragments';

import type { CatalogResolverInterface } from '@dotgoclub/client-contracts';

export const getAllSuppliersQuery$ = gqlQueryOperation<CatalogResolverInterface, 'getAllSuppliers'>(
    adminApolloClient,
    'getAllSuppliers',
    gql`
        query getAllSuppliers {
            getAllSuppliers {
                ...SupplierFragment
            }
        }
        ${supplierFragment}
    `
);
