export enum DeliverySizeEnum {
    Large = 'Large',
    Medium = 'Medium',
    Small = 'Small'
}

const DeliverySizeDescriptionMap: Record<DeliverySizeEnum, string[]> = {
    [DeliverySizeEnum.Small]: ['up to 1 kg', 'max length 70 cm'],
    [DeliverySizeEnum.Medium]: ['up to 2 kg', 'max length 70 cm'],
    [DeliverySizeEnum.Large]: ['up to 5 kg', 'max length 70 cm']
};

export const getDeliverySizeDescriptionArray = (deliverySize: DeliverySizeEnum): string[] => DeliverySizeDescriptionMap[deliverySize];
