import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { imageFragment } from './../../../general/fragments/image.fragment';
import { supplierFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const updateSupplierByIdMutation$ = gqlMutationOperation<CatalogAdminResolverInterface, 'updateSupplierById'>(
    adminApolloClient,
    'updateSupplierById',
    gql`
        mutation updateSupplierById($supplierId: ID!, $input: UpdateSupplierInput!) {
            updateSupplierById(supplierId: $supplierId, input: $input) {
                ...SupplierFragment
                image {
                    ...ImageFragment
                }
            }
        }
        ${supplierFragment}
        ${imageFragment}
    `
);
