import { gql } from '@apollo/client';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

import { emptyJwtTokensResponse } from '@dotgoclub/client-contracts';

import type { ApolloClient } from '@apollo/client';
import type { JwtTokensResponseInterface, LoginArgsInterface } from '@dotgoclub/client-contracts';
import type { Observable } from 'rxjs';

const LOGIN_MUTATION = gql`
    mutation login($phoneNumber: String!, $otp: String!) {
        login(phoneNumber: $phoneNumber, otp: $otp) {
            accessToken
            refreshToken
        }
    }
`;

interface LoginMutation {
    login: JwtTokensResponseInterface;
}

export const loginMutation$ = <T>(
    apolloClient: ApolloClient<T>,
    variables: LoginArgsInterface
): Observable<JwtTokensResponseInterface> =>
    from(apolloClient.mutate<LoginMutation, LoginArgsInterface>({ mutation: LOGIN_MUTATION, variables })).pipe(
        map(res => res.data?.login ?? emptyJwtTokensResponse)
    );
