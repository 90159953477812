import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { imageFragment } from './../../../general/fragments/image.fragment';
import { supplierFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const getSupplierByIdQuery$ = gqlQueryOperation<CatalogAdminResolverInterface, 'getSupplierById'>(
    adminApolloClient,
    'getSupplierById',
    gql`
        query getSupplierById($supplierId: ID!) {
            getSupplierById(supplierId: $supplierId) {
                ...SupplierFragment
                image {
                    ...ImageFragment
                }
            }
        }
        ${supplierFragment}
        ${imageFragment}
    `
);
