import { Epic } from 'redux-observable';
import { filter, map } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { AdminRoutesEnum } from '../../../../general';
import { getCurrentAdminNavigationRoute } from '../../../../general/navigation/admin-navigation-ref';
import { adminInitAction } from '../../../../general/state/admin-root.actions';
import { suppliersListLoadAction } from '../suppliers.action';

export const loadSuppliersOnInitEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(adminInitAction),
        map(() => getCurrentAdminNavigationRoute()),
        filter(currentRoute => [AdminRoutesEnum.Supplier, AdminRoutesEnum.EditCategoryForm].includes(currentRoute)),
        map(() => suppliersListLoadAction())
    );
