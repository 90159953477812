import { AdminRoutesEnum } from '../enum/admin-routes.enum';

export interface RootStackParamList extends Record<string, Record<string, string> | undefined> {
    [AdminRoutesEnum.AddStoreForm]: { supplierId: string };
    [AdminRoutesEnum.AddSupplierForm]: undefined;
    [AdminRoutesEnum.Auth]: undefined;
    [AdminRoutesEnum.ClientForm]: { id: string };
    [AdminRoutesEnum.Clients]: undefined;
    [AdminRoutesEnum.CreatePricingForm]: undefined;
    [AdminRoutesEnum.EditStoreForm]: { storeId: string; supplierId: string };
    [AdminRoutesEnum.EditCategoryForm]: { categoryId: string; supplierId: string };
    [AdminRoutesEnum.EditSupplierForm]: { supplierId: string };
    [AdminRoutesEnum.Home]: undefined;
    [AdminRoutesEnum.Main]: undefined;
    [AdminRoutesEnum.NotFound]: undefined;
    [AdminRoutesEnum.Orders]: { searchParam?: string };
    [AdminRoutesEnum.Pricing]: undefined;
    [AdminRoutesEnum.PricingForm]: { id: string };
    [AdminRoutesEnum.Supplier]: { id: string };
    [AdminRoutesEnum.Suppliers]: undefined;
}

export const linkingConfig = {
    prefixes: [],
    config: {
        screens: {
            [AdminRoutesEnum.Main]: {
                path: '',
                screens: {
                    [AdminRoutesEnum.Home]: 'home',
                    [AdminRoutesEnum.Clients]: 'clients',
                    [AdminRoutesEnum.ClientForm]: 'clients/:id',
                    [AdminRoutesEnum.Orders]: 'orders',
                    [AdminRoutesEnum.Pricing]: 'pricing',
                    [AdminRoutesEnum.PricingForm]: 'pricing/:id',
                    [AdminRoutesEnum.CreatePricingForm]: 'pricing/create',
                    [AdminRoutesEnum.Suppliers]: 'suppliers',
                    [AdminRoutesEnum.Supplier]: 'suppliers/:id',
                    [AdminRoutesEnum.EditSupplierForm]: 'suppliers/:supplierId/edit',
                    [AdminRoutesEnum.EditCategoryForm]: 'suppliers/:supplierId/category/:categoryId/edit',
                    [AdminRoutesEnum.EditStoreForm]: 'suppliers/:supplierId/store/:storeId/edit',
                    [AdminRoutesEnum.AddStoreForm]: 'suppliers/:supplierId/store/add',
                    [AdminRoutesEnum.AddSupplierForm]: 'suppliers/add',
                    [AdminRoutesEnum.NotFound]: '*'
                }
            },
            [AdminRoutesEnum.Auth]: 'auth'
        }
    }
};
