import { emptyBaseEntity } from '../../../common';

import { emptyCatalogImageDto } from './catalog-image-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CatalogImageDtoInterface } from './catalog-image-dto.interface';
import type { CategoryDtoInterface } from './category-dto.interface';
import type { StoreDtoInterface } from './store-dto.interface';

export interface SupplierDtoInterface extends BaseEntityInterface {
    categories: CategoryDtoInterface[];
    description: string;
    hasCatalog: boolean;
    image: CatalogImageDtoInterface;
    isActive: boolean;
    name: string;
    stores: StoreDtoInterface[];
}

export const emptySupplierDto: SupplierDtoInterface = {
    ...emptyBaseEntity,
    name: '',
    description: '',
    stores: [],
    categories: [],
    image: emptyCatalogImageDto,
    isActive: false,
    hasCatalog: false
};
