import { ApolloLink, Observable } from '@apollo/client/core';
import { print } from 'graphql';
import { createClient } from 'graphql-ws';

import type { FetchResult, Operation } from '@apollo/client/core';
import type { GraphQLError } from 'graphql';
import type { Client, ClientOptions } from 'graphql-ws';

export class GraphQLWSLink extends ApolloLink {
    private readonly client: Client;

    constructor(options: ClientOptions) {
        super();
        this.client = createClient(options);
    }

    override request(operation: Operation): Observable<FetchResult> {
        return new Observable(sink =>
            this.client.subscribe<FetchResult>(
                { ...operation, query: print(operation.query) },
                {
                    next: sink.next.bind(sink),
                    complete: sink.complete.bind(sink),
                    error: (err: unknown) => {
                        if (err instanceof Error) {
                            return void sink.error(err);
                        }

                        if (err instanceof CloseEvent) {
                            return void sink.error(new Error(`Socket closed with event ${err.code} ${err.reason}`));
                        }

                        if (Array.isArray(err)) {
                            return void sink.error(new Error((err as GraphQLError[]).map(({ message }) => message).join(', ')));
                        }

                        return void sink.error(err);
                    }
                }
            )
        );
    }
}
