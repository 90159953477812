import { ApolloClient, InMemoryCache } from '@apollo/client';

import { createApolloLink } from './create-apollo-link';
import { resolvers } from './scalars';

import type { ApolloLink, NormalizedCacheObject } from '@apollo/client';
import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';

export const createApolloClient = (
    apiUrl: string,
    getAuthToken: () => string,
    getRefreshToken: () => string,
    onRefreshSuccess: (credentials: JwtTokensResponseInterface) => void,
    onRefreshError: () => void,
    additionalLinks?: ApolloLink[]
    // eslint-disable-next-line max-params
): ApolloClient<NormalizedCacheObject> => {
    const getLink = (): ApolloLink =>
        createApolloLink(apiUrl, getAuthToken, getRefreshToken, onRefreshSuccess, onRefreshError, additionalLinks);

    const client = new ApolloClient({
        resolvers,
        link: getLink(),
        defaultOptions: {
            query: { fetchPolicy: 'no-cache', errorPolicy: 'none' },
            mutate: { fetchPolicy: 'no-cache', errorPolicy: 'none' },
            watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'none' }
        },
        cache: new InMemoryCache()
    });

    client.onResetStore(async () => void client.setLink(getLink()));

    return client;
};
