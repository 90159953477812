import { Flex } from '@rnw-community/fast-style';

import { adminStep } from '../../../../general';

export const DeleteModalStyle = {
    modal: {
        ...Flex.column.spaceBetween.center
    },
    buttonGroup: {
        marginTop: adminStep(5)
    }
};
