import { gql } from '@apollo/client';

export const clientFragment = gql`
    fragment ClientFragment on User {
        id
        name
        phoneNumber
        email
        isAdmin
    }
`;
