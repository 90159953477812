import { AddressType } from '@googlemaps/google-maps-services-js';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Input, Pagination, SelectPicker, Table } from 'rsuite';

import { OrderStatusEnum, emptyPaginationArgs, getOrderStatusText } from '@dotgoclub/client-contracts';
import { convertFromCents, findTypeInAddressComponents, formatAddressComponents } from '@dotgoclub/utils-lib';

import { AdminPageContent, useAdminNavigationRouteParams } from '../../../general';
import {
    orderCancelSelectedOrdersAction,
    orderListLoadAction,
    orderSearchParamChangedAction,
    orderStatusChangedAction
} from '../../state/order.action';
import { orderSelector } from '../../state/order.selector';
import { CheckCell } from '../check-cell/check-cell';

import { AdminOrdersListStyle as styles } from './admin-orders-list.style';

import type { AdminRoutesEnum } from '../../../general';
import type { OrderDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { FC } from 'react';
import type { ItemDataType } from 'rsuite/esm/@types/common';

interface OrderTableInterface {
    city: string;
    clientEmail?: string;
    clientName?: string;
    comment: string;
    country: string;
    courierId: string;
    courierName: string;
    deliveryCost: number;
    description: string;
    fromAddress: string;
    orderStatus: string;
    purchaseCost: number;
    timeTill: string;
    tipsCost: number;
    toAddress: string;
}

const { Column, HeaderCell, Cell } = Table;

const mapOrderStatusEnum = (obj: typeof OrderStatusEnum): ItemDataType[] =>
    Object.keys(obj).map(item => ({ label: getOrderStatusText(item as OrderStatusEnum), value: item }));

const mapTableData = (data: WithSerializedDates<OrderDtoInterface[]>): OrderTableInterface[] =>
    data.map((order, idx) => ({
        position: idx + 1,
        id: order.id,
        shortId: order.id.substring(0, 6),
        fromAddress: formatAddressComponents(order.fromAddress),
        toAddress: formatAddressComponents(order.toAddress),
        city: findTypeInAddressComponents(order.fromAddress, AddressType.locality),
        country: findTypeInAddressComponents(order.fromAddress, AddressType.country),
        clientName: order.client.name,
        clientInfo: `${order.client.name ?? ''}  ${order.client.phoneNumber}`,
        courierInfo: `  ${order.courier?.name ?? 'No courier'} ${order.courier?.phoneNumber ?? ''}`,
        clientEmail: order.client.email,
        description: order.description,
        total: convertFromCents(order.tipsCost + order.purchaseCost + order.deliveryCost),
        currency: order.currency.name,
        orderStatus: getOrderStatusText(order.orderStatus),
        courierName: order.courier?.name ?? 'No courier',
        courierId: order.courier?.id ?? 'No courier id',
        comment: order.comment,
        deliveryCost: convertFromCents(order.deliveryCost),
        timeTill: order.timeTill,
        timeFrom: order.timeFrom,
        createdAt: order.createdAt,
        tipsCost: convertFromCents(order.tipsCost),
        purchaseCost: convertFromCents(order.purchaseCost),
        commission: convertFromCents(order.commission)
    }));

// TODO: Make client/courier columns clickable
// eslint-disable-next-line max-lines-per-function,max-statements
export const AdminOrdersList: FC = () => {
    const dispatch = useDispatch();
    const { list, pagination, isLoading, orderStatusFilter, searchParamFilter } = useSelector(orderSelector);

    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);

    const { searchParam: userId } = useAdminNavigationRouteParams<AdminRoutesEnum.Orders>();

    const tableData = mapTableData(list);
    const isCancelButtonDisabled = selectedOrders.length === 0;

    useEffect(() => void dispatch(orderListLoadAction({ pagination: emptyPaginationArgs })), []);
    useEffect(() => void setSelectedOrders([]), [list]);
    useEffect(() => {
        handleUpdateClientInput(userId ?? '');
        dispatch(orderListLoadAction({ pagination: emptyPaginationArgs, filter: { searchParam: userId } }));
    }, [userId]);

    const handleChangePage = (page: number): void =>
        void dispatch(orderListLoadAction({ pagination: { page, limit: pagination.itemsPerPage } }));
    const handleChangeLength = (limit: number): void =>
        void dispatch(orderListLoadAction({ pagination: { page: pagination.currentPage, limit } }));
    const handleStatusSelect = (value: OrderStatusEnum): void => void dispatch(orderStatusChangedAction(value));
    const handleStatusClear = (): void => void dispatch(orderStatusChangedAction(undefined));
    const handleUpdateClientInput = (value: string): void => void dispatch(orderSearchParamChangedAction(value));
    const handleClear = (): void => {
        handleUpdateClientInput('');
        dispatch(orderListLoadAction({ pagination: emptyPaginationArgs }));
    };
    const handleCheckAll = (): void => void setSelectedOrders(list.map(item => item.id));
    const handleCheck = (value: string): void =>
        void setSelectedOrders(
            selectedOrders.some(id => id === value) ? selectedOrders.filter(order => order !== value) : [...selectedOrders, value]
        );
    const handleClearAll = (): void => void setSelectedOrders([]);
    const handleSearchButtonPress = (): void =>
        void dispatch(
            orderListLoadAction({
                pagination: emptyPaginationArgs,
                filter: { orderStatus: orderStatusFilter, searchParam: searchParamFilter }
            })
        );
    const handleCancelSelectedOrders = (): void =>
        void dispatch(
            orderCancelSelectedOrdersAction(
                list
                    .filter(order => order.orderStatus !== OrderStatusEnum.Canceled)
                    .filter(({ id }) => selectedOrders.includes(id))
                    .map(({ id }) => id)
            )
        );

    return (
        <AdminPageContent title="Orders">
            <View style={styles.labelContainer}>
                <Text style={styles.label}>Enter any ID</Text>
                <Text style={styles.label}>Select status</Text>
            </View>
            <View style={styles.searchContainer}>
                <View style={styles.searchInputContainer}>
                    <Input onChange={handleUpdateClientInput} value={searchParamFilter} />
                </View>
                <View style={styles.selectPickerContainer}>
                    <SelectPicker
                        cleanable
                        data={mapOrderStatusEnum(OrderStatusEnum)}
                        onClean={handleStatusClear}
                        onSelect={handleStatusSelect}
                        placeholder="Select status"
                        value={orderStatusFilter}
                    />
                </View>
                <ButtonToolbar>
                    <Button appearance="primary" color="blue" onClick={handleSearchButtonPress}>
                        Search
                    </Button>
                    <Button appearance="primary" color="red" onClick={handleClear}>
                        Clear
                    </Button>
                </ButtonToolbar>
            </View>
            <View style={styles.buttonToolbarContainer}>
                <ButtonToolbar>
                    <Button appearance="primary" color="blue" onClick={handleCheckAll}>
                        Select all
                    </Button>
                    <Button appearance="primary" color="yellow" onClick={handleClearAll}>
                        Clear all
                    </Button>
                    <Button appearance="primary" color="red" disabled={isCancelButtonDisabled} onClick={handleCancelSelectedOrders}>
                        Cancel selected orders ({selectedOrders.length})
                    </Button>
                </ButtonToolbar>
            </View>
            <Table autoHeight data={tableData} loading={isLoading} rowKey="id" wordWrap>
                <Column flexGrow={0.5}>
                    <HeaderCell>Select</HeaderCell>

                    <CheckCell checkedKeys={selectedOrders} dataKey="id" onChange={handleCheck} />
                </Column>
                <Column flexGrow={-5}>
                    <HeaderCell>#</HeaderCell>
                    <Cell dataKey="position" />
                </Column>
                <Column flexGrow={0}>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="shortId" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell dataKey="orderStatus" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Created at</HeaderCell>
                    <Cell dataKey="createdAt" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Time from</HeaderCell>
                    <Cell dataKey="timeFrom" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Time till</HeaderCell>
                    <Cell dataKey="timeTill" />
                </Column>
                <Column flexGrow={1.5}>
                    <HeaderCell>Country</HeaderCell>
                    <Cell dataKey="country" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column flexGrow={3}>
                    <HeaderCell>From</HeaderCell>
                    <Cell dataKey="fromAddress" />
                </Column>
                <Column flexGrow={3}>
                    <HeaderCell>To</HeaderCell>
                    <Cell dataKey="toAddress" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Client Info</HeaderCell>
                    <Cell dataKey="clientInfo" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Courier Info</HeaderCell>
                    <Cell dataKey="courierInfo" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Total</HeaderCell>
                    <Cell dataKey="total" />
                </Column>
                <Column flexGrow={1.5}>
                    <HeaderCell>Currency</HeaderCell>
                    <Cell dataKey="currency" />
                </Column>
                <Column flexGrow={1.5}>
                    <HeaderCell>Purchase cost</HeaderCell>
                    <Cell dataKey="purchaseCost" />
                </Column>
                <Column flexGrow={1.5}>
                    <HeaderCell>Delivery fee</HeaderCell>
                    <Cell dataKey="deliveryCost" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Tips</HeaderCell>
                    <Cell dataKey="tipsCost" />
                </Column>
            </Table>
            <View style={styles.paginationButtons}>
                <Pagination
                    activePage={pagination.currentPage}
                    layout={['total', '-', 'limit', '|', 'pager']}
                    limit={pagination.itemsPerPage}
                    onChangeLimit={handleChangeLength}
                    onChangePage={handleChangePage}
                    total={pagination.totalItems ?? 0}
                />
            </View>
        </AdminPageContent>
    );
};
