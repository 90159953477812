import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFieldsArray } from '@dotgoclub/utils-lib';

import { findAllCurrenciesQuery$ } from '../../queries/find-currencies.query';
import { pricingCurrenciesLoadAction, pricingCurrenciesLoadSuccessAction, pricingItemLoadFailedAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const currenciesLoadEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingCurrenciesLoadAction),
        switchMap(() =>
            findAllCurrenciesQuery$().pipe(
                map(data => pricingCurrenciesLoadSuccessAction(serializeDateFieldsArray(data))),
                catchError((error: unknown) => [pricingItemLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
