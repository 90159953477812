import { isDefined } from '@rnw-community/shared';

import { REFRESH_TOKEN_MUTATION } from '../query/refresh-token.mutation';

import type { RefreshTokenMutation } from '../query/refresh-token.mutation';
import type { ApolloQueryResult } from '@apollo/client';
import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';

export const getRefreshTokenUtil = async (apiUrl: string, refreshToken: string): Promise<JwtTokensResponseInterface> => {
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            query: REFRESH_TOKEN_MUTATION,
            operationName: 'refreshToken',
            variables: {
                refreshToken
            }
        })
    });

    const result = (await response.json()) as ApolloQueryResult<RefreshTokenMutation>;

    if (!isDefined(result.data)) {
        throw new Error('Could not refresh token');
    }

    return result.data.refreshToken;
};
