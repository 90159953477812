import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { findUserQuery$ } from '../../queries/find-user.query';
import { clientItemLoadAction, clientItemLoadFailedAction, clientItemLoadSuccessAction } from '../client.action';

import type { Epic } from 'redux-observable';

export const clientItemLoadEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(clientItemLoadAction),
        toPayload(),
        switchMap(id =>
            findUserQuery$({ id }).pipe(
                map(user => clientItemLoadSuccessAction(serializeDateFields(user))),
                catchError((error: unknown) => [clientItemLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
