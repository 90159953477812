import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const deletePricingMutation$ = gqlMutationOperation<PricingAdminResolverInterface, 'deletePricing'>(
    adminApolloClient,
    'deletePricing',
    gql`
        mutation deletePricing($id: ID!) {
            deletePricing(id: $id)
        }
    `
);
