import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import { createSupplierItemAction } from '../../state/suppliers.action';
import { supplierItemSelector } from '../../state/suppliers.selector';
import { SupplierForm } from '../supplier-form/supplier-form';

import type { CreateSupplierInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const AddSupplierFormPage: FC = () => {
    const dispatch = useDispatch();
    const { isLoading } = useSelector(supplierItemSelector);

    const initialValues: CreateSupplierInputInterface = {
        name: '',
        description: '',
        src: '',
        alt: ''
    };

    const navigation = useAdminNavigation();

    const handleRootSuppliersPress = (): void => void navigation.navigate(AdminRoutesEnum.Suppliers);
    const handleFormSubmit = (input: CreateSupplierInputInterface): void => void dispatch(createSupplierItemAction(input));

    const title = 'Create supplier';

    const breadcrumbs = [createBreadcrumb('Suppliers', false, handleRootSuppliersPress), createBreadcrumb(title, true)];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={isLoading ? 'Loading...' : title}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <SupplierForm initialValues={initialValues} onCancel={handleRootSuppliersPress} onSubmit={handleFormSubmit} />
            )}
        </AdminPageContent>
    );
};
