import { isDefined } from '@rnw-community/shared';
import { Formik } from 'formik';
import React from 'react';
import { Button, ButtonToolbar, Form } from 'rsuite';
import * as yup from 'yup';

import { DeliveryDistanceEnum, DeliverySizeEnum, DeliveryValueEnum, emptyOrderDto } from '@dotgoclub/client-contracts';

import { useFormikNavUpdateHook } from '../../../general';

import { FormGroupCountryCityPrice } from './form-groups/form-group-country-city-price';
import { FormGroupDeliveryFee } from './form-groups/form-group-delivery-fee';
import { FormGroupDistance } from './form-groups/form-group-distance';
import { FormGroupPurchaseCost } from './form-groups/form-group-purchase-cost';
import { FormGroupSize } from './form-groups/form-group-size';
import { FormGroupTime } from './form-groups/form-group-time';
import { FormGroupTipsCost } from './form-groups/form-group-tips-cost';
import { FormGroupValue } from './form-groups/form-group-value';
import { PricingFormStyle } from './pricing-form.styles';

import type { PricingInputInterface } from '@dotgoclub/client-contracts';
import type { OnEventFn } from '@rnw-community/shared';
import type { FC } from 'react';

type Enum = Record<string, string>;
type YupEnumValidator<T extends Enum> = Record<keyof T, yup.NumberSchema<number | undefined, Record<string, unknown>>>;

// TODO: Extract to utils if it will be used elsewhere
const numericEnumToYupValidator = <T extends Record<string, string>>(valuesEnum: T): YupEnumValidator<T> =>
    Object.keys(valuesEnum).reduce<YupEnumValidator<T>>((acc, item) => {
        const validator = yup.number().positive().min(1, 'Must be a positive value').required();

        return {
            ...acc,
            [item]: validator
        };
        // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter,@typescript-eslint/consistent-type-assertions
    }, {} as YupEnumValidator<T>);

const pricingSchema = yup.object().shape({
    minimalPrice: yup.number().min(1, 'Minimal price must be a positive value').required(),
    value: yup.object().shape(numericEnumToYupValidator(DeliveryValueEnum)),
    size: yup.object().shape(numericEnumToYupValidator(DeliverySizeEnum)),
    distance: yup.object().shape(numericEnumToYupValidator(DeliveryDistanceEnum)),
    countryId: yup.string().required('Country is required'),
    cityId: yup.string().required('City is required'),
    commission: yup.number().min(emptyOrderDto.commission, 'Commission cannot be less than 99').required(),
    defaultPurchaseCost: yup.number().min(1, 'Default purchase cost must be a positive value').required(),
    intervalDeliveryTimeInHours: yup
        .number()
        .positive('Minimal interval must be a positive value')
        .max(6, 'Value cannot be greater than 6 hours'),
    intervalBetweenTimeFromAndTimeTillInHours: yup
        .number()
        .positive('Minimal interval must be a positive value')
        .max(6, 'Value cannot be greater than 6 hours'),
    stepPurchaseCost: yup.number().min(1, 'Step purchase cost must be a positive value').required(),
    tipsCostStep: yup.number().min(1, 'Step tips cost must be a positive value').required(),
    maxPurchaseCost: yup.number().min(1, 'Max purchase cost must be a positive value').required(),
    maximumTipsCost: yup.number().min(1, 'Max purchase cost must be a positive value').required()
});

interface Props {
    initialValues: PricingInputInterface;
    onCancel: () => void;
    onDelete?: () => void;
    onSubmit: OnEventFn<PricingInputInterface>;
}

export const PricingForm: FC<Props> = ({ initialValues, onDelete, onSubmit, onCancel }) => {
    const formikRef = useFormikNavUpdateHook(initialValues);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            onSubmit={onSubmit}
            validationSchema={pricingSchema}
        >
            {({ submitForm, isValid, dirty }) => {
                const handleSubmit = (): void => void submitForm();
                const cannotSave = !isValid || !dirty;

                return (
                    <Form fluid style={PricingFormStyle.Form}>
                        <FormGroupCountryCityPrice />
                        <FormGroupTipsCost />
                        <FormGroupDeliveryFee />
                        <FormGroupPurchaseCost />
                        <FormGroupValue />
                        <FormGroupDistance />
                        <FormGroupSize />
                        <FormGroupTime />
                        <ButtonToolbar>
                            <Button appearance="primary" disabled={cannotSave} onClick={handleSubmit}>
                                Save
                            </Button>
                            <Button color="red" onClick={onCancel}>
                                Cancel
                            </Button>
                            {isDefined(onDelete) && (
                                <Button color="red" disabled={!isDefined(onDelete)} onClick={onDelete}>
                                    Delete
                                </Button>
                            )}
                        </ButtonToolbar>
                    </Form>
                );
            }}
        </Formik>
    );
};
