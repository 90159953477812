import { createAction } from '@reduxjs/toolkit';
import { delay, mapTo } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { adminInitAction } from './admin-root.actions';

import type { Epic } from 'redux-observable';

const reduxPersistAction = createAction('persist/REHYDRATE');

const adminRootInitEpic: Epic = actions$ => actions$.pipe(ofType(reduxPersistAction), delay(10), mapTo(adminInitAction()));

export const adminRootEpics = [adminRootInitEpic];
