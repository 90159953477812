export enum WeightUnitEnum {
    'BAR' = 'BAR',
    'BIN' = 'BIN',
    'BLISTER_PACKAGING' = 'BLISTER_PACKAGING',
    'BOTTLE' = 'BOTTLE',
    'BOX' = 'BOX',
    'BUNDLE' = 'BUNDLE',
    'CARTON' = 'CARTON',
    'DUSSELDORF_PALLET' = 'DUSSELDORF_PALLET',
    'EURO_PALLET' = 'EURO_PALLET',
    'GLASS_JAR' = 'GLASS_JAR',
    'GRAM' = 'GRAM',
    'KILOGRAM' = 'KILOGRAM',
    'LITERS' = 'LITERS',
    'METRES' = 'METRES',
    'MILLILITERS' = 'MILLILITERS',
    'NET' = 'NET',
    'PACKAGE' = 'PACKAGE',
    'PACKAGING' = 'PACKAGING',
    'PIECE' = 'PIECE',
    'PLASTIC_BAG' = 'PLASTIC_BAG',
    'PLASTIC_BOWL' = 'PLASTIC_BOWL',
    'PLASTIC_CUP' = 'PLASTIC_CUP',
    'SACK' = 'SACK',
    'TIN' = 'TIN',
    'TUBE' = 'TUBE'
}
