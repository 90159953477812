import { Epic } from 'redux-observable';
import { concatMap, switchMap } from 'rxjs';

import { authLogoutAction, authSessionExpiredAction } from '@dotgoclub/auth-components';
import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { resetAdminApolloClient } from '../../../general/admin-apollo-client';
import { displayToast } from '../../../general/helper/display-toast';

export const authSessionExpiredEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(authSessionExpiredAction),
        concatMap(() => resetAdminApolloClient()),
        switchMap(() => {
            displayToast({ type: 'warning', header: 'Your session is over' });

            return [authLogoutAction()];
        })
    );
