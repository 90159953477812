import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, mergeMap } from 'rxjs';

import { getApolloErrorCode } from '@dotgoclub/apollo-components';
import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { requestOtpMutation$ } from '../../queries/request-otp.mutation';
import { authOtpAction, authOtpFailedAction, authOtpSuccessAction } from '../auth.actions';

import type { AuthEventOptionsInterface } from '../../interface/auth-event-options.interface';
import type { ApolloClient } from '@apollo/client';
import type { Action } from '@reduxjs/toolkit';
import type { Observable } from 'rxjs';

export const createAuthRequestOtpEpic =
    <T>(
        apolloClient: ApolloClient<T>,
        onSuccess?: AuthEventOptionsInterface['onOTPSuccess'],
        onError?: AuthEventOptionsInterface['onOTPFailed']
    ) =>
    (actions$: Observable<Action>): Observable<Action> =>
        actions$.pipe(
            ofType(authOtpAction),
            toPayload(),
            exhaustMap(phoneNumber =>
                requestOtpMutation$(apolloClient, { phoneNumber }).pipe(
                    mergeMap(nextOtpResendDate => [authOtpSuccessAction(nextOtpResendDate), ...(onSuccess?.() ?? [])]),
                    catchError((err: unknown) => {
                        const errText = getApolloErrorCode(err) ?? getErrorMessage(err, 'Failed OTP request');

                        return [authOtpFailedAction(errText), ...(onError?.(errText) ?? [])];
                    })
                )
            )
        );
