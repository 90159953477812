import { addDays, isToday } from 'date-fns';

export const HOURS_IN_MS = 60 * 60 * 1000;

const monthsShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const getMonthName = (date: Date): string => monthsShortNames[date.getMonth()];

export const unshiftZero = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

export const formatDate = (date: Date, isMonthFirst = false): string =>
    isMonthFirst ? `${getMonthName(date)} ${date.getDate()}` : `${date.getDate()} ${getMonthName(date)}`;

export const getFormattedTime = (date: Date, secondsShown = false): string =>
    `${unshiftZero(date.getHours())}:${unshiftZero(date.getMinutes())}${secondsShown ? `:${unshiftZero(date.getSeconds())}` : ''}`;

export const formatDateTime = (date: Date): string => `${formatDate(date)}, ${getFormattedTime(date)}`;

export const getTodayDate = (): Date => new Date();
export const getTomorrowDate = (): Date => addDays(new Date(), 1);
export const getDayAfterTomorrowDate = (): Date => addDays(new Date(), 2);

export const getDeliveryTimeSlot = (deliveryTimeFrom: Date, deliveryTimeTill: Date, dateAlwaysShown = false): string => {
    if (dateAlwaysShown || !isToday(deliveryTimeFrom)) {
        const dateString = formatDate(deliveryTimeFrom).toUpperCase();

        return `${dateString}, ${getFormattedTime(deliveryTimeFrom)} - ${getFormattedTime(deliveryTimeTill)}`;
    }

    return `${getFormattedTime(deliveryTimeFrom)} - ${getFormattedTime(deliveryTimeTill)}`;
};

export const dateFromSeconds = (seconds: number): Date => {
    // UNIX epoch starting point
    // eslint-disable-next-line no-magic-numbers
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);

    return date;
};

export const getDateText = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);

    return `${day}.${month}.${year}`;
};
