import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';

export const REFRESH_TOKEN_MUTATION = `
    mutation refreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            accessToken
            refreshToken
        }
    }
`;

export interface RefreshTokenMutation {
    refreshToken: JwtTokensResponseInterface;
}
