import { useIsFocused } from '@react-navigation/core';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Pagination, Table } from 'rsuite';

import { emptyPaginationArgs } from '@dotgoclub/client-contracts';

import { AdminPageContent, AdminRoutesEnum, EditCell, adminStep, useAdminNavigation } from '../../../general';
import { ExpandCell } from '../../../general/components/expand-cell/expand-cell';
import { ExpandedTable } from '../../../general/components/expanded-table/expanded-table';
import { useExpandableTable } from '../../../general/hooks/use-expandable-table.hook';
import { pricingListLoadAction } from '../../state/pricing.action';
import { pricingSelector } from '../../state/pricing.selector';

import type { PricingRowInterface } from '../../interfaces/pricing-row.interface';
import type { FC } from 'react';
import type { RowDataType } from 'rsuite-table';

const { Column, HeaderCell, Cell } = Table;

// eslint-disable-next-line max-lines-per-function
export const AdminPricingListPage: FC = () => {
    const dispatch = useDispatch();
    const { list, isLoading, pagination } = useSelector(pricingSelector);

    const isRouteUpdated = useIsFocused();
    const navigation = useAdminNavigation();

    const [handleExpand, expandedRowKeys] = useExpandableTable<PricingRowInterface>();
    const tableData = useMemo<PricingRowInterface[]>(
        () =>
            list.map(item => ({
                city: item.city.name,
                country: item.country.name,
                minimalPrice: item.minimalPrice,
                currency: item.currency.name,
                distance: ` S ${item.distance.Short}, M ${item.distance.Medium}, L ${item.distance.Long}, XL ${item.distance.XLong}`,
                size: ` S ${item.size.Small}, M ${item.size.Medium}, L ${item.size.Large}`,
                time: ` Night ${item.time.Night}, Early Morning ${item.time.EarlyMorning}, Morning ${item.time.Morning}, MidDay ${item.time.MidDay}  , Lunch ${item.time.Lunch},  Evening ${item.time.Evening}`,
                value: `Cheap ${item.value.Cheap}, Medium ${item.value.Medium}, Expensive ${item.value.Expensive}, Very Expensisve ${item.value.VeryExpensive}, Luxury ${item.value.Luxury}`,
                id: item.id
            })),
        [list, isRouteUpdated]
    );

    useEffect(() => void dispatch(pricingListLoadAction({ pagination: emptyPaginationArgs })), [isRouteUpdated]);

    const handleChangePage = (page: number): void =>
        void dispatch(pricingListLoadAction({ pagination: { page, limit: pagination.itemsPerPage } }));
    const handleChangeLength = (limit: number): void =>
        void dispatch(pricingListLoadAction({ pagination: { page: pagination.currentPage, limit } }));
    const handleIDPress = (id: string): void => void navigation.navigate(AdminRoutesEnum.PricingForm, { id });
    const handleCreatePricing = (): void => void navigation.navigate(AdminRoutesEnum.CreatePricingForm);
    const renderExpandedRow = (row?: RowDataType): JSX.Element => (
        <ExpandedTable
            columnsToRender={[
                { label: 'Distance', dataKey: 'distance' },
                { label: 'Size', dataKey: 'size' },
                { label: 'Time', dataKey: 'time' }
            ]}
            rowData={row}
        />
    );

    return (
        <AdminPageContent title="Pricing">
            <ButtonToolbar>
                <Button color="green" onClick={handleCreatePricing}>
                    Create pricing
                </Button>
            </ButtonToolbar>
            <Table
                data={tableData}
                expandedRowKeys={expandedRowKeys}
                height={adminStep(100)}
                loading={isLoading}
                renderRowExpanded={renderExpandedRow}
                rowExpandedHeight={adminStep(30)}
                rowKey="id"
                wordWrap
            >
                <Column flexGrow={1}>
                    <HeaderCell>#</HeaderCell>
                    <ExpandCell expandedRowKeys={expandedRowKeys} onChange={handleExpand} />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Country</HeaderCell>
                    <Cell dataKey="country" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>City</HeaderCell>
                    <Cell dataKey="city" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Minimal price</HeaderCell>
                    <Cell dataKey="minimalPrice" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Currency</HeaderCell>
                    <Cell dataKey="currency" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Edit</HeaderCell>
                    <Cell>{(rowData: PricingRowInterface) => <EditCell handleClick={handleIDPress} rowData={rowData} />}</Cell>
                </Column>
            </Table>
            <Pagination
                activePage={pagination.currentPage}
                limit={pagination.itemsPerPage}
                onChangeLimit={handleChangeLength}
                onChangePage={handleChangePage}
                total={pagination.totalItems ?? 0}
            />
        </AdminPageContent>
    );
};
