import { emptyBaseEntity } from '../../../common';
import { DeliverySizeEnum } from '../../enum';

import type { PickWithBaseEntity } from '../../../common';
import type { SizePricingEntityInterface } from '../../entities';

export interface SizePricingDtoInterface extends PickWithBaseEntity<SizePricingEntityInterface, DeliverySizeEnum> {}

export const emptySizePricingDto: SizePricingDtoInterface = {
    ...emptyBaseEntity,
    [DeliverySizeEnum.Small]: 0,
    [DeliverySizeEnum.Medium]: 0,
    [DeliverySizeEnum.Large]: 0
};
