import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { storeFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const updateStoreByIdMutation$ = gqlMutationOperation<CatalogAdminResolverInterface, 'updateStoreById'>(
    adminApolloClient,
    'updateStoreById',
    gql`
        mutation updateStoreById($storeId: ID!, $input: UpdateStoreInput!) {
            updateStoreById(storeId: $storeId, input: $input) {
                ...StoreFragment
                zip
                type
                address
                coordinates {
                    latitude
                    longitude
                }
            }
        }
        ${storeFragment}
    `
);
