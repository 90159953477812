import { emptyBaseEntity } from '../../../common';

import type { PickWithBaseEntity } from '../../../common';
import type { UserAvatarDtoInterface } from '../../../user-avatars';
import type { UserEntityInterface } from '../../entities';

type FIELDS =
    | 'birthdate'
    | 'email'
    | 'isAdmin'
    | 'isEmailVerified'
    | 'isOnBoardingComplete'
    | 'name'
    | 'phoneNumber'
    | 'quickbloxId'
    | 'quickbloxPassword';

export interface UserDtoInterface extends PickWithBaseEntity<UserEntityInterface, FIELDS> {
    avatar?: UserAvatarDtoInterface | null;
}

export const emptyUserDto: UserDtoInterface = {
    ...emptyBaseEntity,
    isAdmin: false,
    isEmailVerified: false,
    isOnBoardingComplete: false,
    phoneNumber: ''
};
