import { cs } from '@rnw-community/shared';
import React from 'react';
import { Text, View } from 'react-native';

import { H1Styles } from './h1.styles';

import type { FC } from 'react';

interface Props {
    isCentered?: boolean;
    title?: string;
}

export const H1: FC<Props> = ({ title = '', children, isCentered = false }) => (
    <View style={[H1Styles.wrapper, cs(isCentered, H1Styles.textCentered)]}>
        <Text style={H1Styles.text}>
            {title}
            {children}
        </Text>
    </View>
);
