import { emptyCoordinates } from '../../../geolocation';
import { DeliverySizeEnum } from '../../../pricing';

import type { OrderEntityInterface } from '../../entities';
import type { OrderCreateGenericInputInterface } from './order-create-generic-input.interface';

export interface OrderCreateInputInterface
    extends OrderCreateGenericInputInterface,
        // TODO: Do we need deliverySize and tipsCost?
        Pick<OrderEntityInterface, 'deliverySize' | 'description' | 'purchaseCost' | 'tipsCost'> {
    imageIds: string[];
}

export const emptyOrderCreateInput: OrderCreateInputInterface = {
    comment: '',
    contactPersonName: '',
    contactPersonNumber: '',
    deliverFromPlaceId: '',
    deliveryCost: 0,
    fromCoordinates: emptyCoordinates,
    imageIds: [],
    timeTill: new Date(),
    timeFrom: new Date(),
    toCoordinates: emptyCoordinates,
    deliverySize: DeliverySizeEnum.Small,
    description: '',
    purchaseCost: 0,
    tipsCost: 0
};

export const getOrderCreateInputTotal = (input: OrderCreateInputInterface): number => input.deliveryCost + input.purchaseCost;
