import { createSelector } from '@reduxjs/toolkit';
import { isNotEmptyString } from '@rnw-community/shared';

import { AuthRootState } from './auth.slice';

import type { AuthState } from './auth.state';

export const authSelector = (state: AuthRootState): AuthState => state.auth;

export const authPhoneEnterSelector = createSelector(authSelector, state => state.phoneEnter);
export const authOtpScreenSelector = createSelector(authSelector, state => ({
    ...state.otpEnter,
    phoneNumber: state.phoneNumber,
    otpRequestNextDate: state.otpRequestNextDate
}));
export const authIsAuthorizedSelector = createSelector(authSelector, state => isNotEmptyString(state.credentials.accessToken));
export const authIsSessionExpiredSelector = createSelector(authSelector, state => state.isSessionExpired);
export const authAreOnBoardingScreensRequiredSelector = createSelector(authSelector, state => state.areOnBoardingScreensRequired);
