import { Formik } from 'formik';
import React from 'react';
import { Button, ButtonToolbar, Form } from 'rsuite';
import * as Yup from 'yup';

import { InputFormGroup, useFormikNavUpdateHook } from '../../../../general';
import { FormControlWithLabel } from '../../../../general/components/form-control-with-label';

import type { UpdateStoreInputInterface } from '@dotgoclub/client-contracts';
import type { OnEventFn } from '@rnw-community/shared';

interface Props {
    initialValues: UpdateStoreInputInterface;
    onCancel: OnEventFn;
    onSubmit: OnEventFn<UpdateStoreInputInterface>;
}

const validationSchema = Yup.object().shape({
    externalId: Yup.string().strict(true).trim().min(1, 'Too short').max(250, 'Too long').required('required'),
    coordinates: Yup.object().shape({
        latitude: Yup.number().required('required'),
        longitude: Yup.number().required('required')
    }),
    mapPlaceId: Yup.string().strict(true).trim().min(2, 'Too short').max(250, 'Too long').required('required'),
    name: Yup.string().strict(true).trim().min(2, 'Too short').max(250, 'Too long').required('required'),
    address: Yup.string().strict(true).trim().max(1000, 'Too long'),
    cityId: Yup.string().strict(true).trim().min(2, 'Too short').max(250, 'Too long').required('required')
});

export const StoreForm = ({ initialValues, onSubmit, onCancel }: Props): JSX.Element => {
    const formikRef = useFormikNavUpdateHook(initialValues);

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            innerRef={formikRef}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ submitForm, handleChange, errors, values, isValid, dirty }) => (
                <Form>
                    <FormControlWithLabel
                        error={errors.externalId}
                        label="External id"
                        name="externalId"
                        onChange={handleChange('externalId')}
                        value={values.externalId}
                    />
                    <FormControlWithLabel
                        error={errors.mapPlaceId}
                        label="Map place id"
                        name="mapPlaceId"
                        onChange={handleChange('mapPlaceId')}
                        value={values.mapPlaceId}
                    />
                    <FormControlWithLabel
                        error={errors.name}
                        label="Name"
                        name="name"
                        onChange={handleChange('name')}
                        value={values.name}
                    />
                    <InputFormGroup fieldLabel="coordinates.latitude" label="Latitude" />
                    <InputFormGroup fieldLabel="coordinates.longitude" label="Longitude" />
                    <FormControlWithLabel
                        error={errors.address}
                        label="Address"
                        name="address"
                        onChange={handleChange('address')}
                        value={values.address}
                    />
                    <FormControlWithLabel
                        error={errors.zip}
                        label="Zip"
                        name="zip"
                        onChange={handleChange('zip')}
                        value={values.zip}
                    />
                    <FormControlWithLabel
                        error={errors.type}
                        label="Type"
                        name="type"
                        onChange={handleChange('type')}
                        value={values.type}
                    />
                    <FormControlWithLabel
                        error={errors.cityId}
                        label="City id"
                        name="cityId"
                        onChange={handleChange('cityId')}
                        value={values.cityId}
                    />
                    <ButtonToolbar>
                        <Button appearance="primary" disabled={!isValid || !dirty} onClick={submitForm}>
                            Save
                        </Button>
                        <Button appearance="subtle" onClick={onCancel}>
                            Cancel
                        </Button>
                    </ButtonToolbar>
                </Form>
            )}
        </Formik>
    );
};
