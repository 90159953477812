import { emptyBaseEntity } from '../../../common';
import { WeightUnitEnum } from '../../enum';

import { emptyProductDto } from './product-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CatalogImageDtoInterface } from './catalog-image-dto.interface';
import type { ProductDtoInterface } from './product-dto.interface';
import type { ProductInventoryDtoInterface } from './product-inventory-dto.interface';
import type { ProductMetadataDtoInterface } from './product-metadata-dto.interface';
import type { ProductOptionDtoInterface } from './product-option-dto.interface';

export interface ProductVariantDtoInterface extends BaseEntityInterface {
    containerUnit: WeightUnitEnum;
    externalId: string;
    images: CatalogImageDtoInterface[];
    isSoldByWeight: boolean;
    metadata: ProductMetadataDtoInterface[];
    options: ProductOptionDtoInterface[];
    product: ProductDtoInterface;
    productInventory: ProductInventoryDtoInterface[];
    productWeightUnit: number;
    singleProductWeight: number;
    sku: string;
    unitLabel: WeightUnitEnum;
    weight: number;
    weightUnit: WeightUnitEnum;
}

export const emptyProductVariantDto: ProductVariantDtoInterface = {
    ...emptyBaseEntity,
    containerUnit: WeightUnitEnum.BIN,
    externalId: '',
    images: [],
    isSoldByWeight: false,
    metadata: [],
    options: [],
    product: emptyProductDto,
    productInventory: [],
    productWeightUnit: 0,
    singleProductWeight: 0,
    sku: '',
    unitLabel: WeightUnitEnum.BIN,
    weight: 0,
    weightUnit: WeightUnitEnum.BIN
};
