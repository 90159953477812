import { Dimensions, StyleSheet } from 'react-native';

import { adminStep } from '../../styles/admin-step';

// TODO: Use useWindowDimensions hook
export const AdminAuthorizedNavigatorStyles = StyleSheet.create({
    drawerContent: {
        height: Dimensions.get('window').height
    },
    drawerMenu: {
        borderRightWidth: 0,
        height: Dimensions.get('window').height,
        width: adminStep(30)
    }
});
