export enum ErrorCodesEnum {
    AVATAR_BY_USER_IDS_FAILED = 'AVATAR_BY_USER_IDS_FAILED',
    AVATAR_DELETE_FAILED = 'AVATAR_DELETE_FAILED',
    AVATAR_GET_UPLOAD_URL_FAILED = 'AVATAR_GET_UPLOAD_URL_FAILED',
    AVATAR_MARK_AS_UPLOADED_FAILED = 'AVATAR_MARK_AS_UPLOADED_FAILED',

    BAD_REQUEST = 'BAD_REQUEST',

    CANNOT_CREATE_ENTITY_ERROR = 'CANNOT_CREATE_ENTITY_ERROR',
    CANNOT_UPDATE_ENTITY_ERROR = 'CANNOT_UPDATE_ENTITY_ERROR',

    CATALOG_ALL_SUPPLIERS_FAILED = 'CATALOG_ALL_SUPPLIERS_FAILED',
    CATALOG_CATEGORIES_BY_STORE_ID_FAILED = 'CATALOG_CATEGORIES_BY_STORE_ID_FAILED',
    CATALOG_CATEGORIES_BY_SUPPLIER_ID_FAILED = 'CATALOG_CATEGORIES_BY_SUPPLIER_ID_FAILED',
    CATALOG_CATEGORIES_WITH_SUB_CATEGORIES_BY_STORE_ID_FAILED = 'CATALOG_CATEGORIES_WITH_SUB_CATEGORIES_BY_STORE_ID_FAILED',
    CATALOG_CATEGORY_BY_ID_FAILED = 'CATALOG_CATEGORY_BY_ID_FAILED',
    CATALOG_CATEGORY_UPDATE_FAILED = 'CATALOG_CATEGORY_UPDATE_FAILED',
    CATALOG_NEAREST_STORE_FAILED = 'CATALOG_NEAREST_STORE_FAILED',
    CATALOG_PRODUCTS_BY_IDS_BY_STORE_FAILED = 'CATALOG_PRODUCTS_BY_IDS_BY_STORE_FAILED',
    CATALOG_PRODUCT_BY_ID_BY_STORE_FAILED = 'CATALOG_PRODUCT_BY_ID_BY_STORE_FAILED',
    CATALOG_SEARCH_PRODUCTS_IN_STORE_FAILED = 'CATALOG_SEARCH_PRODUCTS_IN_STORE_FAILED',
    CATALOG_STORES_BY_IDS_FAILED = 'CATALOG_STORES_BY_IDS_FAILED',
    CATALOG_STORES_BY_SUPPLIER_ID_FAILED = 'CATALOG_STORES_BY_SUPPLIER_ID_FAILED',
    CATALOG_STORES_CREATE_FAILED = 'CATALOG_STORES_CREATE_FAILED',
    CATALOG_STORES_IN_RANGE_FAILED = 'CATALOG_STORES_IN_RANGE_FAILED',
    CATALOG_STORES_UPDATE_FAILED = 'CATALOG_STORES_UPDATE_FAILED',
    CATALOG_STORE_BY_ID_FAILED = 'CATALOG_STORE_BY_ID_FAILED',
    CATALOG_STORE_BY_PLACE_ID_FAILED = 'CATALOG_STORE_BY_PLACE_ID_FAILED',
    CATALOG_SUBCATEGORIES_WITH_PRODUCTS_BY_CATEGORY_ID_FAILED = 'CATALOG_SUBCATEGORIES_WITH_PRODUCTS_BY_CATEGORY_ID_FAILED',
    CATALOG_SUPPLIERS_BY_ID_FAILED = 'CATALOG_SUPPLIERS_BY_ID_FAILED',
    CATALOG_SUPPLIER_CREATE_FAILED = 'CATALOG_SUPPLIER_CREATE_FAILED',
    CATALOG_SUPPLIER_UPDATE_FAILED = 'CATALOG_SUPPLIER_UPDATE_FAILED',

    CHAT_ACTIVE_DIALOGS_BY_USER_LOADING_FAILED = 'CHAT_ACTIVE_DIALOGS_BY_USER_LOADING_FAILED',
    CHAT_DIALOG_BY_ID_LOADING_FAILED = 'CHAT_DIALOG_BY_ID_LOADING_FAILED',
    CHAT_DIALOG_BY_ORDER_LOADING_FAILED = 'CHAT_DIALOG_BY_ORDER_LOADING_FAILED',
    CHAT_IMAGE_LOADING_FAILED = 'CHAT_IMAGE_LOADING_FAILED',
    CHAT_LOGIN_FAILED = 'CHAT_LOGIN_FAILED',
    CHAT_MESSAGES_LOADING_FAILED = 'CHAT_MESSAGES_LOADING_FAILED',
    CHAT_RECENT_DIALOGS_BY_USER_LOADING_FAILED = 'CHAT_RECENT_DIALOGS_BY_USER_LOADING_FAILED',
    CHAT_SEND_MESSAGE_CHAT_NOT_CREATED = 'CHAT_SEND_MESSAGE_CHAT_NOT_CREATED',
    CHAT_SEND_MESSAGE_FAILED = 'CHAT_SEND_MESSAGE_FAILED',
    CHAT_SEND_MESSAGE_ORDER_COMPLETED = 'CHAT_SEND_MESSAGE_ORDER_COMPLETED',
    CHAT_SEND_MESSAGE_VALIDATION_FAILED = 'CHAT_SEND_MESSAGE_VALIDATION_FAILED',

    DELIVERY_FROM_FAILED = 'DELIVERY_FROM_FAILED',
    DELIVERY_TO_FAILED = 'DELIVERY_TO_FAILED',

    EMAIL_UPDATE_REQUEST_FAILED = 'EMAIL_UPDATE_REQUEST_FAILED',
    EMAIL_VALIDATION_REQUEST_FAILED = 'EMAIL_VALIDATION_REQUEST_FAILED',
    EMAIL_VERIFICATION_REQUEST_FAILED = 'EMAIL_VERIFICATION_REQUEST_FAILED',
    ENTITY_NOT_FOUND_ERROR = 'ENTITY_NOT_FOUND_ERROR',

    GEOLOCATION_ALL_COUNTRIES_AND_CITIES_FAILED = 'REGION_ALL_COUNTRIES_AND_CITIES',
    GEOLOCATION_ALL_CURRENCIES_FAILED = 'GEOLOCATION_ALL_CURRENCIES_FAILED',
    GEOLOCATION_AUTOCOMPLETE_FAILED = 'GEOLOCATION_AUTOCOMPLETE_FAILED',
    GEOLOCATION_CITIES_AND_COUNTRY_BY_COORDINATES_FAILED = 'GEOLOCATION_CITIES_AND_COUNTRY_BY_COORDINATES_FAILED',
    GEOLOCATION_CITIES_BY_IDS_FAILED = 'GEOLOCATION_CITIES_BY_IDS_FAILED',
    GEOLOCATION_COORDINATES_IN_POLYGON_FAILED = 'GEOLOCATION_COORDINATES_IN_POLYGON_FAILED',
    GEOLOCATION_COUNTRIES_BY_IDS_FAILED = 'GEOLOCATION_COUNTRIES_BY_IDS_FAILED',
    GEOLOCATION_CURRENCIES_BY_IDS_FAILED = 'GEOLOCATION_CURRENCIES_BY_IDS_FAILED',
    GEOLOCATION_CURRENCY_BY_CITY_ID_FAILED = 'GEOLOCATION_CURRENCY_BY_CITY_ID_FAILED',
    GEOLOCATION_CURRENCY_BY_ID_FAILED = 'GEOLOCATION_CURRENCY_BY_ID_FAILED',
    GEOLOCATION_FIND_PLACE_FAILED = 'GEOLOCATION_FIND_PLACE_FAILED',
    GEOLOCATION_GEOCODER_FAILED = 'GEOLOCATION_GEOCODER_FAILED',
    GEOLOCATION_GET_DIRECTIONS_FAILED = 'GEOLOCATION_GET_DIRECTIONS_FAILED',
    GEOLOCATION_TEXTSEARCH_FAILED = 'GEOLOCATION_TEXTSEARCH_FAILED',

    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    INVALID_DELIVERY_COST = 'INVALID_DELIVERY_COST',
    INVALID_OTP = 'INVALID_OTP',
    INVALID_TOKEN = 'INVALID_TOKEN',
    LOCK_ERROR = 'LOCK_ERROR',

    MARK_FILE_AS_UPLOADED_FAILED = 'MARK_FILE_AS_UPLOADED_FAILED',

    NOTIFICATION_EMAIL_FAILED = 'NOTIFICATION_EMAIL_FAILED',
    NOTIFICATION_PUSH_FAILED = 'NOTIFICATION_PUSH_FAILED',
    NOTIFICATION_SMS_FAILED = 'NOTIFICATION_SMS_FAILED',

    ORDER_ACCEPTED_BY_COURIER_LOADING_FAILED = 'ORDER_ACCEPTED_BY_COURIER_LOADING_FAILED',
    ORDER_ACTIVE_FOR_CLIENT_LOADING_FAILED = 'ORDER_ACTIVE_FOR_CLIENT_LOADING_FAILED',
    ORDER_AVAILABLE_FOR_COURIER_LOADING_FAILED = 'ORDER_AVAILABLE_FOR_COURIER_LOADING_FAILED',
    ORDER_CANCEL_OUTDATED_FAILED = 'ORDER_CANCEL_OUTDATED_FAILED',
    ORDER_CANNOT_ACCEPT = 'ORDER_CANNOT_ACCEPT',
    ORDER_CANNOT_ACCEPT_ACCEPTED = 'ORDER_CANNOT_ACCEPT_ACCEPTED',
    ORDER_CANNOT_ACCEPT_ACCEPTED_AND_CANCELLED = 'ORDER_CANNOT_ACCEPT_ACCEPTED_AND_CANCELLED',
    ORDER_CANNOT_ACCEPT_CANCELED = 'ORDER_CANNOT_ACCEPT_CANCELED',
    ORDER_CANNOT_ACCEPT_INVALID_INSURANCE_AMOUNT = 'ORDER_CANNOT_ACCEPT_INVALID_INSURANCE_AMOUNT',
    ORDER_CANNOT_ACCEPT_OWNER = 'ORDER_CANNOT_ACCEPT_OWNER',
    ORDER_CANNOT_ACCEPT_PAYMENT_DECLINED = 'ORDER_CANNOT_ACCEPT_PAYMENT_DECLINED',
    ORDER_CANNOT_AUTO_CANCEL = 'ORDER_CANNOT_AUTO_CANCEL',
    ORDER_CANNOT_CANCEL = 'ORDER_CANNOT_CANCEL',
    ORDER_CANNOT_CANCEL_ALREADY_ACCEPTED = 'ORDER_CANNOT_CANCEL_ALREADY_ACCEPTED',
    ORDER_CANNOT_INCREASE_DELIVERY_COST = 'ORDER_CANNOT_INCREASE_DELIVERY_COST',
    ORDER_CANNOT_INCREASE_PURCHASE_COST = 'ORDER_CANNOT_INCREASE_PURCHASE_COST',
    ORDER_CANNOT_MARK_AS_DELIVERED = 'ORDER_CANNOT_MARK_AS_DELIVERED',
    ORDER_CANNOT_MARK_AS_PAID = 'ORDER_CANNOT_MARK_AS_PAID',
    ORDER_CANNOT_RATE_CLIENT = 'ORDER_CANNOT_RATE_CLIENT',
    ORDER_CANNOT_RATE_COURIER = 'ORDER_CANNOT_RATE_COURIER',
    ORDER_CANNOT_RATE_COURIER_ALREADY_RATED = 'ORDER_CANNOT_RATE_COURIER_ALREADY_RATED',
    ORDER_CANNOT_RATE_COURIER_NOT_DELIVERED = 'ORDER_CANNOT_RATE_COURIER_NOT_DELIVERED',
    ORDER_CANNOT_RATE_COURIER_OTHER_CREATOR = 'ORDER_CANNOT_RATE_COURIER_OTHER_CREATOR',
    ORDER_CANNOT_RATE_COURIER_PAYMENT_DECLINED = 'ORDER_CANNOT_RATE_COURIER_PAYMENT_DECLINED',
    ORDER_CANNOT_SET_COURIER_AS_ARRIVED = 'ORDER_CANNOT_SET_COURIER_AS_ARRIVED',
    ORDER_CHANGES_LOADING_BY_ORDER_IDS_FAILED = 'ORDER_CHANGES_LOADING_BY_ORDER_IDS_FAILED',
    ORDER_CHECK_DELIVERY_DATE_FAILED = 'ORDER_CHECK_DELIVERY_DATE_FAILED',
    ORDER_CHECK_PURCHASE_BALANCE_FAILED = 'ORDER_CHECK_PURCHASE_BALANCE_FAILED',
    ORDER_CONFIRM_DELIVERY_FAILED = 'ORDER_CONFIRM_DELIVERY_FAILED',
    ORDER_COURIER_CANNOT_CANCEL = 'ORDER_COURIER_CANNOT_CANCEL',
    ORDER_CREATE_FAILED = 'ORDER_CREATE_FAILED',
    ORDER_DELIVERY_FOR_USER_LOADING_FAILED = 'ORDER_DELIVERY_FOR_USER_LOADING_FAILED',
    ORDER_HISTORY_FOR_USER_LOADING_FAILED = 'ORDER_HISTORY_FOR_USER_LOADING_FAILED',
    ORDER_INCREASE_DELIVERY_COST_ORDER_ACCEPTED = 'ORDER_INCREASE_DELIVERY_COST_ORDER_ACCEPTED',
    ORDER_INCREASE_DELIVERY_COST_PAYMENT_DECLINED = 'ORDER_INCREASE_DELIVERY_COST_PAYMENT_DECLINED',
    ORDER_INCREASE_PURCHASE_COST_ORDER_PAID = 'ORDER_INCREASE_PURCHASE_COST_ORDER_PAID',
    ORDER_INCREASE_PURCHASE_COST_PAYMENT_DECLINED = 'ORDER_INCREASE_PURCHASE_COST_PAYMENT_DECLINED',
    ORDER_ITEMS_LOADING_BY_ORDER_IDS_FAILED = 'ORDER_ITEMS_LOADING_BY_ORDER_IDS_FAILED',
    ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
    ORDER_PRICING_FAILED = 'ORDER_PRICING_FAILED',
    ORDER_REQUIRED_ACTIONS_LOADING_FAILED = 'ORDER_REQUIRED_ACTIONS_LOADING_FAILED',
    ORDER_SET_COURIER_NOT_FOUND_FAILED = 'ORDER_SET_COURIER_NOT_FOUND_FAILED',

    PAYMENT_APPLEPAY_HOLD_FAILED = 'PAYMENT_APPLEPAY_HOLD_FAILED',
    PAYMENT_APPLEPAY_PAYMENT_FAILED = 'PAYMENT_APPLEPAY_PAYMENT_FAILED',
    PAYMENT_CANCEL_FAILED = 'PAYMENT_CANCEL_FAILED',
    PAYMENT_CARD_HOLD_FAILED = 'PAYMENT_CARD_HOLD_FAILED',
    PAYMENT_CARD_PAYMENT_FAILED = 'PAYMENT_CARD_PAYMENT_FAILED',
    PAYMENT_GOOGLEPAY_HOLD_FAILED = 'PAYMENT_GOOGLEPAY_HOLD_FAILED',
    PAYMENT_GOOGLEPAY_PAYMENT_FAILED = 'PAYMENT_GOOGLEPAY_PAYMENT_FAILED',
    PAYMENT_INCREMENT_FAILED = 'PAYMENT_INCREMENT_FAILED',
    PAYMENT_TOKEN_ACTIVATE_FAILED = 'PAYMENT_TOKEN_ACTIVATE_FAILED',
    PAYMENT_TOKEN_ADD_CARD_FAILED = 'PAYMENT_TOKEN_ADD_CARD_FAILED',
    PAYMENT_TOKEN_ADD_WALLET_FAILED = 'PAYMENT_TOKEN_ADD_WALLET_FAILED',
    PAYMENT_TOKEN_LIST_FAILED = 'PAYMENT_TOKEN_LIST_FAILED',
    PAYMENT_TOKEN_NOT_FOUND = 'PAYMENT_TOKEN_NOT_FOUND',
    PAYMENT_TOKEN_REMOVE_FAILED = 'PAYMENT_TOKEN_REMOVE_FAILED',
    PAYMENT_TOKEN_REMOVE_GATE_FAILED = 'PAYMENT_TOKEN_REMOVE_GATE_FAILED',
    PAYMENT_TOKEN_REMOVE_USED_FAILED = 'PAYMENT_TOKEN_REMOVE_USED_FAILED',
    PAYMENT_TOKEN_REMOVE_WALLET_FAILED = 'PAYMENT_TOKEN_REMOVE_WALLET_FAILED',
    PAYMENT_TRANSACTION_CREATION_FAILED = 'PAYMENT_TRANSACTION_CREATION_FAILED',
    PAYMENT_TRANSACTION_NOT_FOUND = 'PAYMENT_TRANSACTION_NOT_FOUND',
    PAYMENT_TRANSACTION_REGISTRATION_FAILED = 'PAYMENT_TRANSACTION_REGISTRATION_FAILED',
    PAYMENT_WITHDRAW_FAILED = 'PAYMENT_WITHDRAW_FAILED',

    POLYGON_COUNTRIES_WITH_CITIES_FAILED = 'POLYGON_COUNTRIES_WITH_CITIES_FAILED',
    POLYGON_CREATE_FAILED = 'POLYGON_CREATE_FAILED',
    POLYGON_DELETE_FAILED = 'POLYGON_DELETE_FAILED',
    POLYGON_GET_ALL_FAILED = 'POLYGON_GET_ALL_FAILED',
    POLYGON_GET_BY_2_COORDINATES_FAILED = 'POLYGON_GET_BY_2_COORDINATES_FAILED',
    POLYGON_GET_BY_COORDINATES_FAILED = 'POLYGON_GET_BY_COORDINATES_FAILED',
    POLYGON_UPDATE_FAILED = 'POLYGON_UPDATE_FAILED',

    STORE_NOT_FOUND_IN_RANGE = 'STORE_NOT_FOUND_IN_RANGE',
    SUPPLIERS_NOT_FOUND = 'SUPPLIERS_NOT_FOUND',
    UNAUTHENTICATED_ERROR = 'UNAUTHENTICATED_ERROR',

    USER_BY_IDS_FAILED = 'USER_BY_IDS_FAILED',
    USER_BY_ID_FAILED = 'USER_BY_ID_FAILED',
    USER_BY_PHONE_FAILED = 'USER_BY_PHONE_FAILED',
    USER_COURIER_ACTIVE_DELIVERIES_FAILED = 'USER_COURIER_ACTIVE_DELIVERIES_FAILED',
    USER_DEACTIVATE_PROFILE_FAILED = 'USER_DEACTIVATE_PROFILE_FAILED',
    USER_DELETE_FAILED = 'USER_DELETE_FAILED',
    USER_DEVICE_REMOVE_TOKEN_FAILED = 'USER_DEVICE_REMOVE_TOKEN_FAILED',
    USER_DEVICE_TOKEN_FAILED = 'USER_DEVICE_TOKEN_FAILED',
    USER_LIST_FAILED = 'USER_LIST_FAILED',
    USER_PROFILE_DEACTIVATED = 'USER_PROFILE_DEACTIVATED',
    USER_UPDATE_EMAIL_FAILED = 'USER_UPDATE_EMAIL_FAILED',
    USER_UPDATE_FAILED = 'USER_UPDATE_FAILED',

    VALIDATION_ERROR = 'VALIDATION_ERROR'
}
