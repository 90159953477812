import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import type { OrderAdminResolverInterface } from '@dotgoclub/client-contracts';

export const cancelMultipleOrdersMutation$ = gqlMutationOperation<OrderAdminResolverInterface, 'cancelMultipleOrders'>(
    adminApolloClient,
    'cancelMultipleOrders',
    gql`
        mutation cancelMultipleOrders($orderIds: [ID!]!) {
            cancelMultipleOrders(orderIds: $orderIds)
        }
    `
);
