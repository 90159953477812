import { emptyBaseEntity } from '../../../common';
import { emptyUserDto } from '../../../users';

import type { UserDtoInterface } from '../../../users';

export interface ChatDialogDtoInterface {
    id: string;
    isActive: boolean;
    lastMessage: string | null;
    lastMessageDate: string | null;
    name: string;
    opposingUser: UserDtoInterface;
    opposingUserId: string;
    orderId: string;
    unreadMessagesCount: number;
}

export const emptyChatDialogDto: ChatDialogDtoInterface = {
    ...emptyBaseEntity,
    isActive: false,
    lastMessage: '',
    lastMessageDate: '',
    name: '',
    opposingUser: emptyUserDto,
    opposingUserId: '',
    orderId: '',
    unreadMessagesCount: 0
};
