import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { clientFragment } from './fragments/client.fragment';

import type { UserAdminResolverInterface } from '@dotgoclub/client-contracts';

export const updateUserMutation$ = gqlMutationOperation<UserAdminResolverInterface, 'updateUser'>(
    adminApolloClient,
    'updateUser',
    gql`
        mutation updateUser($id: ID!, $input: UpdateUserInput!) {
            updateUser(id: $id, input: $input) {
                ...ClientFragment
            }
        }
        ${clientFragment}
    `
);
