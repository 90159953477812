import { getErrorMessage } from '@rnw-community/shared';
import { catchError, concatMap, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { AdminRoutesEnum, adminNavigateAction } from '../../../general';
import { displayToast } from '../../../general/helper/display-toast';
import { createPricingMutation$ } from '../../queries/create-pricing.mutation';
import { pricingItemCreateAction, pricingItemCreateFailedAction, pricingItemCreateSuccessAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const pricingItemCreateEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingItemCreateAction),
        toPayload(),
        switchMap(input =>
            createPricingMutation$({ input }).pipe(
                concatMap(data => {
                    displayToast({ type: 'success', header: 'Pricing was successfully created' });

                    return [pricingItemCreateSuccessAction(serializeDateFields(data)), adminNavigateAction(AdminRoutesEnum.Pricing)];
                }),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: `Failed updating pricing ${getErrorMessage(error)}` });

                    return [pricingItemCreateFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
