import { countriesListEpic } from './epics/countries-list.epic';
import { currenciesLoadEpic } from './epics/currencies-list.epic';
import { pricingItemCreateEpic } from './epics/pricing-item-create.epic';
import { pricingItemDeleteEpic } from './epics/pricing-item-delete.epic';
import { pricingItemLoadEpic } from './epics/pricing-item-load.epic';
import { pricingItemSaveEpic } from './epics/pricing-item-save.epic';
import { pricingListEpic } from './epics/pricing-list.epic';

export const pricingEpics = [
    pricingListEpic,
    pricingItemLoadEpic,
    currenciesLoadEpic,
    countriesListEpic,
    pricingItemSaveEpic,
    pricingItemCreateEpic,
    pricingItemDeleteEpic
];
