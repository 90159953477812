import { Flex } from '@rnw-community/fast-style';
import { StyleSheet } from 'react-native';

import { AdminColorsEnum } from '../../enum/admin-colors.enum';
import { AdminFont } from '../../styles/admin-font';
import { adminStep } from '../../styles/admin-step';

export const AdminErrorStyles = StyleSheet.create({
    header: {
        ...Flex.column.flexEnd.flexStart,
        height: '50%'
    },
    headerText: {
        ...AdminFont.Inter.xl.darkGrey,
        marginBottom: adminStep(2)
    },
    subHeaderText: {
        ...AdminFont.Inter.xs.darkGrey
    },
    wrapper: {
        ...Flex.column.spaceBetween.stretch,
        backgroundColor: AdminColorsEnum.white,
        height: '100%',
        padding: adminStep(2),
        width: '100%',
        zIndex: 9999
    }
});
