import { getErrorMessage } from '@rnw-community/shared';
import { catchError, exhaustMap, map } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { updateSupplierByIdMutation$ } from '../../queries/update-supplier-by-id.mutation';
import { updateSupplierItemAction, updateSupplierItemFailedAction, updateSupplierItemSuccessAction } from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const updateSupplierByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(updateSupplierItemAction),
        toPayload(),
        exhaustMap(({ supplierId, input }) =>
            updateSupplierByIdMutation$({ supplierId, input }).pipe(
                map(supplier => {
                    displayToast({ type: 'success', header: `Supplier has been successfully updated` });

                    return updateSupplierItemSuccessAction(serializeDateFields(supplier));
                }),
                catchError((err: unknown) => {
                    const errorMsg = getErrorMessage(err);
                    displayToast({ type: 'error', header: `Supplier update failed : ${errorMsg}` });

                    return [updateSupplierItemFailedAction(errorMsg)];
                })
            )
        )
    );
