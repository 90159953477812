import { AddressType, GeocodingAddressComponentType, PlaceType1, PlaceType2 } from '@googlemaps/google-maps-services-js';

export const AddressTypeEnum = {
    ...AddressType,
    ...GeocodingAddressComponentType,
    ...PlaceType2,
    ...PlaceType1,
    grocery_or_supermarket: 'grocery_or_supermarket',
    tourist_attraction: 'tourist_attraction',
    plus_code: 'plus_code'
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AddressTypeEnum = AddressType & GeocodingAddressComponentType & PlaceType1 & PlaceType2;
