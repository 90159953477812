import { emptyBaseEntity } from '../../../common';

import { emptyCategoryDto } from './category-dto.interface';

import type { BaseEntityInterface } from '../../../common';
import type { CatalogImageDtoInterface } from './catalog-image-dto.interface';
import type { CategoryDtoInterface } from './category-dto.interface';
import type { ProductOptionDtoInterface } from './product-option-dto.interface';
import type { ProductVariantDtoInterface } from './product-variant-dto.interface';

export interface ProductDtoInterface extends BaseEntityInterface {
    description: string;
    externalId: string;
    images: CatalogImageDtoInterface[];
    mainCategory: CategoryDtoInterface;
    mainCategoryId: string;
    mainImage?: CatalogImageDtoInterface;
    name: string;
    options: ProductOptionDtoInterface[];
    sideCategories: CategoryDtoInterface[];
    variants: ProductVariantDtoInterface[];
}

export const emptyProductDto: ProductDtoInterface = {
    ...emptyBaseEntity,
    description: '',
    externalId: '',
    images: [],
    mainCategory: emptyCategoryDto,
    mainCategoryId: '',
    name: '',
    options: [],
    sideCategories: [],
    variants: []
};
