import { AddressType, GeocodingAddressComponentType } from '@googlemaps/google-maps-services-js';
import { isEmptyString } from '@rnw-community/shared';

import { truncateString } from './string.util';

import type { AddressComponent } from '@googlemaps/google-maps-services-js';

export const findTypeInAddressComponents = (
    components: AddressComponent[],
    type: AddressType | GeocodingAddressComponentType,
    field: 'long_name' | 'short_name' = 'long_name'
): string => components.find(addressComponent => addressComponent.types.includes(type))?.[field] ?? '';

export const hasNoStreetAddress = (components: AddressComponent[] = []): boolean =>
    isEmptyString(findTypeInAddressComponents(components, AddressType.route));

export const isDeliveryAddressCorrect = (components: AddressComponent[] = []): boolean =>
    components.filter(
        addressComponent =>
            addressComponent.types.includes(AddressType.route) ||
            addressComponent.types.includes(GeocodingAddressComponentType.street_number)
    ).length === 2;

export const formatAddressComponents = (components: AddressComponent[] = [], establishmentName = ''): string =>
    [
        establishmentName,
        findTypeInAddressComponents(components, AddressType.route),
        findTypeInAddressComponents(components, GeocodingAddressComponentType.street_number)
    ]
        .filter(Boolean)
        .join(', ');

export const formatAddressComponentsTruncated = (
    components: AddressComponent[] = [],
    establishmentName = '',
    maxLength = 24
): string => {
    const address = [formatAddressComponents(components, establishmentName)].filter(Boolean).join(', ');

    return truncateString(address, maxLength);
};

export const getStreetAddressLine = (address: AddressComponent[]): string =>
    `${findTypeInAddressComponents(address, AddressType.route)} ${findTypeInAddressComponents(
        address,
        GeocodingAddressComponentType.street_number
    )}`.trim();

export const getCityAddressLine = (address: AddressComponent[]): string =>
    [findTypeInAddressComponents(address, AddressType.postal_code), findTypeInAddressComponents(address, AddressType.locality)]
        .join(', ')
        .trim();
