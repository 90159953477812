import { DeliveryValueEnum } from '../../enum';

export interface ValuePricingInputInterface extends Partial<Record<DeliveryValueEnum, number>> {}

export const emptyValuePricingInput: ValuePricingInputInterface = {
    [DeliveryValueEnum.Cheap]: 0,
    [DeliveryValueEnum.Medium]: 0,
    [DeliveryValueEnum.Expensive]: 0,
    [DeliveryValueEnum.VeryExpensive]: 0,
    [DeliveryValueEnum.Luxury]: 0
};
