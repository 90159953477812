import { gql } from '@apollo/client';

export const pricingFragment = gql`
    fragment PricingFragment on Pricing {
        intervalDeliveryTimeInHours
        intervalBetweenTimeFromAndTimeTillInHours
        commission
        id
        distance {
            Short
            Medium
            Long
            XLong
        }
        size {
            Small
            Medium
            Large
        }
        country {
            name
            id
            cca2
        }
        city {
            id
            name
        }
        minimalPrice
        value {
            Cheap
            Expensive
            Luxury
            Medium
            VeryExpensive
        }
        defaultPurchaseCost
        maxPurchaseCost
        stepPurchaseCost
        maximumTipsCost
        tipsCostStep
        deliveryFeeStepsCount
        deliveryFeeStepValue
        time {
            EarlyMorning
            Evening
            LateEvening
            Lunch
            MidDay
            Morning
            Night
        }
    }
`;
