import React, { FC } from 'react';
import { Form } from 'rsuite';

import { H1, InputFormGroup } from '../../../../general';
import { PricingFormStyle } from '../pricing-form.styles';

export const FormGroupPurchaseCost: FC = () => (
    <Form.Group style={PricingFormStyle.FormGroup}>
        <H1>Purchase cost</H1>
        <InputFormGroup fieldLabel="defaultPurchaseCost" label="Default purchase cost" />
        <InputFormGroup fieldLabel="maxPurchaseCost" label="Max purchase cost" />
        <InputFormGroup fieldLabel="stepPurchaseCost" label="Step purchase cost" />
    </Form.Group>
);
