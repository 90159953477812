import MinusSquareIcon from '@rsuite/icons/legacy/MinusSquare';
import PlusSquareIcon from '@rsuite/icons/legacy/PlusSquare';
import React from 'react';
import { IconButton, Table } from 'rsuite';
interface Props<T> {
    expandedRowKeys: string[];
    onChange: (rowData?: T) => void;
    rowData?: T;
}

export const ExpandCell = <T extends { id: string }>({ rowData, expandedRowKeys, onChange, ...props }: Props<T>): JSX.Element => {
    const icon = expandedRowKeys.some(key => key === rowData?.id) ? <MinusSquareIcon /> : <PlusSquareIcon />;

    const handleClick = (): void => void onChange(rowData);

    return (
        <Table.Cell {...props}>
            <IconButton appearance="subtle" icon={icon} onClick={handleClick} size="xs" />
        </Table.Cell>
    );
};
