import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { pricingFragment } from './fragments/pricing.fragment';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const updatePricingMutation$ = gqlMutationOperation<PricingAdminResolverInterface, 'updatePricing'>(
    adminApolloClient,
    'updatePricing',
    gql`
        mutation updatePricing($id: ID!, $input: UpdatePricingInput!) {
            updatePricing(id: $id, input: $input) {
                ...PricingFragment
            }
        }
        ${pricingFragment}
    `
);
