import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFieldsArray } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getStoresBySupplierIdQuery$ } from '../../queries/get-all-stores-by-supplier-id.query';
import {
    supplierStoresListBySupplierIdLoadAction,
    supplierStoresListBySupplierIdLoadFailedAction,
    supplierStoresListBySupplierIdLoadSuccessAction
} from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadStoresBySupplierIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(supplierStoresListBySupplierIdLoadAction),
        toPayload(),
        switchMap(supplierId =>
            getStoresBySupplierIdQuery$({ supplierId }).pipe(
                map(store => supplierStoresListBySupplierIdLoadSuccessAction(serializeDateFieldsArray(store))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load stores for the selected supplier" });

                    return [supplierStoresListBySupplierIdLoadFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
