import { emptyFn } from '@rnw-community/shared';

import type { OnEventFn } from '@rnw-community/shared';

export interface BreadcrumbInterface {
    isActive: boolean;
    onClick: OnEventFn<void>;
    title: string;
}

export const createBreadcrumb = (title: string, isActive = false, onClick: OnEventFn<void> = emptyFn): BreadcrumbInterface => ({
    title,
    isActive,
    onClick
});
