import { emptyBaseEntity } from '../../../common';
import { DeliveryTimeEnum } from '../../enum';

import type { PickWithBaseEntity } from '../../../common';
import type { TimePricingEntityInterface } from '../../entities';

export interface TimePricingDtoInterface extends PickWithBaseEntity<TimePricingEntityInterface, DeliveryTimeEnum> {}

export const emptyTimePricingDto: TimePricingDtoInterface = {
    ...emptyBaseEntity,
    [DeliveryTimeEnum.Night]: 0,
    [DeliveryTimeEnum.EarlyMorning]: 0,
    [DeliveryTimeEnum.Morning]: 0,
    [DeliveryTimeEnum.MidDay]: 0,
    [DeliveryTimeEnum.Lunch]: 0,
    [DeliveryTimeEnum.Evening]: 0,
    [DeliveryTimeEnum.LateEvening]: 0
};
