import { emptyBaseEntity } from '../../../common';

import type { BaseEntityInterface } from '../../../common';

export interface CurrencyDtoInterface extends BaseEntityInterface {
    name: string;
    symbol: string;
}

export const emptyCurrencyDto: CurrencyDtoInterface = {
    ...emptyBaseEntity,
    name: '',
    symbol: ''
};
