import { isDefined } from '@rnw-community/shared';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Message, SelectPicker } from 'rsuite';

import { H1, InputFormGroup } from '../../../../general';
import { countriesSelector } from '../../../state/pricing.selector';
import { PricingFormStyle } from '../pricing-form.styles';

import type { CityDtoInterface, CountryDtoInterface, PricingInputInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { FC } from 'react';
import type { SelectPickerProps } from 'rsuite';
// eslint-disable-next-line node/no-missing-import
import type { ItemDataType } from 'rsuite/esm/@types/common';

type OnSelect = SelectPickerProps['onSelect'];

const mapEntities = (
    arr: Array<WithSerializedDates<CityDtoInterface>> | Array<WithSerializedDates<CountryDtoInterface>>
): ItemDataType[] => arr.map(item => ({ label: item.name, value: item.id }));

export const FormGroupCountryCityPrice: FC = () => {
    const countries = useSelector(countriesSelector);

    const { values: formikValues, setFieldValue, setFieldTouched, getFieldMeta } = useFormikContext<PricingInputInterface>();

    const selectedCountry = countries.find(item => item.id === formikValues.countryId);
    const availableCities = selectedCountry?.cities ?? [];
    const selectedCity = availableCities.find(item => item.id === formikValues.cityId);

    const handleCountrySelect: OnSelect = (_, item) => {
        setFieldTouched('countryId', true);
        setFieldValue('countryId', item.value);
    };
    const handleCitySelect: OnSelect = (_, item) => {
        setFieldTouched('cityId', true);
        setFieldValue('cityId', item.value);
    };
    const handleClean = (item: string) => () => void setFieldValue(item, '');

    const countryFieldMeta = getFieldMeta('countryId');
    const cityFieldMeta = getFieldMeta('cityId');

    // TODO: Extract select input to generic component
    return (
        <Form.Group style={PricingFormStyle.FormGroup}>
            <H1>Country</H1>
            <Form.ControlLabel>Select Country</Form.ControlLabel>
            <SelectPicker
                data={mapEntities(countries)}
                onClean={handleClean('countryId')}
                onSelect={handleCountrySelect}
                style={PricingFormStyle.FormControl}
                {...(isDefined(selectedCountry) && { value: selectedCountry.id })}
            />
            {isDefined(countryFieldMeta.error) && <Message type="error">{countryFieldMeta.error}</Message>}
            <Form.ControlLabel>City</Form.ControlLabel>
            <SelectPicker
                data={mapEntities(availableCities)}
                onClean={handleClean('cityId')}
                onSelect={handleCitySelect}
                style={PricingFormStyle.FormControl}
                {...(isDefined(selectedCity) && { value: selectedCity.id })}
            />
            {isDefined(cityFieldMeta.error) && <Message type="error">{cityFieldMeta.error}</Message>}
            <InputFormGroup fieldLabel="minimalPrice" label="Minimal price" />
            <InputFormGroup fieldLabel="commission" label="Commission" />
            <InputFormGroup fieldLabel="intervalDeliveryTimeInHours" label="Interval delivery time in hours" />
            <InputFormGroup
                fieldLabel="intervalBetweenTimeFromAndTimeTillInHours"
                label="Interval delivery time with time from in hours"
            />
        </Form.Group>
    );
};
