import { emptyBaseEntity } from '../../common';

import type { BaseEntityInterface } from '../../common';
import type { FileEntityInterface } from '../../files';
import type { OrderEntityInterface } from '../../orders';
import type { PaymentTokenEntityInterface, PaymentTransactionEntityInterface } from '../../payment';
import type { UserAvatarEntityInterface } from '../../user-avatars';

export interface UserEntityInterface extends BaseEntityInterface {
    acceptedOrders: OrderEntityInterface[];
    avatar?: UserAvatarEntityInterface;
    birthdate?: Date;
    deviceTokens: string[];
    email?: string;
    isAdmin: boolean;
    isDeactivated: boolean;
    isEmailVerified: boolean;
    isOnBoardingComplete: boolean;
    name?: string;
    orders: OrderEntityInterface[];
    paymentTokens: PaymentTokenEntityInterface[];
    paymentTransactions: PaymentTransactionEntityInterface[];
    phoneNumber: string;
    quickbloxId?: number;
    quickbloxPassword?: string;
    uploadedFiles: FileEntityInterface[];
}

export const emptyUserEntity: UserEntityInterface = {
    ...emptyBaseEntity,
    acceptedOrders: [],
    deviceTokens: [],
    isAdmin: false,
    isEmailVerified: false,
    isDeactivated: false,
    isOnBoardingComplete: false,
    orders: [],
    paymentTokens: [],
    paymentTransactions: [],
    phoneNumber: '',
    uploadedFiles: []
};
