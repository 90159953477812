import { emptyBaseEntity } from '../../../common';
import { CatalogImageTypeEnum } from '../../enum';

import type { BaseEntityInterface } from '../../../common';
import type { OrderItemDtoInterface } from '../../../orders';
import type { CategoryDtoInterface } from './category-dto.interface';
import type { ProductDtoInterface } from './product-dto.interface';
import type { ProductVariantDtoInterface } from './product-variant-dto.interface';
import type { SupplierDtoInterface } from './supplier-dto.interface';

export interface CatalogImageDtoInterface extends BaseEntityInterface {
    alt: string;
    category?: CategoryDtoInterface;
    orderItem?: OrderItemDtoInterface;
    product?: ProductDtoInterface;
    productMainImage?: ProductDtoInterface;
    productVariant?: ProductVariantDtoInterface;
    sourceLink?: string;
    src: string;
    supplier?: SupplierDtoInterface;
    type: CatalogImageTypeEnum;
}

export const emptyCatalogImageDto: CatalogImageDtoInterface = {
    ...emptyBaseEntity,
    alt: '',
    sourceLink: '',
    src: '',
    type: CatalogImageTypeEnum.CATEGORY
};
