import { useFocusEffect } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Pagination, Table } from 'rsuite';

import { emptyPaginationArgs } from '@dotgoclub/client-contracts';

import { clientListLoadAction, clientSelector } from '../../../clients';
import { AdminPageContent, AdminRoutesEnum, EditCell, useAdminNavigation } from '../../../general';

import type { PricingRowInterface } from '../../../pricing/interfaces/pricing-row.interface';
import type { UserDtoInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

const { Column, HeaderCell, Cell } = Table;

export const AdminClientsPage: FC = () => {
    const dispatch = useDispatch();
    const { list, isLoading, pagination } = useSelector(clientSelector);

    const navigation = useAdminNavigation();

    useFocusEffect(useCallback(() => void dispatch(clientListLoadAction({ pagination: emptyPaginationArgs })), []));

    const handleEditPress = (id: string) => (): void => void navigation.navigate(AdminRoutesEnum.ClientForm, { id });
    const handleShowOrdersPress = (userId: string) => (): void =>
        void navigation.navigate(AdminRoutesEnum.Orders, { searchParam: userId });
    const handleChangePage = (page: number): void =>
        void dispatch(clientListLoadAction({ pagination: { page, limit: pagination.itemsPerPage } }));

    return (
        <AdminPageContent title="Clients">
            <Table cellBordered data={list} height={600} loading={isLoading}>
                <Column flexGrow={1}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Phone</HeaderCell>
                    <Cell dataKey="phoneNumber" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Email</HeaderCell>
                    <Cell dataKey="email" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>isAdmin</HeaderCell>
                    <Cell dataKey="isAdmin">{(rowData: UserDtoInterface) => <Checkbox checked={rowData.isAdmin} />}</Cell>
                </Column>
                <Column flexGrow={1} verticalAlign="top">
                    <HeaderCell>Show orders</HeaderCell>
                    <Cell>
                        {(rowData: UserDtoInterface) => (
                            <Button appearance="primary" color="blue" onClick={handleShowOrdersPress(rowData.id)} size="sm">
                                Show orders
                            </Button>
                        )}
                    </Cell>
                </Column>
                <Column flexGrow={1} verticalAlign="top">
                    <HeaderCell>Edit</HeaderCell>
                    <Cell>
                        {(rowData: PricingRowInterface) => <EditCell handleClick={handleEditPress(rowData.id)} rowData={rowData} />}
                    </Cell>
                </Column>
            </Table>
            <Pagination
                activePage={pagination.currentPage}
                limit={pagination.itemsPerPage}
                onChangePage={handleChangePage}
                total={pagination.totalItems ?? 0}
            />
        </AdminPageContent>
    );
};
