import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const createStoreMutation$ = gqlMutationOperation<CatalogAdminResolverInterface, 'createStore'>(
    adminApolloClient,
    'createStore',
    gql`
        mutation createStore($input: CreateStoreInput!) {
            createStore(input: $input) {
                id
            }
        }
    `
);
