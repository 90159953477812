import React from 'react';
import { Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { Button, ButtonGroup, Modal } from 'rsuite';

import { AdminRoutesEnum, useAdminNavigation } from '../../../../general';
import { pricingItemDeleteAction } from '../../../state/pricing.action';

import { DeleteModalStyle } from './delete-modal-style';

import type { FC, SetStateAction } from 'react';

interface Props {
    closeModal: React.Dispatch<SetStateAction<boolean>>;
    id: string;
    shown: boolean;
}

export const DeleteModal: FC<Props> = ({ shown, id, closeModal }) => {
    const dispatch = useDispatch();
    const navigation = useAdminNavigation();

    const handleDelete = (): void => {
        closeModal(false);
        dispatch(pricingItemDeleteAction(id));
        navigation.navigate(AdminRoutesEnum.Pricing);
    };
    const handleCancel = (): void => {
        closeModal(false);
    };

    return (
        <Modal open={shown}>
            <View style={DeleteModalStyle.modal}>
                <Text>You sure?</Text>
                <ButtonGroup style={DeleteModalStyle.buttonGroup}>
                    <Button color="red" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button onClick={handleCancel}>Cancel</Button>
                </ButtonGroup>
            </View>
        </Modal>
    );
};
