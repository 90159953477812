import { emptyBaseEntity } from '../../../common';
import { DeliveryDistanceEnum } from '../../enum';

import type { PickWithBaseEntity } from '../../../common';
import type { DistancePricingEntityInterface } from '../../entities';

export interface DistancePricingDtoInterface extends PickWithBaseEntity<DistancePricingEntityInterface, DeliveryDistanceEnum> {}

export const emptyDistancePricingDto: DistancePricingDtoInterface = {
    ...emptyBaseEntity,
    [DeliveryDistanceEnum.Long]: 0,
    [DeliveryDistanceEnum.Medium]: 0,
    [DeliveryDistanceEnum.Short]: 0,
    [DeliveryDistanceEnum.XLong]: 0
};
