export enum ProductMetadataKeysEnum {
    brandName = 'brandName',
    carbohydrates = 'carbohydrates',
    cellulose = 'cellulose',
    fats = 'fats',
    importerCompany = 'importerCompany',
    kcal = 'kcal',
    kilojoules = 'kilojoules',
    manufacturerCountry = 'manufacturerCountry',
    protein = 'protein',
    salt = 'salt',
    saturatedFats = 'saturatedFats',
    sugar = 'sugar',
    vat = 'vat'
}
