import { AdminColorsEnum, adminStep } from '../../../general';

export const AdminClientFormPageStyle = {
    toggleText: {
        color: AdminColorsEnum.white
    },
    formControl: {
        marginRight: adminStep(),
        marginBottom: adminStep()
    }
};
