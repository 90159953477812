import React, { FC } from 'react';
import { Form } from 'rsuite';

import { H1, InputFormGroup } from '../../../../general';
import { PricingFormStyle } from '../pricing-form.styles';

export const FormGroupDeliveryFee: FC = () => (
    <Form.Group style={PricingFormStyle.FormGroup}>
        <H1>Delivery fee</H1>
        <InputFormGroup fieldLabel="deliveryFeeStepsCount" label="Delivery fee steps count" />
        <InputFormGroup fieldLabel="deliveryFeeStepValue" label="Delivery fee step value" />
    </Form.Group>
);
