import { emptyBaseEntity } from '../../../common';
import { emptyCoordinates, emptyCurrencyDto, emptyDirectionDto } from '../../../geolocation';
import { DeliverySizeEnum } from '../../../pricing';
import { emptyUserDto } from '../../../users';
import { OrderStatusEnum } from '../../enum';

import type { StoreDtoInterface } from '../../../catalog';
import type { PickWithBaseEntity } from '../../../common';
import type { FileDtoInterface } from '../../../files';
import type { CoordinatesInterface, CurrencyDtoInterface, DirectionDtoInterface } from '../../../geolocation';
import type { PaymentTransactionDtoInterface } from '../../../payment';
import type { PricingDtoInterface } from '../../../pricing';
import type { UserDtoInterface } from '../../../users';
import type { OrderEntityInterface } from '../../entities';
import type { OrderDeliveryMethodEnum } from '../../enum';
import type { OrderChangesDtoInterface } from './order-changes-dto.interface';
import type { OrderItemDtoInterface } from './order-item-dto.interface';

type FIELDS =
    | 'chatId'
    | 'clientRating'
    | 'comment'
    | 'commission'
    | 'contactPersonName'
    | 'contactPersonNumber'
    | 'courierRating'
    | 'currencyId'
    | 'deliveryCost'
    | 'deliverySize'
    | 'description'
    | 'finalPurchaseCost'
    | 'fromAddress'
    | 'fromCoordinates'
    | 'fromEstablishmentName'
    | 'orderStatus'
    | 'purchaseCost'
    | 'securityDeposit'
    | 'timeFrom'
    | 'timeTill'
    | 'tipsCost'
    | 'toAddress'
    | 'toCoordinates';

export interface OrderDtoInterface extends PickWithBaseEntity<OrderEntityInterface, FIELDS> {
    autoOrderConfirmTimeoutInMinutes: number;
    client: UserDtoInterface;
    courier?: UserDtoInterface | null;
    courierPosition?: CoordinatesInterface | null;
    currency: CurrencyDtoInterface;
    declineDeliveryTimeoutInMinutes: number;
    deliveryImages: FileDtoInterface[];
    deliveryMethod?: OrderDeliveryMethodEnum | null;
    descriptionDeliveryMethod?: string;
    images: FileDtoInterface[];
    initialDeliveryFee: number;
    isStoreOrder: boolean;
    orderChanges: OrderChangesDtoInterface[];
    orderItems: OrderItemDtoInterface[];
    paymentTransactions: PaymentTransactionDtoInterface[];
    pricing?: PricingDtoInterface | null;
    route: DirectionDtoInterface;
    store?: StoreDtoInterface;
}

export const emptyOrderDto: OrderDtoInterface = {
    ...emptyBaseEntity,
    autoOrderConfirmTimeoutInMinutes: 0,
    chatId: undefined,
    client: emptyUserDto,
    clientRating: undefined,
    comment: '',
    commission: 0,
    contactPersonName: undefined,
    contactPersonNumber: undefined,
    courierRating: undefined,
    currency: emptyCurrencyDto,
    currencyId: '',
    declineDeliveryTimeoutInMinutes: 0,
    deliveryCost: 0,
    deliverySize: DeliverySizeEnum.Small,
    description: '',
    finalPurchaseCost: 0,
    fromAddress: [],
    fromCoordinates: emptyCoordinates,
    fromEstablishmentName: '',
    images: [],
    deliveryImages: [],
    initialDeliveryFee: 0,
    isStoreOrder: false,
    orderChanges: [],
    orderItems: [],
    orderStatus: OrderStatusEnum.Placed,
    paymentTransactions: [],
    purchaseCost: 0,
    route: emptyDirectionDto,
    securityDeposit: 0,
    timeFrom: new Date(),
    timeTill: new Date(),
    tipsCost: 0,
    toAddress: [],
    toCoordinates: emptyCoordinates
};
