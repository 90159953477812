import { PaymentMethodEnum } from '../../enum';

import type { PaymentHeaderInputInterface } from './payment-header-input.interface';

export interface PaymentInputInterface {
    data: string;
    header: PaymentHeaderInputInterface;
    merchantId: string;
    paymentTokenId: string;
    signature: string;
    type: PaymentMethodEnum;
    version: string;
}

export const emptyPaymentInput: PaymentInputInterface = {
    data: '',
    merchantId: '',
    paymentTokenId: '',
    signature: '',
    type: PaymentMethodEnum.UnknownCard,
    version: '',
    header: {
        ephemeralPublicKey: '',
        publicKeyHash: '',
        transactionId: ''
    }
};
