// TODO: Statuses Placed, Processing, CourierOnTheWay, CourierNearDelivery and Reviewed should be removed
export enum OrderStatusEnum {
    AutomaticallyCanceled = 'AutomaticallyCanceled',
    AutomaticallyConfirmed = 'AutomaticallyConfirmed',
    Canceled = 'Canceled',
    ConfirmedByClient = 'ConfirmedByClient',
    CourierArrived = 'CourierArrived',
    CourierFound = 'CourierFound',
    CourierNearDelivery = 'CourierNearDelivery',
    CourierNotFound = 'CourierNotFound',
    CourierOnTheWay = 'CourierOnTheWay',
    CourierSearch = 'CourierSearch',
    Delivered = 'Delivered',
    Paid = 'Paid',
    Placed = 'Placed',
    Processing = 'Processing',
    Reviewed = 'Reviewed'
}

const OrderStatusMap: Record<OrderStatusEnum, string> = {
    [OrderStatusEnum.Placed]: 'Order is placed',
    [OrderStatusEnum.CourierSearch]: 'Looking for courier',
    [OrderStatusEnum.CourierFound]: 'Courier is found',
    [OrderStatusEnum.Processing]: 'Processing order',
    [OrderStatusEnum.Paid]: 'Order is paid',
    [OrderStatusEnum.CourierOnTheWay]: 'Courier is on the way',
    [OrderStatusEnum.CourierNearDelivery]: 'Courier is near you',
    [OrderStatusEnum.CourierArrived]: 'Courier arrived',
    [OrderStatusEnum.Delivered]: 'Order is delivered',
    [OrderStatusEnum.AutomaticallyConfirmed]: 'Order was automatically confirmed',
    [OrderStatusEnum.ConfirmedByClient]: 'Order was confirmed by client',
    [OrderStatusEnum.Reviewed]: 'Order is reviewed',
    [OrderStatusEnum.Canceled]: 'Order is canceled',
    [OrderStatusEnum.CourierNotFound]: 'Courier is not found',
    [OrderStatusEnum.AutomaticallyCanceled]: 'Order is automatically canceled'
};

export const getOrderStatusText = (status: OrderStatusEnum): string => OrderStatusMap[status];
