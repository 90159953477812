export interface PaginationMetaInterface {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems?: number;
    totalPages?: number;
}

export const emptyPaginationMeta: PaginationMetaInterface = {
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0
};
