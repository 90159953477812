import { createSlice } from '@reduxjs/toolkit';
import { loadingFailed, loadingFinished, loadingStarted } from '@rnw-community/redux-loadable';

import { initialAuthState } from './auth.state';

import type { JwtTokensResponseInterface, LoginArgsInterface } from '@dotgoclub/client-contracts';
import type { PayloadAction } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    initialState: initialAuthState,
    name: 'auth',
    reducers: {
        login(state, action: PayloadAction<LoginArgsInterface>) {
            loadingStarted(state.otpEnter);
            state.phoneNumber = action.payload.phoneNumber;
        },
        loginSuccess(state, action: PayloadAction<JwtTokensResponseInterface>) {
            loadingFinished(state.otpEnter);
            state.credentials = action.payload;
            state.isOtpRequested = false;
            state.isSessionExpired = false;
            state.areOnBoardingScreensRequired = false;
            state.otpRequestNextDate = undefined;
        },
        loginFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.otpEnter, action.payload);
        },
        otp(state, action: PayloadAction<string>) {
            loadingStarted(state.phoneEnter);
            state.phoneNumber = action.payload;
        },
        otpSuccess(state, action: PayloadAction<string | null | undefined>) {
            loadingFinished(state.phoneEnter);
            state.isOtpRequested = true;
            state.otpRequestNextDate = action.payload;
        },
        otpFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.phoneEnter, action.payload);
            state.isOtpRequested = false;
        },
        refresh(state, action: PayloadAction<JwtTokensResponseInterface>) {
            state.credentials = action.payload;
        },
        cancelOtp(state) {
            state.isOtpRequested = false;
        },
        logout(state) {
            Object.assign(state, initialAuthState);
            state.areOnBoardingScreensRequired = false;
        },
        sessionExpired(state) {
            state.isSessionExpired = true;
        },
        completeOnBoarding(state) {
            state.areOnBoardingScreensRequired = false;
        },
        resetOtpState(state) {
            Object.assign(state.otpEnter, initialAuthState.otpEnter);
        }
    }
});

export interface AuthRootState {
    [authSlice.name]: ReturnType<typeof authSlice.reducer>;
}

export const authReducer = authSlice.reducer;
