import { getErrorMessage } from '@rnw-community/shared';
import { catchError, concatMap, switchMap, withLatestFrom } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { AdminRoutesEnum, adminNavigateAction } from '../../../general';
import { displayToast } from '../../../general/helper/display-toast';
import { updatePricingMutation$ } from '../../queries/update-pricing.mutation';
import { pricingSaveAction, pricingSaveFailedAction, pricingSaveSuccessAction } from '../pricing.action';

import type { PricingRootState } from '../pricing.slice';
import type { Action } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';

export const pricingItemSaveEpic: Epic<Action, Action, PricingRootState> = (actions$, state$) =>
    actions$.pipe(
        ofType(pricingSaveAction),
        toPayload(),
        withLatestFrom(state$, (input, state) => ({ input, id: state.pricing.item.id })),
        switchMap(args =>
            updatePricingMutation$(args).pipe(
                concatMap(data => {
                    displayToast({ type: 'success', header: 'Pricing was successfully updated' });

                    return [pricingSaveSuccessAction(serializeDateFields(data)), adminNavigateAction(AdminRoutesEnum.Pricing)];
                }),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: `Failed updating pricing ${getErrorMessage(error)}` });

                    return [pricingSaveFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
