import { DeliveryTimeEnum } from '../../enum';

export interface TimePricingInputInterface extends Partial<Record<DeliveryTimeEnum, number>> {}

export const emptyTimePricingInput: TimePricingInputInterface = {
    [DeliveryTimeEnum.EarlyMorning]: 0,
    [DeliveryTimeEnum.Morning]: 0,
    [DeliveryTimeEnum.Lunch]: 0,
    [DeliveryTimeEnum.Evening]: 0,
    [DeliveryTimeEnum.MidDay]: 0,
    [DeliveryTimeEnum.LateEvening]: 0,
    [DeliveryTimeEnum.Night]: 0
};
