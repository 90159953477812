import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFieldsArray } from '@dotgoclub/utils-lib';

import { findAllCountriesQuery$ } from '../../queries/find-countries.query';
import { pricingCountriesLoadAction, pricingCountriesLoadFailedAction, pricingCountriesLoadSuccessAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const countriesListEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingCountriesLoadAction),
        switchMap(() =>
            findAllCountriesQuery$().pipe(
                map(data => pricingCountriesLoadSuccessAction(serializeDateFieldsArray(data))),
                catchError((error: unknown) => [pricingCountriesLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
