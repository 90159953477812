import { emptyCoordinates, isEmptyCoordinates } from '../../interfaces';

import { emptyGeocodeResultDto, isEmptyGeocodeResult } from './geocode-result-dto.interface';

import type { CoordinatesInterface } from '../../interfaces';
import type { GeocodeResultDtoInterface } from './geocode-result-dto.interface';
import type { Place } from '@googlemaps/google-maps-services-js';

export interface GeolocationPlaceDtoInterface extends Pick<Place, 'name' | 'types'>, GeocodeResultDtoInterface {
    coordinates: CoordinatesInterface;
    formattedAddress?: string;
}

export const emptyGeolocationPlaceDto: GeolocationPlaceDtoInterface = {
    ...emptyGeocodeResultDto,
    coordinates: emptyCoordinates
};

export const isEmptyGeolocationPlace = (place: GeolocationPlaceDtoInterface): boolean =>
    isEmptyGeocodeResult(place) && isEmptyCoordinates(place.coordinates);
