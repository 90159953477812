import { Flex } from '@rnw-community/fast-style';

import { AdminColorsEnum } from '../../enum/admin-colors.enum';
import { adminStep } from '../../styles/admin-step';

export const AdminMenuStyles = {
    container: {
        ...Flex.column.flexStart.flexStart,
        height: '100%',
        backgroundColor: AdminColorsEnum.grey
    },
    logo: {
        width: '100%',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: AdminColorsEnum.darkgrey,
        padding: adminStep(2),
        height: adminStep(10),
        marginBottom: adminStep(2)
    }
};
