import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { findAllPricingsQuery$ } from '../../queries/find-pricings.query';
import { pricingListLoadAction, pricingListLoadFailedAction, pricingListLoadSuccessAction } from '../pricing.action';

import type { Epic } from 'redux-observable';

export const pricingListEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(pricingListLoadAction),
        toPayload(),
        switchMap(variables =>
            findAllPricingsQuery$(variables).pipe(
                map(data => pricingListLoadSuccessAction(serializeDateFields(data))),
                catchError((error: unknown) => [pricingListLoadFailedAction(getErrorMessage(error))])
            )
        )
    );
