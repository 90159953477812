import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { isDefined } from '@rnw-community/shared';

import { ErrorCodesEnum } from '@dotgoclub/client-contracts';

import { getRefreshTokenUtil } from '../util/get-refresh-token.util';

import type { ApolloLink } from '@apollo/client';
import type { Observable } from '@apollo/client/utilities';
import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';
import type { OnEventFn } from '@rnw-community/shared';

// TODO: Fix mutable variable
let isTokenRefreshing = false;
const pendingRequests: Array<OnEventFn<void, unknown>> = [];

const addPendingRequest = (resolve: OnEventFn<void>): void => void pendingRequests.push(() => void resolve());
const onRefreshEnd = (): void => void (isTokenRefreshing = false);

export const createErrorLink = (
    apiUrl: string,
    refreshTokenFn: () => string,
    refreshSuccessFn: (credentials: JwtTokensResponseInterface) => void,
    refreshErrorFn: () => void
): ApolloLink =>
    // TODO: Refactor token refreshing logic using more elegant approach
    onError(({ graphQLErrors, operation, forward }) => {
        if (isDefined(graphQLErrors) && isDefined(graphQLErrors[0].extensions)) {
            if (graphQLErrors[0].extensions.code === ErrorCodesEnum.INVALID_TOKEN) {
                let forward$: Observable<unknown> | null = null;

                if (isTokenRefreshing) {
                    forward$ = fromPromise(new Promise(addPendingRequest));
                } else {
                    isTokenRefreshing = true;
                    forward$ = fromPromise(
                        getRefreshTokenUtil(apiUrl, refreshTokenFn())
                            .then(credentials => {
                                refreshSuccessFn(credentials);
                                pendingRequests.map(callback => callback());
                                pendingRequests.length = 0;

                                return credentials.accessToken;
                            })
                            .catch(refreshErrorFn)
                            .finally(onRefreshEnd)
                    ).filter(value => Boolean(value));
                }

                return forward$.flatMap(() => forward(operation));
            }
        }

        return void null;
    });
