import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const createSupplierMutation$ = gqlMutationOperation<CatalogAdminResolverInterface, 'createSupplier'>(
    adminApolloClient,
    'createSupplier',
    gql`
        mutation createSupplier($input: CreateSupplierInput!) {
            createSupplier(input: $input) {
                id
            }
        }
    `
);
