import { createSlice } from '@reduxjs/toolkit';
import { loadingFailed, loadingFinished, loadingStarted } from '@rnw-community/redux-loadable';

import { initialOrderState } from './order.state';

import type { FindOrdersArgsInterface, OrderDtoInterface, OrderStatusEnum, PaginatedInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { PayloadAction } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
    initialState: initialOrderState,
    name: 'order',
    reducers: {
        listLoad(state, _action: PayloadAction<FindOrdersArgsInterface>) {
            loadingStarted(state);
        },
        listLoadSuccess(state, action: PayloadAction<WithSerializedDates<PaginatedInterface<OrderDtoInterface>>>) {
            loadingFinished(state);
            state.list = action.payload.items;
            state.pagination = action.payload.meta;
        },
        listLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        cancelSelectedOrders(state, _action: PayloadAction<string[]>) {
            loadingStarted(state);
        },
        cancelSelectedOrdersSuccess(state) {
            loadingFinished(state);
        },
        cancelSelectedOrdersFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        statusChanged(state, action: PayloadAction<OrderStatusEnum | undefined>) {
            state.orderStatusFilter = action.payload;
        },
        searchParamChanged(state, action: PayloadAction<string>) {
            state.searchParamFilter = action.payload;
        }
    }
});

export interface OrderRootState {
    [orderSlice.name]: ReturnType<typeof orderSlice.reducer>;
}

export const orderReducer = orderSlice.reducer;
