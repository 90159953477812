import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { imageFragment } from './../../../general/fragments/image.fragment';
import { categoryFragment } from './fragments';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const getCategoryByIdQuery$ = gqlQueryOperation<CatalogAdminResolverInterface, 'getCategoryById'>(
    adminApolloClient,
    'getCategoryById',
    gql`
        query getCategoryById($categoryId: ID!) {
            getCategoryById(categoryId: $categoryId) {
                ...CategoryFragment
                image {
                    ...ImageFragment
                }
            }
        }
        ${categoryFragment}
        ${imageFragment}
    `
);
