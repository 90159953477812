export enum DeliveryValueEnum {
    Cheap = 'Cheap',
    Expensive = 'Expensive',
    Luxury = 'Luxury',
    Medium = 'Medium',
    VeryExpensive = 'VeryExpensive'
}

export const deliveryValueMatrix: Record<DeliveryValueEnum, number> = {
    [DeliveryValueEnum.Cheap]: 0,
    [DeliveryValueEnum.Medium]: 50,
    [DeliveryValueEnum.Expensive]: 75,
    [DeliveryValueEnum.VeryExpensive]: 100,
    [DeliveryValueEnum.Luxury]: 500
};
