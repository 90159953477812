import { useIsFocused } from '@react-navigation/core';
import { loadingStateSelector } from '@rnw-community/redux-loadable';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { removeObjectFields } from '@dotgoclub/utils-lib';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../general';
import { createBreadcrumb } from '../../../general/helper/create-breadcrumb';
import { pricingCountriesLoadAction, pricingItemLoadAction, pricingSaveAction } from '../../state/pricing.action';
import { pricingItemSelector } from '../../state/pricing.selector';
import { PricingForm } from '../pricing-form/pricing-form';

import { DeleteModal } from './delete-modal/delete-modal';

import type { PricingInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const AdminPricingFormPage: FC = () => {
    const [deleteModalShown, setDeleteModalShown] = useState(false);
    const dispatch = useDispatch();
    const data = useSelector(pricingItemSelector);
    const [isLoading] = useSelector(loadingStateSelector('pricing'));

    const isRouteUpdated = useIsFocused();
    const navigation = useAdminNavigation();
    const { id = '' } = useAdminNavigationRouteParams<AdminRoutesEnum.PricingForm>();

    const initialEditPricingInput = {
        ...removeObjectFields(data, ['country', 'city', 'updatedAt', 'createdAt', 'deletedAt', 'id']),
        countryId: data.country.id,
        cityId: data.city.id
    };

    useEffect(() => void dispatch(pricingCountriesLoadAction()), []);
    useEffect(() => void dispatch(pricingItemLoadAction(id)), [isRouteUpdated, id]);

    const handleSubmit = (input: PricingInputInterface): void => void dispatch(pricingSaveAction(input));
    const handleRootRoutePricing = (): void => void navigation.navigate(AdminRoutesEnum.Pricing);
    const handleDelete = (): void => void setDeleteModalShown(true);

    const breadcrumbs = [createBreadcrumb('Pricing', false, handleRootRoutePricing), createBreadcrumb(`Pricing "${id}"`, true)];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title="Pricing">
            <DeleteModal closeModal={setDeleteModalShown} id={data.id} shown={deleteModalShown} />
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <PricingForm
                    initialValues={initialEditPricingInput}
                    onCancel={handleRootRoutePricing}
                    onDelete={handleDelete}
                    onSubmit={handleSubmit}
                />
            )}
        </AdminPageContent>
    );
};
