import { getErrorMessage } from '@rnw-community/shared';
import { catchError, concatMap, exhaustMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../general/helper/display-toast';
import { updateUserMutation$ } from '../../queries/update-user.mutation';
import { clientItemSaveAction, clientItemSaveFailedAction, clientItemSaveSuccessAction } from '../client.action';

import type { Epic } from 'redux-observable';

export const clientItemSaveEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(clientItemSaveAction),
        toPayload(),
        exhaustMap(({ id, input }) =>
            updateUserMutation$({ id, input }).pipe(
                concatMap(data => {
                    displayToast({ type: 'success', header: 'Client was successfully updated' });

                    return [clientItemSaveSuccessAction(serializeDateFields(data))];
                }),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: 'Client was not updated' });

                    return [clientItemSaveFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
