import React from 'react';
import { Text, View } from 'react-native';
import { Tag } from 'rsuite';

import type { RowDataType } from 'rsuite-table';

interface Props<O> {
    columnsToRender: O[];
    rowData?: RowDataType;
}
export const ExpandedTable = <O extends { dataKey: string; label: string }>({ rowData, columnsToRender }: Props<O>): JSX.Element => (
    <>
        {columnsToRender.map(({ label, dataKey }) => (
            <View key={label}>
                <Text>{label} </Text>
                <Tag size="lg">{rowData?.[dataKey]}</Tag>
            </View>
        ))}
    </>
);
