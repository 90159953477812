import React from 'react';
import { Table } from 'rsuite';

import { AdminPageContent } from '../admin-page-content/admin-page-content';

import type { FC } from 'react';

const { Column, HeaderCell, Cell } = Table;

export const AdminHomePage: FC = () => (
    <AdminPageContent breadcrumbs={[]} title="Home">
        <Table data={[]}>
            <Column>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id" />
            </Column>
            <Column>
                <HeaderCell>From</HeaderCell>
                <Cell dataKey="deliveryFrom" />
            </Column>
            <Column>
                <HeaderCell>To</HeaderCell>
                <Cell dataKey="deliveryTo" />
            </Column>
            <Column>
                <HeaderCell>Client</HeaderCell>
                <Cell dataKey="client" />
            </Column>
            <Column>
                <HeaderCell>Status</HeaderCell>
                <Cell dataKey="status" />
            </Column>
        </Table>
    </AdminPageContent>
);
