import { useFocusEffect } from '@react-navigation/core';
import { isNotEmptyString } from '@rnw-community/shared';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'rsuite';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import { supplierByIdLoadAction, updateSupplierItemAction } from '../../state/suppliers.action';
import { supplierItemSelector } from '../../state/suppliers.selector';
import { SupplierForm } from '../supplier-form/supplier-form';

import type { UpdateSupplierInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const EditSupplierFormPage: FC = () => {
    const dispatch = useDispatch();

    const { item: supplier, isLoading } = useSelector(supplierItemSelector);

    const initialValues: UpdateSupplierInputInterface = {
        name: supplier.name,
        description: supplier.description,
        src: supplier.image.src,
        alt: supplier.image.alt
    };

    const navigation = useAdminNavigation();

    const { supplierId } = useAdminNavigationRouteParams<AdminRoutesEnum.EditSupplierForm>();

    useFocusEffect(
        useCallback(() => void (isNotEmptyString(supplierId) && dispatch(supplierByIdLoadAction(supplierId))), [supplierId])
    );

    const handleFormSubmit = (input: UpdateSupplierInputInterface): void => {
        if (isNotEmptyString(supplierId)) {
            dispatch(updateSupplierItemAction({ input, supplierId }));
        }
    };

    const handleRootRouteSupplierPress = (): void => void navigation.navigate(AdminRoutesEnum.Suppliers);

    const breadcrumbs = [
        createBreadcrumb('Suppliers', false, handleRootRouteSupplierPress),
        createBreadcrumb(`Supplier "${supplier.name}"`, true)
    ];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={isLoading ? 'Loading...' : `Edit supplier "${supplier.name}"`}>
            {isLoading ? (
                <Loader size="lg" />
            ) : (
                <SupplierForm initialValues={initialValues} onCancel={handleRootRouteSupplierPress} onSubmit={handleFormSubmit} />
            )}
        </AdminPageContent>
    );
};
