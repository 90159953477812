import { useFocusEffect } from '@react-navigation/core';
import { isDefined } from '@rnw-community/shared';
import { useCallback, useRef } from 'react';

import type { FormikProps } from 'formik';
import type { RefObject } from 'react';

export const useFormikNavUpdateHook = <T>(initialValues: T): RefObject<FormikProps<T>> => {
    const formikRef = useRef<FormikProps<T>>(null);

    useFocusEffect(
        useCallback(() => {
            if (isDefined(formikRef.current)) {
                formikRef.current.setValues(initialValues, false);
            }
        }, [formikRef])
    );

    return formikRef;
};
