import { cs } from '@rnw-community/shared';
import React from 'react';
import { Text, View } from 'react-native';

import { H2Styles } from './h2.styles';

import type { FC } from 'react';

interface Props {
    isCentered?: boolean;
    title?: string;
}

export const H2: FC<Props> = ({ title = '', children, isCentered = false }) => (
    <View style={[H2Styles.wrapper, cs(isCentered, H2Styles.textCentered)]}>
        <Text style={H2Styles.text}>
            {title}
            {children}
        </Text>
    </View>
);
