import { initialLoadingState } from '@rnw-community/redux-loadable';

import { emptyPaginationMeta } from '@dotgoclub/client-contracts';

import type { OrderDtoInterface, OrderStatusEnum, PaginationMetaInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { LoadingStateInterface } from '@rnw-community/redux-loadable';

export interface OrderState extends LoadingStateInterface {
    list: WithSerializedDates<OrderDtoInterface[]>;
    orderStatusFilter?: OrderStatusEnum;
    pagination: PaginationMetaInterface;
    searchParamFilter?: string;
}

export const initialOrderState: OrderState = {
    ...initialLoadingState,
    list: [],
    pagination: emptyPaginationMeta,
    orderStatusFilter: undefined,
    searchParamFilter: undefined
};
