import { gql } from '@apollo/client';

export const storeFragment = gql`
    fragment StoreFragment on Store {
        id
        name
        externalId
        mapPlaceId
        cityId
    }
`;
