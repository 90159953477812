import { StyleSheet } from 'react-native';

import { AdminFont } from '../../styles/admin-font';
import { adminStep } from '../../styles/admin-step';

export const H1Styles = StyleSheet.create({
    text: {
        ...AdminFont.Inter.xxl.black
    },
    textCentered: {
        textAlign: 'center'
    },
    wrapper: {
        marginBottom: adminStep(2)
    }
});
