import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType, toPayload } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getSupplierByIdQuery$ } from '../../queries/get-supplier-by-id.query';
import { supplierByIdFailedAction, supplierByIdLoadAction, supplierByIdSuccessAction } from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadSupplierByIdEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(supplierByIdLoadAction),
        toPayload(),
        switchMap(supplierId =>
            getSupplierByIdQuery$({ supplierId }).pipe(
                map(supplier => supplierByIdSuccessAction(serializeDateFields(supplier))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load supplier by provided id" });

                    return [supplierByIdFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
