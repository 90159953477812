import { createSlice } from '@reduxjs/toolkit';
import { loadingFailed, loadingFinished, loadingStarted } from '@rnw-community/redux-loadable';

import { initialSuppliersState } from './suppliers.state';

import type {
    CategoryDtoInterface,
    CreateStoreInputInterface,
    CreateSupplierInputInterface,
    StoreDtoInterface,
    SupplierDtoInterface,
    UpdateCategoryByIdArgsInterface,
    UpdateStoreArgsInterface,
    UpdateSupplierByIdArgsInterface
} from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { PayloadAction } from '@reduxjs/toolkit';

export const suppliersSlice = createSlice({
    initialState: initialSuppliersState,
    name: 'suppliers',
    reducers: {
        listLoad(state) {
            loadingStarted(state);
        },
        listLoadSuccess(state, action: PayloadAction<WithSerializedDates<SupplierDtoInterface[]>>) {
            state.list = action.payload;
            loadingFinished(state);
        },
        listLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        categoriesListBySupplierIdLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state.categories);
        },
        categoriesListBySupplierIdLoadSuccess(state, action: PayloadAction<WithSerializedDates<CategoryDtoInterface[]>>) {
            state.categories.list = action.payload;
            loadingFinished(state.categories);
        },
        categoriesListBySupplierIdLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.categories, action.payload);
        },
        categoryItemLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state.categories);
        },
        categoryItemLoadSuccess(state, action: PayloadAction<WithSerializedDates<CategoryDtoInterface>>) {
            state.categories.item = action.payload;
            loadingFinished(state.categories);
        },
        categoryItemLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.categories, action.payload);
        },
        updateStoreItem(state, _action: PayloadAction<UpdateStoreArgsInterface>) {
            loadingStarted(state.stores);
        },
        updateStoreItemSuccess(state, action: PayloadAction<WithSerializedDates<StoreDtoInterface>>) {
            state.stores.item = action.payload;
            loadingFinished(state.stores);
        },
        updateStoreItemFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.stores, action.payload);
        },
        updateCategoryItem(state, _action: PayloadAction<UpdateCategoryByIdArgsInterface>) {
            loadingStarted(state.categories);
        },
        updateCategoryItemSuccess(state, action: PayloadAction<WithSerializedDates<CategoryDtoInterface>>) {
            state.categories.item = action.payload;
            loadingFinished(state.categories);
        },
        updateCategoryItemFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.categories, action.payload);
        },
        supplierByIdLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state);
        },
        supplierByIdSuccess(state, action: PayloadAction<WithSerializedDates<SupplierDtoInterface>>) {
            state.item = action.payload;
            loadingFinished(state);
        },
        supplierByIdFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        updateSupplierItem(state, _action: PayloadAction<UpdateSupplierByIdArgsInterface>) {
            loadingStarted(state);
        },
        updateSupplierItemSuccess(state, action: PayloadAction<WithSerializedDates<SupplierDtoInterface>>) {
            state.item = action.payload;
            loadingFinished(state);
        },
        updateSupplierItemFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        },
        storeListBySupplierIdLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state.stores);
        },
        storeListBySupplierIdLoadSuccess(state, action: PayloadAction<WithSerializedDates<StoreDtoInterface[]>>) {
            state.stores.list = action.payload;
            loadingFinished(state.stores);
        },
        storeListBySupplierIdLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.stores, action.payload);
        },
        storeItemLoad(state, _action: PayloadAction<string>) {
            loadingStarted(state.stores);
        },
        storeItemLoadSuccess(state, action: PayloadAction<WithSerializedDates<StoreDtoInterface>>) {
            state.stores.item = action.payload;
            loadingFinished(state.stores);
        },
        storeItemLoadFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.stores, action.payload);
        },
        createStoreItem(state, _input: PayloadAction<CreateStoreInputInterface>) {
            loadingStarted(state.stores);
        },
        createStoreItemSuccess(state) {
            loadingFinished(state.stores);
        },
        createStoreItemFailed(state, action: PayloadAction<string>) {
            loadingFailed(state.stores, action.payload);
        },
        createSupplierItem(state, _input: PayloadAction<CreateSupplierInputInterface>) {
            loadingStarted(state);
        },
        createSupplierItemSuccess(state) {
            loadingFinished(state);
        },
        createSupplierItemFailed(state, action: PayloadAction<string>) {
            loadingFailed(state, action.payload);
        }
    }
});

export interface SuppliersRootState {
    [suppliersSlice.name]: ReturnType<typeof suppliersSlice.reducer>;
}

export const suppliersReducer = suppliersSlice.reducer;
