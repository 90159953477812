import { initialLoadingState } from '@rnw-community/redux-loadable';

import { emptyPaginationMeta, emptyUserDto } from '@dotgoclub/client-contracts';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import type { PaginationMetaInterface, UserDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { LoadingStateInterface } from '@rnw-community/redux-loadable';

export interface ClientState extends LoadingStateInterface {
    item: WithSerializedDates<UserDtoInterface>;
    list: WithSerializedDates<UserDtoInterface[]>;
    pagination: PaginationMetaInterface;
}

export const initialClientState: ClientState = {
    ...initialLoadingState,
    list: [],
    item: serializeDateFields(emptyUserDto),
    pagination: emptyPaginationMeta
};
