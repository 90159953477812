import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../../general/admin-apollo-client';

import { imageFragment } from './../../../general/fragments/image.fragment';
import { categoryFragment } from './fragments/category.fragment';

import type { CatalogAdminResolverInterface } from '@dotgoclub/client-contracts';

export const getCategoriesBySupplierIdQuery$ = gqlQueryOperation<CatalogAdminResolverInterface, 'getCategoriesBySupplierId'>(
    adminApolloClient,
    'getCategoriesBySupplierId',
    gql`
        query getCategoriesBySupplierId($supplierId: ID!) {
            getCategoriesBySupplierId(supplierId: $supplierId) {
                ...CategoryFragment
                image {
                    ...ImageFragment
                }
            }
        }
        ${categoryFragment}
        ${imageFragment}
    `
);
