import { useIsFocused } from '@react-navigation/core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { emptyPricingInput } from '@dotgoclub/client-contracts';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation } from '../../../general';
import { pricingCountriesLoadAction, pricingItemCreateAction } from '../../state/pricing.action';
import { PricingForm } from '../pricing-form/pricing-form';

import type { PricingInputInterface } from '@dotgoclub/client-contracts';
import type { FC } from 'react';

export const AdminCreatePricingFormPage: FC = () => {
    const dispatch = useDispatch();

    const navigation = useAdminNavigation();
    const isRouteUpdated = useIsFocused();

    useEffect(() => void dispatch(pricingCountriesLoadAction()), [isRouteUpdated]);

    const handleCancel = (): void => void navigation.navigate(AdminRoutesEnum.Pricing);
    const handleSubmit = (values: PricingInputInterface): void => void dispatch(pricingItemCreateAction(values));

    return (
        <AdminPageContent title="Create pricing">
            <PricingForm initialValues={emptyPricingInput} onCancel={handleCancel} onSubmit={handleSubmit} />
        </AdminPageContent>
    );
};
