import { getErrorMessage } from '@rnw-community/shared';
import { catchError, map, switchMap } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';
import { serializeDateFieldsArray } from '@dotgoclub/utils-lib';

import { displayToast } from '../../../../general/helper/display-toast';
import { getAllSuppliersQuery$ } from '../../queries/get-all-suppliers.query';
import { suppliersListLoadAction, suppliersListLoadFailedAction, suppliersListLoadSuccessAction } from '../suppliers.action';

import type { Epic } from 'redux-observable';

export const loadSuppliersEpic: Epic = actions$ =>
    actions$.pipe(
        ofType(suppliersListLoadAction),
        switchMap(() =>
            getAllSuppliersQuery$().pipe(
                map(data => suppliersListLoadSuccessAction(serializeDateFieldsArray(data))),
                catchError((error: unknown) => {
                    displayToast({ type: 'error', header: "Couldn't load suppliers" });

                    return [suppliersListLoadFailedAction(getErrorMessage(error))];
                })
            )
        )
    );
