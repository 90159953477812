import { gql } from '@apollo/client';
import { from, map } from 'rxjs';

import type { ApolloClient } from '@apollo/client';
import type { RequestOtpArgsInterface } from '@dotgoclub/client-contracts';
import type { Observable } from 'rxjs';

const REQUEST_OTP_MUTATION = gql`
    mutation requestOtp($phoneNumber: String!) {
        requestOtp(phoneNumber: $phoneNumber)
    }
`;

interface RequestOtpMutation {
    requestOtp: string | null;
}

export const requestOtpMutation$ = <T>(
    apolloClient: ApolloClient<T>,
    variables: RequestOtpArgsInterface
): Observable<string | null | undefined> =>
    from(apolloClient.mutate<RequestOtpMutation, RequestOtpArgsInterface>({ mutation: REQUEST_OTP_MUTATION, variables })).pipe(
        map(res => res.data?.requestOtp)
    );
