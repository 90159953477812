import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findAllCurrenciesQuery$ = gqlQueryOperation<PricingAdminResolverInterface, 'findAllCurrencies'>(
    adminApolloClient,
    'findAllCurrencies',
    gql`
        query findAllCurrencies {
            findAllCurrencies {
                name
                symbol
            }
        }
    `
);
