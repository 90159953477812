export enum NotificationTypeEnum {
    ChatMessageReceived = 'ChatMessageReceived',
    OrderAccepted = 'OrderAccepted',
    OrderAutoCancelled = 'OrderAutoCancelled',
    OrderCancelledByCourier = 'OrderCancelledByCourier',
    OrderCourierArrived = 'OrderCourierArrived',
    OrderCourierDelivered = 'OrderCourierDelivered',
    OrderDeliveryAutoConfirmed = 'OrderDeliveryAutoConfirmed',
    OrderNewOrderAvailable = 'OrderNewOrderAvailable',
    OrderPaidByCourier = 'OrderPaidByCourier'
}
