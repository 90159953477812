import { degreesToRadians } from './degrees-to-radians.util';

export const metresToKilometers = (metres: number): number => Math.round((metres / 1000 + Number.EPSILON) * 100) / 100;

const EARTH_RADIUS = 6371e3;

export const getDistanceInMetresBetweenTwoCoordinates = (
    coord1: { latitude: number; longitude: number },
    coord2: { latitude: number; longitude: number }
): number => {
    const coord1Lat = degreesToRadians(coord1.latitude);
    const coord2Lat = degreesToRadians(coord2.latitude);
    const latDelta = degreesToRadians(coord2.latitude - coord1.latitude);
    const lonDelta = degreesToRadians(coord2.longitude - coord1.longitude);

    const angularDistance =
        Math.sin(latDelta / 2) * Math.sin(latDelta / 2) +
        Math.cos(coord1Lat) * Math.cos(coord2Lat) * Math.sin(lonDelta / 2) * Math.sin(lonDelta / 2);

    const distanceInMeters = 2 * Math.atan2(Math.sqrt(angularDistance), Math.sqrt(1 - angularDistance)) * EARTH_RADIUS;

    return distanceInMeters;
};
