import { createAction } from '@reduxjs/toolkit';

import type { AdminRoutesEnum } from '../enum/admin-routes.enum';

// TODO: Add navigation package to avoid logic duplication
export const adminNavigateAction = createAction<AdminRoutesEnum>('navigate');
export const adminNavigateBackAction = createAction('navigateBack');

// TODO: How we can add typing using RootStackParamList
export const adminNavigateParamsAction = createAction<{ params?: Record<string, string>; route: AdminRoutesEnum }>('navigateParams');
