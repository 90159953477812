import { useFocusEffect } from '@react-navigation/core';
import { isNotEmptyString } from '@rnw-community/shared';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AdminPageContent, AdminRoutesEnum, useAdminNavigation, useAdminNavigationRouteParams } from '../../../../general';
import { createBreadcrumb } from '../../../../general/helper/create-breadcrumb';
import {
    supplierByIdLoadAction,
    supplierCategoriesListBySupplierIdLoadAction,
    supplierStoresListBySupplierIdLoadAction
} from '../../state/suppliers.action';
import { supplierCategoriesSelector, supplierItemSelector, supplierStoreListSelector } from '../../state/suppliers.selector';
import { CategoryTable } from '../tables/category-table';
import { StoreTable } from '../tables/store-table';

import type { FC } from 'react';

export const AdminSupplierPage: FC = () => {
    const dispatch = useDispatch();

    const navigation = useAdminNavigation();
    const { id } = useAdminNavigationRouteParams<AdminRoutesEnum.Supplier>();

    const { item: supplier } = useSelector(supplierItemSelector);
    const { list: stores, isLoading: areStoresLoading } = useSelector(supplierStoreListSelector);

    const { list: categories, isLoading: areCategoriesLoading } = useSelector(supplierCategoriesSelector);

    useFocusEffect(
        useCallback(() => {
            if (isNotEmptyString(id)) {
                dispatch(supplierByIdLoadAction(id));
                dispatch(supplierCategoriesListBySupplierIdLoadAction(id));
                dispatch(supplierStoresListBySupplierIdLoadAction(id));
            }
        }, [id])
    );

    const handleCategoryEditPress = (categoryId: string): void =>
        void (isNotEmptyString(id) && navigation.navigate(AdminRoutesEnum.EditCategoryForm, { supplierId: id, categoryId }));
    const handleStoreEditPress = (storeId: string): void =>
        void (isNotEmptyString(id) && navigation.navigate(AdminRoutesEnum.EditStoreForm, { supplierId: id, storeId }));
    const handleCreateStorePress = (): void =>
        void (isNotEmptyString(id) && navigation.navigate(AdminRoutesEnum.AddStoreForm, { supplierId: id }));

    const title = `Supplier "${supplier.name}"`;
    const breadcrumbs = [
        createBreadcrumb('Suppliers', false, () => void navigation.navigate(AdminRoutesEnum.Suppliers)),
        createBreadcrumb(title, true)
    ];

    return (
        <AdminPageContent breadcrumbs={breadcrumbs} title={title}>
            <StoreTable
                isLoading={areStoresLoading}
                onCreatePress={handleCreateStorePress}
                onEditPress={handleStoreEditPress}
                stores={stores}
            />
            <CategoryTable categories={categories} isLoading={areCategoriesLoading} onEditPress={handleCategoryEditPress} />
        </AdminPageContent>
    );
};
