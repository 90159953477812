export interface PaginationArgsInterface {
    limit: number;
    page: number;
    route?: string;
}

export const emptyPaginationArgs: PaginationArgsInterface = {
    limit: 20,
    page: 1
};
