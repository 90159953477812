import { getEnv } from '@rnw-community/platform';

import { createAuthApolloClient } from '@dotgoclub/auth-components';

import { displayToast } from './helper/display-toast';
import { adminReduxStore } from './state/admin-root.reducer';

export const adminApolloClient = createAuthApolloClient(getEnv('API_URL') ?? '', adminReduxStore, {
    onOTPSuccess: () => void displayToast({ type: 'success', header: 'OTP was sent' }),
    onOTPFailed: (errText: string) => void displayToast({ header: `OTP request failed ${errText} `, type: 'error' }),
    onLoginSuccess: () => void displayToast({ header: 'Logged in successfully', type: 'success' }),
    onLoginFailed: (errText: string) => void displayToast({ header: `Login failed ${errText}`, type: 'error' })
});

export const resetAdminApolloClient = async (): Promise<void> => void (await adminApolloClient.resetStore());
