/* jscpd:ignore-start */
import EditIcon from '@rsuite/icons/Edit';
import React from 'react';
import { Button, ButtonToolbar, IconButton, Table } from 'rsuite';

import { H2 } from '../../../../general';

import type { StoreDtoInterface } from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { OnEventFn } from '@rnw-community/shared';
import type { FC } from 'react';

const { Column, HeaderCell, Cell } = Table;

interface Props {
    isLoading: boolean;
    onCreatePress: OnEventFn;
    onEditPress: OnEventFn<string>;
    stores: Array<WithSerializedDates<StoreDtoInterface>>;
}

export const StoreTable: FC<Props> = ({ onEditPress, stores, isLoading, onCreatePress }) => {
    const handleEditPress = (id: string) => () => void onEditPress(id);

    return (
        <>
            <H2>Stores</H2>
            <ButtonToolbar>
                <Button appearance="primary" color="green" onClick={onCreatePress}>
                    Create store
                </Button>
            </ButtonToolbar>
            <Table data={stores} height={1200} loading={isLoading}>
                <Column flexGrow={2}>
                    <HeaderCell>ID</HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Name</HeaderCell>
                    <Cell dataKey="name" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>External ID</HeaderCell>
                    <Cell dataKey="externalId" />
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>Map Place Id</HeaderCell>
                    <Cell dataKey="mapPlaceId" />
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>&nbsp;</HeaderCell>
                    <Cell>{(row: StoreDtoInterface) => <IconButton icon={<EditIcon />} onClick={handleEditPress(row.id)} />}</Cell>
                </Column>
            </Table>
        </>
    );
};
/* jscpd:ignore-end */
