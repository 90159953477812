import { initialLoadingState } from '@rnw-community/redux-loadable';

import { emptyPaginationMeta, emptyPricingDto } from '@dotgoclub/client-contracts';
import { serializeDateFields } from '@dotgoclub/utils-lib';

import type {
    CountryDtoInterface,
    CurrencyDtoInterface,
    PaginationMetaInterface,
    PricingDtoInterface
} from '@dotgoclub/client-contracts';
import type { WithSerializedDates } from '@dotgoclub/utils-lib';
import type { LoadingStateInterface } from '@rnw-community/redux-loadable';

export interface PricingState extends LoadingStateInterface {
    countries: Array<WithSerializedDates<CountryDtoInterface>>;
    currencies: Array<WithSerializedDates<CurrencyDtoInterface>>;
    item: WithSerializedDates<PricingDtoInterface>;
    list: Array<WithSerializedDates<PricingDtoInterface>>;
    pagination: PaginationMetaInterface;
}

export const initialPricingState: PricingState = {
    ...initialLoadingState,
    list: [],
    item: serializeDateFields(emptyPricingDto),
    currencies: [],
    countries: [],
    pagination: emptyPaginationMeta
};
