import { createHttpLink, from, split } from '@apollo/client';

import { createAuthLink } from './apollo-link/auth-link';
import { cleanTypeNameLink } from './apollo-link/clean-typename-link';
import { createErrorLink } from './apollo-link/error-link';
import { GraphQLWSLink } from './apollo-link/graphql-ws-link';
import { isSubscriptionLink } from './subscription/is-subscription-link';
import { getRefreshTokenUtil } from './util/get-refresh-token.util';

import type { ApolloLink } from '@apollo/client';
import type { JwtTokensResponseInterface } from '@dotgoclub/client-contracts';

export const createApolloLink = (
    apiUrl: string,
    getAuthToken: () => string,
    getRefreshToken: () => string,
    onRefreshSuccess: (credentials: JwtTokensResponseInterface) => void,
    onRefreshError: () => void,
    additionalLinks: ApolloLink[] = []
    // eslint-disable-next-line max-params
): ApolloLink =>
    from([
        ...additionalLinks,
        cleanTypeNameLink,
        createErrorLink(apiUrl, getRefreshToken, onRefreshSuccess, onRefreshError),
        createAuthLink(getAuthToken),
        split(
            isSubscriptionLink,
            new GraphQLWSLink({
                url: apiUrl.replace('http', 'ws'),
                lazy: true,
                connectionParams: async () => {
                    const { accessToken } = await getRefreshTokenUtil(apiUrl, getRefreshToken());

                    return { accessToken };
                }
            }),
            createHttpLink({ uri: apiUrl })
        )
    ]);
