import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findAllCountriesQuery$ = gqlQueryOperation<PricingAdminResolverInterface, 'findAllCountries'>(
    adminApolloClient,
    'findAllCountries',
    gql`
        query findAllCountries {
            findAllCountries {
                id
                name
                cities {
                    id
                    name
                }
            }
        }
    `
);
