import { EMPTY, concatMap } from 'rxjs';

import { ofType } from '@dotgoclub/redux-toolkit-rxjs-lib';

import { adminNavigationRef } from '../admin-navigation-ref';
import { adminNavigateBackAction } from '../admin-navigation.action';

import type { Action } from '@reduxjs/toolkit';
import type { Epic } from 'redux-observable';

export const adminNavigateBackEpic: Epic<Action, Action, unknown> = actions$ =>
    actions$.pipe(
        ofType(adminNavigateBackAction),
        concatMap(() => {
            adminNavigationRef.current?.goBack();

            return EMPTY;
        })
    );
