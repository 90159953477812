import { gql } from '@apollo/client';

import { gqlQueryOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { metaFragment } from './../../general/fragments/meta.fragment';
import { clientFragment } from './fragments/client.fragment';

import type { UserAdminResolverInterface } from '@dotgoclub/client-contracts';

export const findUsersQuery$ = gqlQueryOperation<UserAdminResolverInterface, 'findUsers'>(
    adminApolloClient,
    'findUsers',
    gql`
        query findUsers($pagination: PaginationArgs!, $filter: FindUsersFilter) {
            findUsers(pagination: $pagination, filter: $filter) {
                items {
                    ...ClientFragment
                }
                meta {
                    ...MetaFragment
                }
            }
        }
        ${clientFragment}
        ${metaFragment}
    `
);
