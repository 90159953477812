import { suppliersSlice } from './suppliers.slice';

export const {
    listLoad: suppliersListLoadAction,
    listLoadSuccess: suppliersListLoadSuccessAction,
    listLoadFailed: suppliersListLoadFailedAction,

    categoriesListBySupplierIdLoad: supplierCategoriesListBySupplierIdLoadAction,
    categoriesListBySupplierIdLoadSuccess: supplierCategoriesListBySupplierIdLoadSuccessAction,
    categoriesListBySupplierIdLoadFailed: supplierCategoriesListBySupplierIdLoadFailedAction,

    storeListBySupplierIdLoad: supplierStoresListBySupplierIdLoadAction,
    storeListBySupplierIdLoadSuccess: supplierStoresListBySupplierIdLoadSuccessAction,
    storeListBySupplierIdLoadFailed: supplierStoresListBySupplierIdLoadFailedAction,

    supplierByIdLoad: supplierByIdLoadAction,
    supplierByIdSuccess: supplierByIdSuccessAction,
    supplierByIdFailed: supplierByIdFailedAction,

    updateSupplierItem: updateSupplierItemAction,
    updateSupplierItemSuccess: updateSupplierItemSuccessAction,
    updateSupplierItemFailed: updateSupplierItemFailedAction,

    categoryItemLoad: supplierCategoryItemLoadAction,
    categoryItemLoadSuccess: supplierCategoryItemLoadSuccessAction,
    categoryItemLoadFailed: supplierCategoryItemLoadFailedAction,

    storeItemLoad: supplierStoreItemLoadAction,
    storeItemLoadSuccess: supplierStoreItemLoadSuccessAction,
    storeItemLoadFailed: supplierStoreItemLoadFailedAction,

    updateCategoryItem: updateSupplierCategoryItemAction,
    updateCategoryItemSuccess: updateSupplierCategoryItemSuccessAction,
    updateCategoryItemFailed: updateSupplierCategoryItemFailedAction,

    updateStoreItem: updateSupplierStoreItemAction,
    updateStoreItemSuccess: updateSupplierStoreItemSuccessAction,
    updateStoreItemFailed: updateSupplierStoreItemFailedAction,

    createStoreItem: createStoreItemAction,
    createStoreItemSuccess: createStoreItemSuccessAction,
    createStoreItemFailed: createStoreItemFailedAction,

    createSupplierItem: createSupplierItemAction,
    createSupplierItemSuccess: createSupplierItemSuccessAction,
    createSupplierItemFailed: createSupplierItemFailedAction
} = suppliersSlice.actions;
