import { gql } from '@apollo/client';

import { gqlMutationOperation } from '@dotgoclub/apollo-components';

import { adminApolloClient } from '../../general/admin-apollo-client';

import { pricingFragment } from './fragments/pricing.fragment';

import type { PricingAdminResolverInterface } from '@dotgoclub/client-contracts';

export const createPricingMutation$ = gqlMutationOperation<PricingAdminResolverInterface, 'createPricing'>(
    adminApolloClient,
    'createPricing',
    gql`
        mutation createPricing($input: PricingInput!) {
            createPricing(input: $input) {
                ...PricingFragment
            }
        }
        ${pricingFragment}
    `
);
