import type { CoordinatesInterface } from '../../interfaces';

export interface DirectionDtoInterface {
    coordinates: CoordinatesInterface[];
    distance: number;
    time: number;
}

export const emptyDirectionDto: DirectionDtoInterface = {
    coordinates: [],
    distance: 0,
    time: 0
};
