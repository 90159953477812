import { addHours } from 'date-fns';

import { resetDateHourAndMin } from './reset-date-hour-and-min.util';
import { roundTimeQuarterHour } from './round-time-quarter-hour.util';

export const getRangeTimeTill = (
    timeFrom: Date,
    intervalDeliveryTimeInHours: number,
    intervalBetweenTimeFromAndTimeTillInHours: number,
    currentDate = new Date()
): [min: Date, max: Date] => {
    const interval =
        timeFrom > roundTimeQuarterHour(currentDate) ? intervalBetweenTimeFromAndTimeTillInHours : intervalDeliveryTimeInHours;

    const minDate = roundTimeQuarterHour(addHours(timeFrom, interval));
    const maxDate = resetDateHourAndMin(23, 45, timeFrom);
    const limitDate = addHours(resetDateHourAndMin(0, 0, timeFrom), interval);

    return [minDate > maxDate ? limitDate : minDate, maxDate];
};
