import { createNavigationContainerRef } from '@react-navigation/native';

import type { AdminRoutesEnum } from '../enum/admin-routes.enum';
import type { RootStackParamList } from '../navigators/linking.config';

// TODO: This logic is duplicated in the client components, we could make an util/helper for this
export const adminNavigationRef = createNavigationContainerRef<RootStackParamList>();

export const navigate = <R extends keyof RootStackParamList>(route: R, params?: RootStackParamList[R]): void =>
    // @ts-expect-error Cannot add normal typing for now, needs improvement
    adminNavigationRef.current?.navigate(route, params);

export const getCurrentAdminNavigationRoute = (): AdminRoutesEnum =>
    adminNavigationRef.current?.getCurrentRoute()?.name as AdminRoutesEnum;
